// Dependencies
import { useState, useContext, useEffect } from 'react';
import { VerifyEmailContext } from '../../../App.js';

// API Imports
import { checkEmailCode, resendEmailCode } from "../../../apicalls/auth.js";

// Util Imports
import setTitleText from "../../../utils/setTitleText.js";

// SVG Imports
import su_logo from "../../../img/svg/signup/su_logo.svg";

// PNG Imports
import signup_bg from "../../../img/png/signup/signup_bg_8.png";


// Functional Component
function VerifyEmail() {
    setTitleText("Verify your email");

    // Data states
    const email = useContext(VerifyEmailContext);
    const [codeError, setCodeError] = useState(false);
    const [code, setCode] = useState("");

    // Handle creation of user
    async function handleVerifyCode() {
        const checkCode = await checkEmailCode(code);
        if (checkCode.status !== 200) {
            // TODO: Handle error
            const data = await checkCode.json();
            if (data.msg === "Expired") {
                // Code is expired, prompt to resend email
                setCodeError(true);
            } else {
                // Code provided is incorrect
                setCodeError(true);
            }
        } else {
            setCodeError(false);
            window.location.reload(false);
        }
    }

    const [resentEmailTimer, setResentEmailTimer] = useState(0);
    const [timerRunning, setTimerRunning] = useState(false);

    useEffect(() => {
        let intervalId;
        if (timerRunning) {
            intervalId = setInterval(() => {
                if (resentEmailTimer > 0) {
                    setResentEmailTimer(resentEmailTimer-1);
                } else {
                    clearInterval(intervalId);
                    stopTimer();
                }
            }, 1000);
        } else clearInterval(intervalId);
        return () => clearInterval(intervalId);
    }, [timerRunning, resentEmailTimer]);

    function startTimer() {
        setResentEmailTimer(180);
        setTimerRunning(true);
    }
  
    function stopTimer() {
        setResentEmailTimer(0);
        setTimerRunning(false);
    }

    async function handleResendCode() {
        // Don't resend email if timer not expired
        if (resentEmailTimer <= 0) {
            const resp = await resendEmailCode();
            if (resp.status !== 200) {
                // TODO: Handle error
                stopTimer();
            } else {
                // Start email timer
                startTimer();
            }
        }
    }

    function secondsToMinutes(num_seconds) {
        return `${String(Math.floor(num_seconds / 60)).padStart(2, '0')}:${String(num_seconds % 60).padStart(2, '0')}`;
    }

    // Return layout
    return(
        <>
            <div className="su-bg">
                <img src={signup_bg} alt="" />
            </div>
            <div className="su-cont">
                <img src={su_logo} alt="" />
                <div className="top bl">
                    <h1>First, let's verify your email.</h1>
                </div>
                <div className="in">
                    <div className="di mb0">
                        <h3>We sent <span>{email}</span> a 6-digit verification code. If you do not see the email, try checking your "Promotion" or "Spam" folders.</h3>
                        <div className="vc flex">
                            <h2>Verification Code</h2>
                            {(timerRunning) ?
                                <button className="timer" id="rc-btn">{`Resend Code in ${secondsToMinutes(resentEmailTimer)}`}</button>
                                :
                                <button id="rc-btn" onClick={handleResendCode}>Resend Code</button>
                            }
                        </div>
                        <input className={"vc" + ((codeError) ? " err" : "")} placeholder="123456" type="number" value={code} onChange={(e)=>{setCodeError(false); setCode(e.target.value.slice(0,6))}} />
                        {(codeError) && <h3 className="vc-err">The code you entered is invalid or expired. Please try again.</h3>}
                    </div>
                </div>
                <button
                    className={((code.length !== 6) ? "hidden" : "")}
                    onClick={handleVerifyCode}>Continue</button>
            </div>
        </>
    );
}

export default VerifyEmail;