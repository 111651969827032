// Change names to numbers
export function themeNameToNumber(theme, isBg=false) {
	if (isBg) {
		if (theme === 'light') return 0;
		else return 1;
	} else {
		switch (theme) {
			case "black":
				return 0;
			case "silver":
				return 1;
			case "navy":
				return 2;
			case "blue":
				return 3;
			case "maroon":
				return 4;
			case "red":
				return 5;
			case "orange":
				return 6;
			case "gold":
				return 7;
			case "lime":
				return 8;
			case "green":
				return 9;
			case "turquoise":
				return 10;
			case "purple":
				return 11;
			default:
				return 0;
		}
	}
}

// Change number to name
export function themeNumberToName(theme, isBg=false) {
	if (isBg) {
		if (theme === 0) return 'light';
		else return 'dark';
	} else {
		switch (theme) {
			case 0:
				return "black";
			case 1:
				return "silver";
			case 2:
				return "navy";
			case 3:
				return "blue";
			case 4:
				return "maroon";
			case 5:
				return "red";
			case 6:
				return "orange";
			case 7:
				return "gold";
			case 8: 
				return "lime";
			case 9:
				return "green";
			case 10:
				return "turquoise";
			case 11:
				return "purple";
			default:
				return "black";
		}
	}
}