// Dependencies
import { useState, useContext, createContext, useRef } from "react";

// Image Imports
import error_icon from "../../../img/svg/error/error_triangle.svg";
import x_icon_white from "../../../img/svg/error/x_icon_white.svg";

// Error Components
const ErrorContext = createContext();

export const useError = () => useContext(ErrorContext);

export function ErrorProvider ({ children }) {
	const [errorMessage, setErrorMessage] = useState('');
	const timeoutRef = useRef(null);

    const showError = (message) => {
    	setErrorMessage(message);

        // Clear the previous timeout, if it exists
        if (timeoutRef.current) clearTimeout(timeoutRef.current);

        // Set a new timeout to clear the error after 10 seconds
        timeoutRef.current = setTimeout(() => { setErrorMessage(''); }, 10000);
    };

	const clearError = () => {
		setErrorMessage('');
	}

	return (
		<ErrorContext.Provider value={{ errorMessage, showError, clearError }}>
			{children}
		</ErrorContext.Provider>
	);
};

export function ErrorDisplay() {
	const { errorMessage, clearError } = useError();

	function closeError() {
		clearError();
	}

	return (
		<>
			{(errorMessage) ?
				<div className="app-err-cont">
					<div className="app-err-d">
						<img src={error_icon} alt="Error" />
						<h3>{errorMessage}</h3>
						<button onClick={closeError}>
							<img src={x_icon_white} alt="Close Error" />
						</button>
					</div>
				</div>
				:
				<></>
			}
		</>
	);
};
  