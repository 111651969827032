// Dependencies
import { useState } from "react";

// API Imports
import { getNotesWithCode, saveNotesFor } from "../../../apicalls/visit.js";

// SVG Imports
import back_icon from "../../../img/svg/listing/back_chevron.svg";
import logo from "../../../img/svg/signin/logo_black.svg";
import notes_icon from "../../../img/svg/signin/notes.svg";
import end_icon from "../../../img/svg/signin/end.svg";
import search_icon from "../../../img/svg/topbar/search_icon.svg";


// Helper Functional Component
function VisitorList(props) {
    return (
        <>
            <div className="dash-result-d flex">
                <h2>Visitors</h2>
                <div className="dash-result-num"><h3>{props.leads.length}</h3></div>
            </div>
            <div className="visit-mng-vst-cont">
                {
                    props.leads.map((lead, i) => {     
                        return (<button value={lead.id} key={`vk-${i}`} onClick={props.chooseVisitor}>{lead.name}</button>);
                    })
                }
            </div>
        </>
    );
}


// Functional Component
function Manage(props) {
    // Page states
    const [page, setPage] = useState(0);
    const [searchInput, setSearchInput] = useState("");

    // Data states
    const [visitorList, setVisitorList] = useState(null);
    const [selectedVisitor, setSelectedVisitor] = useState(null);
    const [newNotes, setNewNotes] = useState(null);

    // Load visitors
    async function loadVisitors() {
        const resp = await getNotesWithCode(props.code);
        if (resp.status !== 200) {
            // TODO: Handle error
        } else {
            const data = await resp.json();
            setVisitorList(data.data);
            setPage(1);
        }
    }

    // Choose visitor and open notes page
    function chooseVisitor(e) {
        const lead_id = parseInt(e.currentTarget.value);
        // Find visitor info
        const len = visitorList.length;
        for (var i=0; i<len; i++) {
            if (visitorList[i].id === lead_id) {
                setSelectedVisitor(visitorList[i]);
                setNewNotes(visitorList[i].notes);
                break;
            }
        }
        // Advance page
        setPage(2);
    }

    // Save notes for selected visitor
    async function handleSaveNotes() {
        const resp = await saveNotesFor(props.code, selectedVisitor.id, newNotes);
        if (resp.status !== 200) {
            // TODO: Handle error
        } else {
            setSelectedVisitor(null);
            setNewNotes(null);
            setPage(0);
        }
    }

    // End the open house
    function endOpenHouse() {
        window.close();
    }

    // Return layout
    return (
        <div className={"visit-mng-cont" + ((props.open) ? "" : " off")}>
            {(page === 0) &&
                /* Select Option */
                <>
                    <img className="logo" src={logo} alt="" />
                    <h1>Manage Sign-In</h1>
                    <div className="visit-mng-opt-cont">
                        <button onClick={loadVisitors}>
                            <img src={notes_icon} alt="" />
                            <h3>Add Visitor Notes</h3>
                        </button>
                        <button onClick={endOpenHouse}>
                            <img src={end_icon} alt="" />
                            <h3>End Open House</h3>
                        </button>
                    </div>
                    <button className="visit-mng-b" onClick={props.close}>Done</button>
                </>
            }
            {(page === 1) &&
                /* Select Visitor (Add Notes) */
                <>
                    <button className="visit-mng-back-b" onClick={()=>{setPage(0)}}>
                        <img src={back_icon} alt="" />
                    </button>
                    <h1>Add Visitor Notes</h1>
                    <label className="visit-mng-vst-search">
                        <img src={search_icon} alt="" />
                        <input placeholder="Search visitors..." value={searchInput || ""} onChange={(e)=>{setSearchInput(e.currentTarget.value)}} />
                    </label>
                    <VisitorList {...{leads: visitorList.filter(lead => lead.name.toLowerCase().includes(searchInput.toLowerCase())), chooseVisitor: chooseVisitor}} />
                </>
            }
            {(page === 2 && selectedVisitor) &&
                /* Add Notes */
                <>
                    <button className="visit-mng-back-b" onClick={()=>{setPage(1)}}>
                        <img src={back_icon} alt="" />
                    </button>
                    <h1><span>Notes for </span>{selectedVisitor.name}</h1>
                    <div className="visit-mng-notes">
                        <textarea className="visit-mng-ta" value={newNotes || ""} onChange={(e)=>{setNewNotes(e.currentTarget.value)}} placeholder="Write notes here..." />
                        <button className="visit-mng-b" onClick={handleSaveNotes}>Save Notes</button>
                    </div>
                </>
            }
        </div>
    );
}

export default Manage;