// Dependencies
import { useState, useEffect } from "react";

// API Imports
import { getCredentialsForType, saveCredentialsForType } from "../../../apicalls/integrations.js";

// Util Imports
import { getIntegrationTitle, getIntegrationLogo } from "../../../utils/sharedIntegrationFuns.js";
import { useError } from '../../ui/Context/ErrorContext';

// Functional Component
function UpdateCredentials(props) {
	const { showError } = useError();
	
	// Data states
	const [t1, setT1] = useState(null);
	const [t2, setT2] = useState(null);
	const [t1_og, setT1_og] = useState(null);
	const [t2_og, setT2_og] = useState(null);
	const [data, setData] = useState(null);

	// Load credentials
	async function loadCreds() {
		const resp = await getCredentialsForType(props.type);
		if (resp.status !== 200) {
			showError('An unexpected error occurred.');
		} else {
			const data = await resp.json();
			setT1(data.target_1); setT1_og(data.target_1);
			setT2(data.target_2 || null); setT2_og(data.target_2);
			setData({t1: data.target_1, t2: data.target_2 || ""});
		}
	}
	useEffect(()=>{ loadCreds(); },[]);

	// Save credential wrapper
	async function handleSave() {
		if (await saveCredentialsForType(props.type, t1, t2)) {
			setT1_og(t1);
			setT2_og(t2);
		} else {
			showError('An unexpected error occurred.');
		}
	}

	function getFields(type) {
		switch (type) {
			case "hubspot":
				return (
					<div className="lst-new-i-d mb0">
						<h2>Private App Access Token</h2>
						<h3 className="intg-mng-hs">Go to "Settings" -&gt; "Integrations" -&gt; "Private Apps" -&gt; "Create". Under "Scopes", search "crm.objects.contacts" and enable "Write". Create the Private App to retrieve the Access Token.</h3>
						<input className="intg-mng-in" placeholder="e.g. pat-na1-c9us90zy-ad91-9924-25fd-sj62cxrbouyn" onChange={(e)=>{setT1(e.target.value)}} value={t1 || ""} />
					</div>
				);
			case "mailchimp":
				return (
					<>
						<div className="lst-new-i-d">
							<h2>API Key</h2>
							<h3 className="intg-mng-hs">Can be found in "Profile" -&gt; "Extras" -&gt; "API keys" -&gt; "Create A Key".</h3>
							<input className="intg-mng-in" placeholder="e.g. 09bb47fed8fc36decfc4e8bbcdca37" onChange={(e)=>{setT1(e.target.value)}} value={t1 || ""} />
						</div>
						<div className="lst-new-i-d mb0">
							<h2>Audience ID</h2>
							<h3 className="intg-mng-hs">Can be found in "Audience" -&gt; "Settings" -&gt; "Audience name and campaign defaults".</h3>
							<input className="intg-mng-in" placeholder="e.g. 7695882c5b" onChange={(e)=>{setT2(e.target.value)}} value={t2 || ""} />
						</div>
					</>
				);
			case "pipedrive":
				return (
					<div className="lst-new-i-d mb0">
						<h2>API Token</h2>
						<h3 className="intg-mng-hs">Can be found in "My Account" -&gt; "Personal Preferences" -&gt; API -&gt; "Your personal API token".</h3>
						<input className="intg-mng-in" placeholder="e.g. w7R4pK3zT9ySvA1D6oQ8qL0hFxU2gM5jE1vY3cH4" onChange={(e)=>{setT1(e.target.value)}} value={t1 || ""} />
					</div>
				);
			case "salesmate":
				return (
					<>
					</>
				);
			case "gsheets":
				return (
					<>
					</>
				);
			case "gmail":
				return (
					<>
					</>
				);
			case "twilio":
				return (
					<>
					</>
				);
			case "zapier":
				return (
					<>
					</>
				);
			default:
				return <></>;
		}		
	}

	// Return layout
	return (
		<div className="lst-mng-lead-prof-cont">
			{(data) &&
				<>
                    <div className="intg-mng-t-d">
                        <img className="intg-mng-img" src={getIntegrationLogo(props.type)} alt="" />
                        <div className="intg-mng-t-id mt">
                            <h1 className="lead-prof-nm">{getIntegrationTitle(props.type)} Credentials</h1>
							<h3>Manage your saved credentials for {getIntegrationTitle(props.type)} integrations.</h3>
                        </div>
                    </div>
					<div className="content-spacer" />
					{ getFields(props.type) }
                    <div className="dash-cont-b-d iflex">
                        <button className="back" onClick={props.goBack}>&lt;- Back</button>
                        <button
                            className={"finish" + ((t1 === t1_og && t2 === t2_og) ? " hidden" : "")}
                            disabled={(t1 === t1_og && t2 === t2_og)}
                            onClick={handleSave}>Save & Publish</button>
                    </div>
				</>
			}
        </div>
	);
}

export default UpdateCredentials;