// Dependencies
import { useState, useEffect } from 'react';
import Select from 'react-select';

// API Imports
import { getOrganization, addOrganizationMember, removeOrganizationMember, leaveOrganization, changeOrganizationRole, createOrganization } from "../../../apicalls/settings.js";

// Component Imports
import InviteMemberModal from "../../modals/InviteMemberModal";

// Util Imports
import { useError } from '../../ui/Context/ErrorContext';
import { useUpgrade } from '../../ui/Context/UpgradeContext';

// SVG Imports
import user_icon from "../../../img/svg/profile/user_orange.svg";
import back_icon from "../../../img/svg/listing/back_chevron.svg";
import del_orange from "../../../img/svg/topbar/del_orange.svg";

// PNG Imports
import building_grey from "../../../img/png/settings/org_grey.png";

// Constants
const selectStyle = {
    control: (styles, { isFocused }) => ({
        ...styles,
        borderColor: isFocused ? 'var(--orange-accent)' : styles.borderColor,
        boxShadow: (isFocused) ? '0 0 0 1px var(--orange-accent)' : styles.boxShadow,
        '&:hover': {
            borderColor: 'var(--orange-accent)'
        }
    }),
    option: (provided, state) => ({
        ...provided,
        fontFamily: 'Inter',
        fontSize: '15px',
        backgroundColor: state.isFocused ? 'var(--orange-accent-lite)' : null,
        color: 'black',
        ':active': {
            backgroundColor: 'var(--orange-accent)'
        },
    }),
    noOptionsMessage: (styles) => ({
        ...styles,
        fontSize: '15px',
        fontFamily: 'Inter',
        color: 'var(--grey-4)'
    }),
    singleValue: (styles) => ({
        ...styles,
        fontSize: '15px',
        fontFamily: 'Inter',
        color: 'black'
    })
};

const roleOptions = [
    {value: 1, label: "Manager"},
    {value: 2, label: "Agent"}
];


// Helper Functional Component
function OrgMember({
    id, index,
    userRole,
    name, surname, email, role,
    handleChangeRole, handleRemove
}) {
    // Return layout
    return (
        <div className="set-org-mem-d" key={`mk-${index+1}`}>
            <div className="nm"><h3>{name.charAt(0).toUpperCase()}{surname.charAt(0).toUpperCase()}</h3></div>
            <div className="dt">
                <h2>{name.charAt(0).toUpperCase() + name.slice(1)} {surname.charAt(0).toUpperCase() + surname.slice(1)}{(role === 0) && " (Owner)"}{((userRole !== 0 && userRole !== 1) && role === 1) && " (Manager)"}{((userRole !== 0 && userRole !== 1) && role === 2) && " (Agent)"}</h2>
                <h3>{email}</h3>
            </div>
            {(role !== 0 && (userRole === 0 || userRole === 1)) &&
                <>
                    <div className="sel">
                        <Select
                            options={roleOptions}
                            styles={selectStyle}
                            defaultValue={roleOptions.find(l => l.value === role)}
                            isClearable={false}
                            onChange={(e)=>{handleChangeRole(id, e.value)}}
                        />
                    </div>
                    <button className="rmv" onClick={handleRemove} value={id}>Remove</button>
                </>
            }
        </div>
    );
}

// Functional Component
function Organization(props) {
    const { showError } = useError();
    const { showUpgrade } = useUpgrade();
    
    // Page stages
    const [loaded, setLoaded] = useState(0); // 0 = Not loaded, 1 = Loaded, has org, 2 = Loaded, no org
    const [loaderOn, setLoaderOn] = useState(false);
    const [inviteOpen, toggleInvite] = useState(false);

    // Data states
    const [userRole, setUserRole] = useState(null);
    const [orgName, setOrgName] = useState(null);
    const [orgLogo, setOrgLogo] = useState(null);
    const [orgMembers, setOrgMembers] = useState([]);
    const [newOrgName, setNewOrgName] = useState("");

    // Remove user from organization
    async function removeMember(e) {
        try {
            const id = parseInt(e.currentTarget.value);
            const resp = await removeOrganizationMember(id);
            switch (resp.status) {
                case 200:
                    setOrgMembers(orgMembers.filter((m) => m.id !== id));
                    break;
                case 401:
                    showUpgrade('Organizations');
                    break;
                default:
                    showError('An unexpected error occurred.');
                    break;
            }
        } catch (err) {
            showError('An unexpected error occurred.');
        }
    }

    // Add member to organization
    async function addMember(email, role) {
        const resp = await addOrganizationMember(email, role);
        switch (resp.status) {
            case 200:
                toggleInvite(false);
                loadOrg();
                break;
            case 401:
                showUpgrade('Organizations');
                break;
            default:
                showError('An unexpected error occurred.');
                break;
        }
    }

    // Change member role
    async function changeRole(id, role) {
        const resp = await changeOrganizationRole(id, role);
        switch (resp.status) {
            case 200:
                setOrgMembers([...orgMembers.map((m)=>{
                    if (m.id === id) return {...m, role: role}
                    else return m
                })]);
                break;
            case 401:
                showUpgrade('Organizations');
                break;
            default:
                showError('An unexpected error occurred.');
                break;
        }
    }
    
    // Leave organization
    async function leaveOrg() {
        const resp = await leaveOrganization();
        switch (resp.status) {
            case 200:
                setLoaded(2);
                break;
            case 401:
                showUpgrade('Organizations');
                break;
            default:
                showError('An unexpected error occurred.');
                break;
        }
    }

    // Create organization
    async function createOrg() {
        const resp = await createOrganization(newOrgName);
        switch (resp.status) {
            case 200:
                loadOrg();
                break;
            case 401:
                showUpgrade('Organizations');
                break;
            default:
                showError('An unexpected error occurred.');
                break;
        }
    }

    function openConfirmDelete() {
        props.setPage(0.4);
    }

    // Load organization
    async function loadOrg() {
        const resp = await getOrganization();
        setTimeout(()=>{setLoaderOn(true)}, 500); // Turn preloader on after 0.5s
        switch (resp.status) {
            case 200:
                const data = (await resp.json()).data;
                setOrgName(data.org_name);
                setUserRole(data.org_role);
                setOrgLogo(data.org_logo);
                setOrgMembers(data.org_members);
                setLoaded(1);
                break;
            case 401:
                showUpgrade('Organizations');
                break;
            case 204:
                setLoaded(2);
                // Intentional non-break
            default:
                showError('An unexpected error occurred.');
                break;
        }
    }
    useEffect(()=>{ if (!orgName) loadOrg(); }, []);

    // Return layout
    return (
        <>
            {(loaded === 0) && <>{(loaderOn) && <div className="prl-spin-cont"><div className="prl-spin" /></div>}</>}
            {(loaded === 1) &&
                <>
                    {(inviteOpen) && <InviteMemberModal orgName={orgName} closeModal={()=>{toggleInvite(false)}} handleSubmit={addMember} />}
                    <div className="lst-new-i-d">
                        <div className="set-card-d flex">
                            <div className="l org">
                                <img src={orgLogo} alt="" />
                            </div>
                            <div className="r org">
                                <h2>{orgName || ""}</h2>
                                <div className="flex">
                                    <img src={user_icon} alt="" />
                                    <h3>{orgMembers.length} Member{(orgMembers.length > 1) && "s"}</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="lst-new-i-d mb0">
                        <h2>Members</h2>
                        <div>
                            {
                                orgMembers.filter((m) => m.email === props.email).map((m) => {
                                    return (
                                        <div className="set-org-mem-d" key={`mk-0`}>
                                            <div className="nm"><h3>{m.name.charAt(0).toUpperCase()}{m.surname.charAt(0).toUpperCase()}</h3></div>
                                            <div className="dt">
                                                <h2>{m.name.charAt(0).toUpperCase() + m.name.slice(1)} {m.surname.charAt(0).toUpperCase() + m.surname.slice(1)}{(m.role === 0) && " (Owner)"}{(m.role === 1) && " (Manager)"}{(m.role === 2) && " (Agent)"}</h2>
                                                <h3>{m.email}</h3>
                                            </div>
                                        </div>
                                    );
                                })
                            }
                            {
                                orgMembers.sort((a,b) => a.role - b.role).map((m, index) => {
                                    if (m.email === props.email) return;
                                    return (<OrgMember id={m.id} index={index} userRole={userRole} name={m.name} surname={m.surname} email={m.email} role={m.role} handleChangeRole={changeRole} handleRemove={removeMember} />);
                                })
                            }
                        </div>
                    </div>
                    <div className="dash-cont-b-d iflex">
                        {(userRole === 0) ? 
                            <button className="del" onClick={openConfirmDelete}>Delete Organization</button>
                            :
                            <button className="back" onClick={leaveOrg}>Leave</button>
                        }
                        {(userRole === 0 || userRole === 1) && <button className="next" onClick={()=>{toggleInvite(true)}}>Invite Members</button>}
                    </div>
                </>
            }
            {(loaded === 2) &&
                <>
                    <div className="lst-new-i-d">
                        <div className="set-card-d flex mw100">
                            <div className="l org">
                                <img src={building_grey} alt="" />
                            </div>
                            <div className="r org">
                                <h2>Create Organization</h2>
                                <h3 className="set-org-hs">Simplify and streamline internal team organization.</h3>
                            </div>
                        </div>
                    </div>
                    <div className="lst-new-i-d mb0">
                        <h2>Name</h2>
                        <input className="intg-mng-in" placeholder="e.g. Dream Realty Group" value={newOrgName || ""} onChange={(e)=>{setNewOrgName(e.target.value)}} />
                    </div>
                    <div className="dash-cont-b-d iflex">
                        <button className={"next"+((!newOrgName || newOrgName.length === 0) ? " hidden" : "")} disabled={!newOrgName || newOrgName.length === 0} onClick={createOrg}>Create Organization</button>
                    </div>
                </>
            }
        </>
    );
}

export default Organization;