// Dependencies
import { useState, useEffect } from "react";
import Select from "react-select";

// API Imports
import { getUserCategories } from "../../../../apicalls/listings.js";
import { getAutomationsForUser } from "../../../../apicalls/automations.js";

// Util Imports
import { getAutomationSmallIcon } from "../../../../utils/sharedAutomationFuns.js";
import { useError } from '../../../ui/Context/ErrorContext';

// Functional component
function Setup(props) {
    const { showError } = useError();
    
    // Data states
	const [categories, setCategories] = useState([]);
    const [listingTemplates, setListingTemplates] = useState([]); // used by select box

	// Load options for categories, and load all automations
	useEffect(()=>{
		async function loadCategories() {
			const resp = await getUserCategories();
			if (resp.status !== 200) {
				showError('An unexpected error occurred.');
			} else {
				const cats = await resp.json(); var tempCat = [];
				cats.forEach(cat => {
					tempCat.push({ value: cat.category, label: cat.category_name });
				});
				setCategories(tempCat);
			}
		}
		loadCategories();

        async function loadTemplates() {
            const resp = await getAutomationsForUser();
            if (resp.status !== 200) {
                if (resp.status !== 401) showError('An unexpected error occurred.');
            } else {
                const results_raw = (await resp.json()).data;
                const results = results_raw.reduce((filtered, atm) => {
                    if (atm.type === 0) filtered.push({label: atm.name, value: atm.code});
                    return filtered;
                }, []);
                props.setAutomations(results);
            }
        }
        loadTemplates();
	}, []);

    // Filter automations to get only listing templates
    useEffect(()=>{
        if (props.automations && props.automations.length > 0) {
            setListingTemplates(
                props.automations.reduce((filtered, atm) => {
                    if (atm.type === 0) filtered.push({label: atm.name, value: atm.code});
                    return filtered;
                }, [])
            );
        }
    }, [props.automations]);

    // Wrapper for choosing category
    function chooseCategory(cat) {
        if (cat) {
            props.setChosenCategory(cat.value);
            props.setChosenCategoryName(cat.label);
        } else {
            props.setChosenCategory(null);
            props.setChosenCategoryName("No Category");
        }
    }

    // Wrapper for choosing listing template
    function chooseTemplate(t) {
        if (t) {
            props.setChosenTemplate(t.value);
        } else {
            props.setChosenTemplate(null);
        }
    }

    // Custom style for selector
    const selectStyle = {
        control: (styles, { isFocused }) => ({
            ...styles,
            borderColor: isFocused ? 'var(--orange-accent)' : styles.borderColor,
            boxShadow: (isFocused) ? '0 0 0 1px var(--orange-accent)' : styles.boxShadow,
            '&:hover': {
                borderColor: 'var(--orange-accent)'
            }
        }),
        option: (provided, state) => ({
            ...provided,
            fontFamily: 'Inter',
            fontSize: '15px',
            backgroundColor: state.isFocused ? 'var(--orange-accent-lite)' : null,
            color: 'black',
            ':active': {
                backgroundColor: 'var(--orange-accent)'
            },
        }),
        noOptionsMessage: (styles) => ({
            ...styles,
            fontSize: '15px',
            fontFamily: 'Inter',
            color: 'var(--grey-4)'
        }),
        singleValue: (styles) => ({
            ...styles,
            fontSize: '15px',
            fontFamily: 'Inter',
            color: 'black'
        })
    };

	// Return layout
	return (
		<>
            <div className="lst-new-i-d">
                <h2>Category</h2>
                <Select
                    isClearable={true}
                    defaultValue={(props.chosenCategory) ?  categories.find(e => e.value === props.chosenCategory) || null : null}
                    options={(categories && categories.length > 0) ? categories : []}
                    onChange={(c)=>{(c) ? chooseCategory(c) : chooseCategory(null)}}
                    styles={selectStyle}
                    disabled={(categories && categories.length > 0) ? false : true}
                    placeholder="No Category Selected"
                    noOptionsMessage={(_) => "No categories found"}
                />
            </div>
            <div className="lst-new-i-d mb0">
                <div className="iflex atms-lst-t">
                    {getAutomationSmallIcon(0)}
                    <h2>Listing Templates</h2>
                </div>
                <Select
                    isClearable={true}
                    defaultValue={(props.chosenTemplate) ?  props.automations.find(e => e.value === props.chosenTemplate) || null : null}
                    options={(listingTemplates.length > 0) ? listingTemplates : []}
                    onChange={(c)=>{(c) ? chooseTemplate(c) : chooseTemplate(null)}}
                    styles={selectStyle}
                    disabled={listingTemplates.length === 0}
                    placeholder="No Template Selected"
                    noOptionsMessage={(_) => "No templates found"}
                />
            </div>
            <div className="dash-cont-b-d iflex">
				<button className="next" onClick={props.goNext}>Next -&gt;</button>
			</div>
		</>
	);
}

export default Setup;