// Dependencies
import { useState, useEffect } from 'react';

// Component Imports
import Typewriter from "../../../utils/Typewriter";

// API Imports
import { api_enhance } from '../../../apicalls/compose';

// Util Imports
import { useError } from "../../ui/Context/ErrorContext";
import { useUpgrade } from "../../ui/Context/UpgradeContext";
// SVG Imports
import enhance_icon from "../../../img/svg/ai_suite/enhance_icon.svg";


// Functional Component
function FinishedCompose(props) {
	const { showError } = useError();
	const { showUpgrade } = useUpgrade();

	// Data States
	const [outputs, setOutputs] = useState([]);
	const [enhancedOutputs, setEnhancedOutputs] = useState([]);
	const [enhancedFeedback, setEnhancedFeedback] = useState([]);
	const [enhanceDesc, setEnhanceDesc] = useState("");

	// Page States
	const [isLoading, setLoading] = useState(false);
	const [currIndex, setCurrIndex] = useState(-1);
	function decrementIndex() { setEnhancedEnabled(false); setEnhanceDesc(""); setShowFeedback(false); if (!isLoading && currIndex>0) setCurrIndex(currIndex-1); }
	function incrementIndex() { setEnhancedEnabled(false); setEnhanceDesc(""); setShowFeedback(false); if (!isLoading && currIndex+1<outputs.length) setCurrIndex(currIndex+1); }
	const [isTyping, toggleTyping] = useState(false);
	const [indicesTyped, setIndicesTyped] = useState([]);
	function setIndexTyped(index) { var t = indicesTyped; t[index] = true; setIndicesTyped(t); }
	const [indicesTyped_enhanced, setIndicesTyped_enhanced] = useState([]);
	function setIndexTyped_enhanced(index) { var t = indicesTyped_enhanced; t[index] = true; setIndicesTyped_enhanced(t); }
	const [enhanceEnabled, setEnhancedEnabled] = useState(false);
	const [showFeedback, setShowFeedback] = useState(false);

	// Copy output to clipboard
	function copyText() {
		navigator.clipboard.writeText(outputs[currIndex] || "").then(function() {
			// TODO: Handle success
		}, function(_err) { showError('An unexpected error occurred'); });
	}

	// Copy enhanced output to clipboard
	function copyEnhancedText() {
		navigator.clipboard.writeText(enhancedOutputs[currIndex] || "").then(function() {
			// TODO: Handle success
		}, function(_err) { showError('An unexpected error occurred'); });
	}

	// Redo composition
	async function composeNew(isFirst=false) {
		// Reset page
		setEnhancedEnabled(false); setEnhanceDesc(""); setShowFeedback(false);

		// Normal set-up
		const tempOutputs = [...outputs];
		setOutputs([...outputs, "ㅤ" /* U+3164 */]);
		setIndicesTyped([...indicesTyped, false]);
		
		// Enhanced set-up
		setEnhancedOutputs([...enhancedOutputs, null]);
		setEnhancedFeedback([...enhancedFeedback, ""]);
		setIndicesTyped_enhanced([...indicesTyped_enhanced, false]);

		// Move to newest
		(isFirst) ? setCurrIndex(0) : setCurrIndex(outputs.length);
		window.scrollTo(0,0); // Scroll to top
		toggleTyping(true);

		// Get new completion
		setLoading(true);
		const new_text = await props.generate();
		setOutputs([...tempOutputs, new_text]);
		toggleTyping(false);
		setLoading(false);
	}
	// Generate first initial response
	useEffect(()=>{ if (outputs.length === 0) composeNew(true); }, [outputs]);

	// Enhance composition based on feedback
	async function enhanceCompose() {
		var temp_feedback = enhancedFeedback;
		temp_feedback[currIndex] = enhanceDesc;
		setEnhancedFeedback(temp_feedback);

		setLoading(true);
		const resp = await api_enhance(outputs[currIndex], enhanceDesc);
		switch (resp.status) {
			case 200:
				const msg = await resp.json();
				var temp_outputs = enhancedOutputs;
				temp_outputs[currIndex] = msg.c;
				setEnhancedOutputs([...temp_outputs]);
				setLoading(false);
				break;
			case 401:
				showUpgrade('Compose');
				break;
			default:
				showError('An unexpected error occurred.');
				break;
		}
	}

	// Return layout
	return (
		<>
			<div className="flex ai-fnsh-pn-d">
				<button className={(!isLoading && currIndex>0) ? "" : "hidden"} onClick={decrementIndex}>&lt;-</button>
				<h3>{`${currIndex+1} / ${outputs.length}` || "0/0"}</h3>
				<button className={(!isLoading && currIndex+1<outputs.length) ? "" : "hidden"} onClick={incrementIndex}>-&gt;</button>
			</div>
			<div className="ai-fnsh-t-d">
				{(indicesTyped[currIndex]) ? 
					<h3>{outputs[currIndex] || "ㅤ" /* U+3164 */}</h3>
					:
					<Typewriter loading={isTyping} currIndex={currIndex} setIndexTyped={setIndexTyped} text={outputs[currIndex] || "ㅤ" /* U+3164 */} />
				}
			</div>
			{(enhancedOutputs[currIndex]) ? 
				<>
					<div className="ai-enh-h-d flex">
						<div className="flex">
							<img src={enhance_icon} alt="Magic" />
							<h2 className="ai-enh-hm ind">Enhanced</h2>
						</div>
						<button onClick={()=>{setShowFeedback(!showFeedback)}}>{(showFeedback) ? "Hide " : "Show "}Details</button>
					</div>
					{(showFeedback) &&
						<>
							<h2 className="ai-enh-hs fb">Feedback</h2>
							<div className="ai-fnsh-t-d fb">
								<h3>{enhancedFeedback[currIndex]}</h3>
							</div>
							<h2 className="ai-enh-hs">Output</h2>
						</>
					}
					<div className="ai-fnsh-t-d enh">
						{(indicesTyped_enhanced[currIndex]) ? 
							<h3>{enhancedOutputs[currIndex] || "ㅤ" /* U+3164 */}</h3>
							:
							<Typewriter type="enhance" loading={isTyping} currIndex={currIndex} setIndexTyped={setIndexTyped_enhanced} text={enhancedOutputs[currIndex] || "ㅤ" /* U+3164 */} />
						}
					</div>
					<div className="dash-cont-b-d wd iflex">
						<button className="back" onClick={copyEnhancedText}>Copy</button>
						<div className="r">
							<button className={"retry"+((isLoading) ? " hidden" : "")} disabled={isLoading} onClick={()=>{composeNew(false)}}>Retry</button>
						</div>
					</div>
				</>
				:
				<>
					{(enhanceEnabled) ?
						<>
							<h2 className="ai-enh-hm">Feedback</h2>
							<textarea className="ai-enh-ta" maxLength={850} placeholder="Provide your feedback here..." value={enhanceDesc} onChange={(e)=>{setEnhanceDesc(e.currentTarget.value)}} />
							<div className="dash-cont-b-d wd iflex">
								<button className={"retry"+((isLoading) ? " hidden" : "")} disabled={isLoading} onClick={()=>{setEnhancedEnabled(false)}}>Cancel</button>
								<button className={"enhance" + ((enhanceDesc.length === 0 || isLoading) ? " hidden" : "")} disabled={isLoading || enhanceDesc.length === 0} onClick={enhanceCompose}>Confirm</button>
							</div>
						</>
						:
						<div className="dash-cont-b-d wd iflex">
							<button className="back" disabled={false} onClick={copyText}>Copy</button>
							<div className="r">
								<button className={"retry"+((isLoading) ? " hidden" : "")} disabled={isLoading} onClick={()=>{composeNew(false)}}>Retry</button>
								<button className={"enhance"+((isLoading) ? " hidden" : "")} disabled={isLoading} onClick={()=>{if (!isLoading) setEnhancedEnabled(true)}}>Enhance</button>
							</div>
						</div>
					}
				</>
			}
		</>
	);
}

export default FinishedCompose;