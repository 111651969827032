function AI_Input({
    val, setVal,
    title, placeholder, description="",
    last=false
}) {
    return (
        <div className={"ai-tool-input" + (last ? " mb0" : "")}>
            <h2>{title}</h2>
            {(description && description.length > 0) && <h3>{description}</h3> }
            <input
                value={val}
                onChange={(e)=>{setVal(e.target.value)}}
                placeholder={placeholder}
            />
        </div>
    );
}

export default AI_Input;