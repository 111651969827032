// Dependencies
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

// API Imports
import { createListing, updateListingDesignWithCode, updateListingQuestionsWithCode } from "../../../apicalls/listings.js";
import { handleBulkCreateIntegrationsWithCode } from "../../../apicalls/integrations.js";
import { loadAutomationWithCode } from "../../../apicalls/automations.js";

// Util Imports
import { useError } from '../../ui/Context/ErrorContext';
import uploadPhoto from "../../../utils/uploadPhoto.js";
import { formatBackendQuestions, formatBackendIntegrations } from "../../../utils/sharedAutomationFuns.js";
import setTitleText from "../../../utils/setTitleText.js";

// Component Imports
import Setup from "./comp/Setup";
import Details from "./comp/Details";
import Questions from "./comp/Questions";
import Integrations from "./comp/Integrations";
import Design from "./comp/Design";
import Review from "./comp/Review";

// SVG Imports
import plus_orange from "../../../img/svg/topbar/plus_orange.svg";


// Functional Component
function Controller() {
    setTitleText("Add Listing");

    const navigate = useNavigate();
    const { showError } = useError();
    
    // Page states
    const [page, setPage] = useState(0);
    function goBack() {setPage(page-1)}
    function goNext() {setPage(page+1)}

    // Setup Data
    const [chosenCategory, setChosenCategory] = useState(null);
    const [chosenCategoryName, setChosenCategoryName] = useState(null);
    const [automations, setAutomations] = useState([]);
    const [chosenTemplate, setChosenTemplate] = useState(null); // Code of the listing template
    const setupProps = {
        chosenCategory: chosenCategory,
        setChosenCategory: setChosenCategory,
        setChosenCategoryName: setChosenCategoryName,
        automations: automations,
        setAutomations: setAutomations,
        chosenTemplate: chosenTemplate,
        setChosenTemplate: setChosenTemplate,
        goNext: goNext,
    }

    // Load questions, integrations, design when template is chosen
    useEffect(()=>{
        async function loadTemplateDetails(code) {
            const resp = await loadAutomationWithCode(code);
            if (resp.status !== 200) {
                if (resp.status !== 401) showError('An unexpected error occurred.');
            } else {
                const result = await resp.json();
                setFgColor(result.data.fg_color);
                setBgColor(result.data.bg_color);
                setChosenPreset(code);
                setChosenPackage(code);
            }
        }
        if (chosenTemplate && chosenTemplate.length > 0) loadTemplateDetails(chosenTemplate);
    }, [chosenTemplate]);

    // Detail Data
    const [address1, setAddress1] = useState("");
    const [address2, setAddress2] = useState(null);
    const [city, setCity] = useState("");
    const [zip, setZip] = useState("");
    const [country, setCountry] = useState("");
    const [region, setRegion] = useState("");
    const [imageBlobs, setImageBlobs] = useState([]);
	const [imageUrls, setImageUrls] = useState([]);
    const detailProps = {
        address1: address1,
        setAddress1: setAddress1, 
        address2: address2,
        setAddress2: setAddress2,
        city: city,
        setCity: setCity,
        zip: zip,
        setZip: setZip,
        country: country,
        setCountry: setCountry,
        region: region,
        setRegion: setRegion,
        imageBlobs: imageBlobs,
        setImageBlobs: setImageBlobs,
        imageUrls: imageUrls,
        setImageUrls: setImageUrls,
        goBack: goBack,
        goNext: goNext,
    }

    // Sign-In Data
    const [questions, setQuestions] = useState([]);
    const [chosenPreset, setChosenPreset] = useState(null);
    const questionProps = {
        chosenTemplate: chosenTemplate,
        questions: questions,
        setQuestions: setQuestions,
        automations: automations,
        chosenPreset: chosenPreset,
        setChosenPreset: setChosenPreset,
        goBack: goBack,
        goNext: goNext
    }

    // Load new questions when preset is chosen
    useEffect(()=>{
        async function loadPresetDetails(code) {
            const resp = await loadAutomationWithCode(code);
            if (resp.status !== 200) {
                showError('An unexpected error occurred.');
            } else {
                const result = await resp.json();
                if (chosenTemplate === chosenPreset) setQuestions(formatBackendQuestions(result.data.questions) || []); // Listing template (different format)
                else setQuestions(formatBackendQuestions(result.data) || []); // Not listing template
            }
        }
        if (chosenPreset && chosenPreset.length > 0) loadPresetDetails(chosenPreset);
        if (!chosenPreset) setQuestions([]);
    }, [chosenPreset]);
    

    // Integration Data
    const [integrations, setIntegrations] = useState([]);
    const [chosenPackage, setChosenPackage] = useState(null);
    const integrationProps = {
        chosenTemplate: chosenTemplate,
        integrations: integrations,
        setIntegrations: setIntegrations,
        automations: automations,
        chosenPackage: chosenPackage,
        setChosenPackage: setChosenPackage,
        goBack: goBack,
        goNext: goNext
    }

    // Load new integrations when package is chosen
    useEffect(()=>{
        async function loadPackageDetails(code) {
            const resp = await loadAutomationWithCode(code);
            if (resp.status !== 200) {
                showError('An unexpected error occurred.');
            } else {
                const result = await resp.json();
                if (chosenTemplate === chosenPackage) setIntegrations(formatBackendIntegrations(result.data.integrations || [])); // Listing template (different format)
                else setIntegrations(formatBackendIntegrations(result.data || [])); // Not listing template
            }
        }
        if (chosenPackage && chosenPackage.length > 0) loadPackageDetails(chosenPackage);
        if (!chosenPackage) setIntegrations([]);
    }, [chosenPackage]);

    // Design Data
    const [fgColor, setFgColor] = useState('black');
    const [bgColor, setBgColor] = useState('light');
    const designProps = {
        fgColor: fgColor,
        setFgColor: setFgColor,
        bgColor: bgColor,
        setBgColor: setBgColor,
        goBack: goBack,
        goNext: goNext
    }

    // Review data
    const reviewProps = {
        category: chosenCategory,
        categoryName: chosenCategoryName,
        address1: address1,
        address2: address2,
        city: city,
        zip: zip,
        country: country,
        region: region,
        imageUrls: imageUrls,
        questions: questions,
        integ: integrations,
        fgColor: fgColor,
        bgColor: bgColor,
        goBack: goBack,
        goFinish: handleCreate
    }


    // Handle creation
    async function handleCreate() {
        var listing_code = "";

        // 0. Upload Photos to S3
        var imageIds = [];
        for (var i=0; i<imageBlobs.length; i++) imageIds.push(await uploadPhoto(imageBlobs[i]));

        // 1. Create listing
        const resp = await createListing(address1, address2, city, zip, country, region, imageIds, chosenCategory);
        if (resp.status !== 200) {
            showError('An unexpected error occurred.');
            return;
        } else {
            const data = await resp.json();
            listing_code = data.code;
        }

        // 2. Add Questions
        const quest_resp = await updateListingQuestionsWithCode(listing_code, questions);
        if (quest_resp.status !== 200) {
            showError('An unexpected error occurred.');
            return;
        }

        // 3. Add Integrations
        const intg_resp = await handleBulkCreateIntegrationsWithCode(listing_code, integrations);
        if (intg_resp.status !== 200) {
            showError('An unexpected error occurred.');
            return;
        }

        // 4. Update design
        const design_resp = await updateListingDesignWithCode(listing_code, fgColor, bgColor);
        if (design_resp.status !== 200) {
            showError('An unexpected error occurred.');
            return;
        }

        navigate(`/listings/${listing_code}`, {replace: true});
    }

    // Return layout
    return (
        <>
            <div className="tb-cont">
                <div className="tb-top">
                    <div className="iflex">
                        <img className="tb-t-icon" src={plus_orange} alt="" />
                        <h1 className="pg-t">Create Listing</h1>
                    </div>
                    <div className="tb-steps-cont flex">
                        <div className={"tb-steps iflex"+((page===0)?" active":"")}><div className="tb-step-num"><h3>1</h3></div><h2>Setup</h2></div>
                        <div className={"tb-steps iflex"+((page===1)?" active":"")}><div className="tb-step-num"><h3>2</h3></div><h2>Details</h2></div>
                        <div className={"tb-steps iflex"+((page===2)?" active":"")}><div className="tb-step-num"><h3>3</h3></div><h2>Sign-In</h2></div>
                        <div className={"tb-steps iflex"+((page===3)?" active":"")}><div className="tb-step-num"><h3>4</h3></div><h2>Integrations</h2></div>
                        <div className={"tb-steps iflex"+((page===4)?" active":"")}><div className="tb-step-num"><h3>5</h3></div><h2>Design</h2></div>
                        <div className={"tb-steps iflex"+((page===5)?" active":"")}><div className="tb-step-num"><h3>6</h3></div><h2>Review</h2></div>
                    </div>
                </div>
            </div>
            <div className="dash-cont step">
                {(page === 0) && <Setup {...setupProps} />}
                {(page === 1) && <Details {...detailProps} />}
                {(page === 2) && <Questions {...questionProps} />}
                {(page === 3) && <Integrations {...integrationProps} />}
                {(page === 4) && <Design {...designProps} />}
                {(page === 5) && <Review {...reviewProps} />}
            </div>
        </>
    );
}

export default Controller;