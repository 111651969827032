// Dependencies
import { useState, useEffect } from 'react';

// API Imports
import { api_createListingDesc } from "../../../apicalls/compose.js";

// Component Imports
import AI_Input from "../comp/Input";
import { AI_Single_Select } from "../comp/Select";
import AI_PropertyDetails from "../comp/PropertyDetails";
import AI_Textbox from "../comp/Textbox";
import AI_Tone from "../comp/Tone";
import FinishedCompose from '../comp/FinishedCompose';

// Util Imports
import { useError } from '../../ui/Context/ErrorContext';
import { useUpgrade } from '../../ui/Context/UpgradeContext';

// Constants
const allPropertyTypes = ["Townhouse","Detached Home","Condominium","Apartment","Duplex","Multiplex","Bungalow","Luxury Property"];


// Functional Component
function Create_ListingDesc(props) {
    const { showError } = useError();
    const { showUpgrade } = useUpgrade();

    // Data states
    const [propertyType, setPropertyType] = useState(null);
    const [addressInfo, setAddressInfo] = useState("");
    const [propertyDetails, setPropertyDetails] = useState([
        {title: "Bedrooms", value: null},
        {title: "Bathrooms", value: null},
        {title: "Levels", value: null},
        {title: "Square Footage", value: null},
        {title: "Lot Size (ft²)", value: null},
        {title: "Garages", value: null},
        {title: "Year Built", value: null},
    ]);
    const [uspInfo, setUspInfo] = useState("");
    const [targetInfo, setTargetInfo] = useState("");
    const [historyInfo, setHistoryInfo] = useState("");
    const [amenityInfo, setAmenityInfo] = useState("");
    const [neighbourInfo, setNeighbourInfo] = useState("");
    const [addInfo, setAddInfo] = useState("");
    const [tone, setTone] = useState(null);

    // Helper functions
    function setPropertyDetailAtIndex(index, newVal) {
        const newTemp = [...propertyDetails];
        newTemp[index].value = newVal;
        setPropertyDetails(newTemp);
    }

    async function generateCompose() {
        const resp = await api_createListingDesc({
            pt: propertyType,
            pa: addressInfo,
            bed: propertyDetails[0].value,
            bath: propertyDetails[1].value,
            lev: propertyDetails[2].value,
            sf: propertyDetails[3].value,
            ls: propertyDetails[4].value,
            gar: propertyDetails[5].value,
            by: propertyDetails[6].value,
            usp: uspInfo,
            ta: targetInfo,
            ph: historyInfo,
            na: amenityInfo,
            nd: neighbourInfo,
            ai: addInfo
        });
        switch (resp.status) {
            case 200:
                const msg = await resp.json();
                return msg.c;
            case 401:
                showUpgrade('Compose');
                return "Compose is a Team feature. Please upgrade your plan to continue.";
            default:
                showError('An unexpected error occurred.');
                return "Sorry, an unexpected error occurred.";
        }
    }

    // Return layout
    return (
        <>
            {(props.stage === 0) &&
                <>
                    <AI_Single_Select val={propertyType} setVal={setPropertyType} allValues={allPropertyTypes} title="Property Type" includeOther={true} otherTitle="Please specify Property Type" otherPlaceholder="e.g. Semi-Detached" />
                    <AI_Input val={addressInfo} setVal={setAddressInfo} title="Property Address" placeholder="e.g. 123 Main Street" />
                    <AI_PropertyDetails vals={propertyDetails} setValAtIndex={setPropertyDetailAtIndex} />
                    <AI_Textbox val={uspInfo} setVal={setUspInfo} title="Unique Selling Points" description="List the key features that make this property special." placeholder="e.g. Picturesque waterfront view, a spacious open-concept layout, a newly renovated kitchen, and a large backyard perfect for entertaining." />
                    <AI_Textbox val={targetInfo} setVal={setTargetInfo} title="Target Audience" description="Describe the ideal buyers or tenants of this property." placeholder="e.g. First-time homebuyers looking for affordability, families seeking a quiet neighborhood, or investors seeking rental income." />
                    <AI_Textbox val={historyInfo} setVal={setHistoryInfo} title="Property History" placeholder="e.g. Recently updated with a new roof, HVAC system, and modernized bathrooms." description="Include details on any relevant renovations, upgrades, repairs, etc." />
                    <AI_Textbox val={amenityInfo} setVal={setAmenityInfo} title="Nearby Amenities" placeholder="e.g. Proximity to Maplewood Elementary School, Woodland Park with playgrounds, Oakview Mall for shopping, and the Metro Transit station for easy commuting." description="Describe nearby schools, parks, shopping centres, transit options, etc." />
                    <AI_Textbox val={neighbourInfo} setVal={setNeighbourInfo} title="Neighbourhood Details" placeholder="e.g. Vibrant arts scene, annual community events, and a strong sense of safety and community." description="Include details on local attractions, community events, safety, etc." />
                    <AI_Textbox val={addInfo} setVal={setAddInfo} title="Additional Information" description="Any other relevant information you’d like to include in the listing description." />
                    <AI_Tone val={tone} setVal={setTone} last={true} />
                </>
            }
            {(props.stage === 1) && <FinishedCompose {...{generate: generateCompose}} /> }
        </>
    );
}

export default Create_ListingDesc;