/*
-------------------------
> AUTOMATIONS API CALLS <
-------------------------
*/

const prefix = `${(process.env.REACT_APP_ENV_MODE === "development") ? process.env.REACT_APP_API_URL_DEV : process.env.REACT_APP_API_URL_PROD}/automations`;


// Fetches automations for current user
export async function getAutomationsForUser() {
    return await fetch(prefix+`/load/all`, {
        method: 'GET', credentials: 'include'
    });
}

export async function loadAutomationWithCode(code) {
    return await fetch(prefix+`/load/${code}`, {
        method: 'GET', credentials: 'include'
    });
}

export async function renameAutomationWithCode(code, name) {
    return await fetch(prefix+`/mv/${code}`, {
        method: 'PATCH', credentials: 'include',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ name: name })
    });
}

// Deletes automation with code
export async function deleteAutomationWithCode(code) {
    return await fetch(prefix+`/forget/${code}`, {
        method: 'DELETE', credentials: 'include'
    });
}

export async function createListingTemplate(name, fg_color, bg_color, questions, integrations) {
    return await fetch(prefix+'/create/lt', {
        method: 'POST', credentials: 'include',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ name: name, fg: fg_color, bg: bg_color, q: questions, intg: integrations })
    });
}

export async function updateListingTemplateWithCode(code, fg_color, bg_color) {
    return await fetch(prefix+`/update/lt/${code}`, {
        method: 'PATCH', credentials: 'include',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ fg: fg_color, bg: bg_color })
    });
}

export async function createIntegrationPackage(name, integrations) {
    return await fetch(prefix+'/create/ip', {
        method: 'POST', credentials: 'include',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ name: name, intg: integrations })
    });
}

export async function updateIntegrationPackageWithCode(code, integrations) {
    return await fetch(prefix+`/update/ip/${code}`, {
        method: 'PATCH', credentials: 'include',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ intg: integrations })
    });
}

export async function createSigninPreset(name, questions) {
    return await fetch(prefix+'/create/sp', {
        method: 'POST', credentials: 'include',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ name: name, q: questions })
    });
}

export async function updateSigninPresetWithCode(code, questions) {
    return await fetch(prefix+`/update/sp/${code}`, {
        method: 'PATCH', credentials: 'include',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ q: questions })
    });
}

export async function createEmailTemplate(name, subject, heading, body, closing, contactName, contactDetails, imageId) {
    return await fetch(prefix+'/create/em', {
        method: 'POST', credentials: 'include',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ data: {n: name, s: subject, h: heading, b: body, c: closing, cn: contactName, ct: contactDetails, i: imageId} })
    });
}

export async function updateEmailTemplateWithCode(code, subject, heading, body, closing, contactName, contactDetails, imageId) {
    return await fetch(prefix+`/update/em/${code}`, {
        method: 'PATCH', credentials: 'include',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ data: {s: subject, h: heading, b: body, c: closing, cn: contactName, ct: contactDetails, i: imageId} })
    });
}