// Dependencies
import { useNavigate } from "react-router-dom";

// API Imports
import { createIntegrationPackage } from "../../../../apicalls/automations.js";

// Util Imports
import { getIntegrationLogo, getIntegrationTitle } from "../../../../utils/sharedIntegrationFuns.js";
import { convertToBackendFormat_integrations } from "../../../../utils/sharedAutomationFuns.js";
import { useError } from '../../../ui/Context/ErrorContext';
import { useUpgrade } from "../../../ui/Context/UpgradeContext";


// PNG Imports
import int_pack from "../../../../img/png/automations/int_pack.png";


// Functional Component
function Review(props) {
    const navigate = useNavigate();
    const { showError } = useError();
    const { showUpgrade } = useUpgrade();

	// Show rows
	function renderIntegrations(integrations) {
		var r = [];
		integrations.forEach(i => {
			r.push(
				<button className="set-int-b">
					<img src={getIntegrationLogo(i.type)} alt="" />
					<h2>{getIntegrationTitle(i.type)}</h2>
				</button>
			);
		});
		return r;
	}

	// Handle creating integration package
	async function handleFinish() {
		const integrations = convertToBackendFormat_integrations(props.integrations);
		const resp = await createIntegrationPackage(props.automationName, integrations);
		switch (resp.status) {
			case 200:
				navigate('/automations',{replace:true});
				break;
			case 401:
				showUpgrade('Automations');
				break;
			default:
				showError('An unexpected error occurred.');
				break;
		}
	}

	// Return layout
	return (
		<>
			<div className="lst-new-i-d">
				<h2>Give your automation a name!</h2>
				<input className="atms-name-in" placeholder="e.g. My Integration Package" onChange={(e) => {props.setAutomationName(e.target.value)}} value={props.automationName || ""}></input>
				<button className="atms-card-b sel rvw">
					<img className="atms-card-img" src={int_pack} alt="" />
					<div className="atms-card-id">
						<h1>{props.automationName || "My Integration Package"}</h1>
						<h3>Integration Package</h3>
					</div>
				</button>
			</div>
			<div className="lst-new-i-d mb0">
				<h2>Integrations</h2>
				<div className="set-int-b-cont">
					{renderIntegrations(props.integrations)}
				</div>
			</div>
			<div className="dash-cont-b-d iflex">
				<button className="back" onClick={props.goBack}>&lt;- Back</button>
				<button
					className={"finish"+((!props.automationName || props.automationName.length === 0) ? " hidden" : "")}
					disabled={(!props.automationName || props.automationName.length === 0)}
					onClick={handleFinish}
				>Create Package</button>
			</div>
		</>
	);
}


export default Review;