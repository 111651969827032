// Dependencies
import React from "react";
import { NavLink, useLocation } from "react-router-dom";

// SVG Imports
import sidebar_logo from "../../img/svg/sidebar/sidebar_logo.svg";
import home_icon from "../../img/svg/sidebar/home.svg";
import plus_icon from "../../img/svg/sidebar/plus.svg";
import db_icon from '../../img/svg/sidebar/db.svg';
import list_icon from "../../img/svg/sidebar/list.svg";
import lead_icon from "../../img/svg/sidebar/lead.svg";
import integrate_icon from "../../img/svg/sidebar/integrate.svg";
import mail_icon from "../../img/svg/sidebar/mail.svg";
import book_icon from "../../img/svg/sidebar/book.svg";
import gear_icon from "../../img/svg/sidebar/gear.svg";
import zap_icon from "../../img/svg/sidebar/zap.svg";
import feather_icon from "../../img/svg/sidebar/feather.svg";


// Functional Component
function Sidebar(props) {
  var currPath = useLocation().pathname;

  function shouldShow(path) {
    return path.slice(0,6) !== "/visit";
  }

  return (
    <>
      { (shouldShow(currPath)) &&
        <div className="sb-cont">
          <div className="sb-logo-d">
            <img alt="" className="sb-logo" src={sidebar_logo} />
          </div>
          <NavLink to="/listings" end className={(navData) => (navData.isActive ? "sb-nl active" : "sb-nl")}>
            <div className="sb-icon-d iflex">
              <img alt="" src={home_icon}></img>
            </div>
          </NavLink>
          <NavLink to="/listings/new" end className={(navData) => {return (navData.isActive ? "sb-nl active" : "sb-nl")}}>
            <div className="sb-icon-d iflex">
              <img alt="" src={plus_icon}></img>
            </div>
          </NavLink>
          <NavLink to="/leads" end className={(navData) => (navData.isActive ? "sb-nl active" : "sb-nl")}>
            <div className="sb-icon-d iflex">
              <img alt="" src={lead_icon}></img>
            </div>
          </NavLink>
          <NavLink to="/automations" end className={(navData) => (navData.isActive ? "sb-nl active" : "sb-nl")}>
            <div className="sb-icon-d iflex">
              <img alt="" src={zap_icon}></img>
            </div>
          </NavLink>
          <NavLink to="/compose" end className={(navData) => (navData.isActive ? "sb-nl active" : "sb-nl")}>
            <div className="sb-icon-d iflex">
              <img alt="" src={feather_icon}></img>
            </div>
          </NavLink>
          {/*<NavLink to="/mail" className={(navData) => (navData.isActive ? "sb-nl active" : "sb-nl")}>
            <div className="sb-icon-d iflex">
              <img alt="" src={mail_icon}></img>
            </div>
          </NavLink>
          <NavLink to="/infobase" className={(navData) => (navData.isActive ? "sb-nl active" : "sb-nl")}>
            <div className="sb-icon-d iflex">
              <img alt="" src={db_icon}></img>
            </div>
          </NavLink>*/}
          <div className="sb-spacer" />
          {/*
          <NavLink to="/guides" className={(navData) => (navData.isActive ? "sb-nl active" : "sb-nl")}>
            <div className="sb-icon-d iflex">
              <img alt="" src={book_icon}></img>
            </div>
          </NavLink>
          */}
          <NavLink to="/settings" className={(navData) => (navData.isActive ? "sb-nl active" : "sb-nl")}>
            <div className="sb-icon-d iflex">
              <img alt="" src={gear_icon}></img>
            </div>
          </NavLink>
        </div>
      }
    </>
  );
}

export default Sidebar;
