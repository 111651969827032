// Dependencies
import { useState } from "react";

// Component Imports
import AddPhotoModal from "../../../modals/AddPhotoModal";

// SVG Imports
import plus_icon from "../../../../img/svg/listing/plus_circle.svg";


// Functional component
function Details(props) {
	// Page states
	const [imageModalOpen, setImageModalOpen] = useState(false);
	const imageModalProps = {
		imageBlobs: props.imageBlobs,
		setImageBlobs: props.setImageBlobs,
		imageUrls: props.imageUrls,
		setImageUrls: props.setImageUrls,
		closeModal: ()=>{setImageModalOpen(false)}
	};

	// Handles click to remove image
	function removeImageNum(e) {
		const index = e.currentTarget.value;
		const tempBlobs = [...props.imageBlobs]; tempBlobs.splice(index, 1);
		const tempUrls = [...props.imageUrls]; tempUrls.splice(index, 1);
		props.setImageBlobs(tempBlobs);
		props.setImageUrls(tempUrls);
	}

	// Return layout
	return (
		<>
			{ (imageModalOpen) && <AddPhotoModal {...imageModalProps} /> }
			<div className="lst-new-i-d">
				<h2>Address</h2>
				<div>
					<input className="half" type="text" placeholder="Street Address" value={props.address1 || ""} onChange={(e)=>{props.setAddress1(e.target.value)}} />
					<input className="half" type="text" placeholder="Apt #, Suite, Floor, etc." value={props.address2 || ""} onChange={(e)=>{props.setAddress2(e.target.value)}} />
				</div>
				<div>
					<input className="qrtr" type="text" placeholder="City" value={props.city || ""} onChange={(e)=>{props.setCity(e.target.value)}} />
					<input className="qrtr" type="text" placeholder="ZIP/Postal Code" value={props.zip || ""} onChange={(e)=>{props.setZip(e.target.value)}} />
					<input className="qrtr" type="text" placeholder="Country" value={props.country || ""} onChange={(e)=>{props.setCountry(e.target.value)}} />
					<input className="qrtr" type="text" placeholder="Region" value={props.region || ""} onChange={(e)=>{props.setRegion(e.target.value)}} />
				</div>
			</div>
			<div className="lst-new-i-d mb0">
				<div className="dash-result-d flex">
					<h2>Photos</h2>
					<div className="dash-result-num">
						<h3>{props.imageUrls.length || "0"}</h3>
					</div>
				</div>
				<div className="img-mng-gal-cont edit">
					{
						props.imageUrls.map((url, index) => {
							return (
								<div className="flex" key={`ik-${index}`}>
									<div className="img-mng-gal-i">
										<img src={url} alt="" />
									</div>
									<button className="img-mng-gal-del-b" value={index} onClick={removeImageNum}>×</button>
								</div>
							);
						})
					}
					<button className="img-mng-gal-add-b" onClick={()=>{setImageModalOpen(true)}}>
						<img src={plus_icon} alt="" />
						<h2>Add Photo</h2>
					</button>
				</div>
			</div>
			<div className="dash-cont-b-d iflex">
				<button className="back" onClick={props.goBack}>&lt;- Back</button>
				<button
					onClick={props.goNext}
					disabled={(props.address1.length > 0 && props.city.length > 0 && props.zip.length > 0 && props.country.length > 0 && props.region.length > 0) ? false : true}
					className={"next" + ((props.address1.length > 0 && props.city.length > 0 && props.zip.length > 0 && props.country.length > 0 && props.region.length > 0) ? "" : " hidden")}
				>Next -&gt;</button>
			</div>
		</>
	);
}

export default Details;