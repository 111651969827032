export function shortenAddress(a) {
    const shortenings = {
        'Avenue': 'Ave',
        'Boulevard': 'Blvd',
        'Court': 'Crt',
        'Crescent': 'Cres',
        'Drive': 'Dr',
        'Lane': 'Ln',
        'Place': 'Pl',
        'Road': 'Rd',
        'Street': 'St'
    };
    for (const [long, short] of Object.entries(shortenings)) {
        if (a.endsWith(long)) {
            a = a.slice(0, -long.length) + short;
            break;
        }
    }
    return a;
}
