/*
----------------------
> LISTINGS API CALLS <
----------------------
*/

const prefix = `${(process.env.REACT_APP_ENV_MODE === "development") ? process.env.REACT_APP_API_URL_DEV : process.env.REACT_APP_API_URL_PROD}/listings`;


export async function getListings() {  
  return await fetch(prefix+'/feed', {
    method: 'GET', credentials: 'include'
  });
}

export async function getUserCategories() {  
  return await fetch(prefix+'/categories', {
    method: 'GET', credentials: 'include'
  });
}

export async function getListingWithCode(code) {
  return await fetch(prefix+`/load/${code}`, {
    method: 'GET', credentials: 'include'
  });
}

export async function createListing(address_1, address_2, city, zipcode, country, region, imageIds, category=null) {  
  return await fetch(prefix+'/new', {
    method: 'POST', credentials: 'include',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({info: {a1: address_1, a2: address_2, city: city, zip: zipcode, country: country, reg: region, imgs: imageIds, cat: category}})
  });
}

export async function updateListingDetailsWithCode(code, address_1, address_2, city, zipcode, country, region, imageIds, categoryId=null) {
  return await fetch(prefix+`/update/details/${code}`, {
    method: 'PUT', credentials: 'include',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({info: {a1: address_1, a2: address_2, city: city, zip: zipcode, country: country, reg: region, imgs: imageIds, cid: categoryId}})
  });
}

export async function updateListingDesignWithCode(code, fg_color, bg_color) {
  return await fetch(prefix+`/update/design/${code}`, {
    method: 'PUT', credentials: 'include',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({info: {fg: fg_color, bg: bg_color}})
  });
}

export async function getAllQuestions() {
  return await fetch(prefix+`/questions/all`, {
      method: 'GET', credentials: 'include'
  });
}

export async function updateListingQuestionsWithCode(code, questions) {
  return await fetch(prefix+`/update/signin/${code}`, {
    method: 'PUT', credentials: 'include',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({q: questions})
  });
}

export async function updateListingEmailWithCode(code, toggle, template) {
  return await fetch(prefix+`/update/email/${code}`, {
    method: 'PATCH', credentials: 'include',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({toggle: toggle, template: template || "def"})
  });
}

export async function createCategoryWithName(name) {
  return await fetch(prefix+`/categories/create`, {
    method: 'POST', credentials: 'include',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({name: name})
  });
}

export async function updateCategoryWithId(id, name) {
  return await fetch(prefix+`/categories/update/${id}`, {
    method: 'PATCH', credentials: 'include',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({name: name})
  });
}

export async function deleteCategoryWithId(id) {
  return await fetch(prefix+`/categories/delete/${id}`, {
    method: 'DELETE', credentials: 'include',
  });
}

export async function deleteListingWithCode(code) {
  return await fetch(prefix+`/forget/${code}`, {
    method: 'DELETE', credentials: 'include',
  });
}
