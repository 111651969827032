/*
------------------
> AUTH API CALLS <
------------------
*/

const prefix = `${(process.env.REACT_APP_ENV_MODE === "development") ? process.env.REACT_APP_API_URL_DEV : process.env.REACT_APP_API_URL_PROD}/auth`;


export async function createUser(name, surname, email, password) {  
  const resp = await fetch(prefix+'/signup', {
      method: 'POST', credentials: 'include',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({info: {name: name, surname: surname, email: email, password: password}})
    });
  return resp.status === 200;
}

export async function checkEmailCode(code) {
  return await fetch(prefix+'/email/verify', {
    method: 'POST', credentials: 'include',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({code: code})
  });
}

export async function resendEmailCode() {
  return await fetch(prefix+'/email/resend', {
    method: 'GET', credentials: 'include'
  });
}

export async function loginUser(email, password) {  
  const resp = await fetch(prefix+'/login', {
      method: 'POST', credentials: 'include',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({username: email, password: password})
    });
  return resp.status === 200;
}

export async function logoutUser() {
  const resp = await fetch(prefix+'/logout', {
    method: 'GET', credentials: 'include'
  });
  return resp.status === 200;
}

export async function getAuthStatus() {
  return await fetch(prefix+'/status', {
    method: 'GET', credentials: 'include'
  });
}

export async function changeUserPassword(password) {
  const resp = await fetch(prefix+'/user/pw/touch', {
    method: 'PATCH', credentials: 'include',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({p: password})
  });
  return resp;
}

export async function deleteUserAccount() {
  const resp = await fetch(prefix+'/user/leave', {
    method: 'GET', credentials: 'include'
  });
  return resp;
}