// Dependencies
import { useState, useEffect } from "react";
import Select from "react-select";

// API Imports
import { getQuestionsWithCode } from "../../../../apicalls/visit.js";
import { updateListingQuestionsWithCode, updateListingEmailWithCode } from "../../../../apicalls/listings.js";
import { getAutomationsForUser, loadAutomationWithCode } from "../../../../apicalls/automations.js";

// Component Imports
import NewQuestionModal from "../../../modals/NewQuestionModal";
import EditQuestionModal from "../../../modals/EditQuestionModal.jsx";

// Util Imports
import { deepCompare } from "../../../../utils/deepCompare.js";
import { formatBackendQuestions } from "../../../../utils/sharedAutomationFuns.js";
import { useError } from '../../../ui/Context/ErrorContext';

// SVG Imports
import plus_icon from "../../../../img/svg/listing/plus_small.svg";
import drag_icon from "../../../../img/svg/signin/drag_icon.svg";
import yn_icon from "../../../../img/svg/signin/yn_icon.svg";
import sc_icon from "../../../../img/svg/signin/sc_icon.svg";
import mc_icon from "../../../../img/svg/signin/mc_icon.svg";
import oe_icon from "../../../../img/svg/signin/oe_icon.svg";

// Constants
const selectStyle = {
    control: (styles, { isFocused }) => ({
        ...styles,
        borderColor: isFocused ? 'var(--orange-accent)' : styles.borderColor,
        boxShadow: (isFocused) ? '0 0 0 1px var(--orange-accent)' : styles.boxShadow,
        '&:hover': {
            borderColor: 'var(--orange-accent)'
        }
    }),
    option: (provided, state) => ({
        ...provided,
        fontFamily: 'Inter',
        fontSize: '15px',
        backgroundColor: state.isFocused ? 'var(--orange-accent-lite)' : null,
        color: 'black',
        ':active': {
            backgroundColor: 'var(--orange-accent)'
        },
    }),
    noOptionsMessage: (styles) => ({
        ...styles,
        fontSize: '15px',
        fontFamily: 'Inter',
        color: 'var(--grey-4)'
    }),
    singleValue: (styles) => ({
        ...styles,
        fontSize: '15px',
        fontFamily: 'Inter',
        color: 'black'
    })
};


// Helper Component
function QuestionList(props) {
    // Draggable
    const [dragId, setDragId] = useState();
    const [dragOverId, setDragOverId] = useState();
    const [dragOverOrder, setDragOverOrder] = useState();

    // Handle clicking a specific question
    function qClick(e) {
        const q_target = props.questions.find(q => q.lid === parseInt(e.target.id));
        props.openEdit(q_target.lid, q_target.q, q_target.type, q_target.options || null, q_target.order);
    }

    // Drag on a question
    function qDragStart(e) {
        e.target.classList.add('dragged');
        setDragId(parseInt(e.target.id));
        setDragOverOrder(props.questions.find((q)=>q.lid===parseInt(e.target.id)).order);
    }

    // Stop dragging
    function qDragEnd(e) {
        const element = e.target;
        element.classList.remove('dragged');
        setDragOverId(null);
        setDragOverOrder(null);
    }

    // Dragged over another question
    function qDragOver(e) {
        e.preventDefault();
        setDragOverId(parseInt(e.target.id));
    }

    // Dropped question - reorder accordingly
    function qDrop(e) {
        const dragQ = props.questions.find((q) => q.lid === dragId);
        const dropQ = props.questions.find((q) => q.lid === parseInt(e.currentTarget.id));

        const dragOrder = dragQ.order; const dropOrder = dropQ.order;

        const newOrder = props.questions.map((q) => {
            if (dragOrder < dropOrder && q.order > dragOrder && q.order <= dropOrder) q.order -= 1;
            else if (q.order >= dropOrder && q.order < dragOrder) q.order += 1;

            if (q.lid === dragId) q.order = dropOrder;

            return q;
        });

        //updateQuestions(newOrder.toSorted((a, b) => a.order - b.order));
        props.updateQuestions(newOrder.toSorted((a, b) => a.order - b.order));
    };

    // Layout rows of questions
    function renderQuestions() {
        var r = [];
        // Order of -1 means they were removed locally
        props.questions.filter(a => a.order !== -1).forEach(q => {
            if (dragOverId && q.lid === dragOverId && q.order < dragOverOrder) r.push(<div key="dragbar" className="dragbar" />);
            r.push(
                <div
                    className="iflex" key={`q-${q.lid}`} id={`${q.lid}a`}
                    draggable={true} onDragStart={qDragStart} onDragEnd={qDragEnd} onDragOver={qDragOver}
                    onDrop={qDrop} onClick={qClick}
                >
                    <div className="iflex" id={`${q.lid}b`} draggable={false}>
                        <img draggable={false} src={drag_icon} id={`${q.lid}c`} alt="" />
                        <h2 draggable={false} id={`${q.lid}d`}>{q.q}</h2>
                    </div>
                    <div className="q-type iflex" id={`${q.lid}e`} draggable={false}>
                        {(q.type === 0) && <img draggable={false} id={`${q.lid}f`} src={yn_icon} alt="" />}
                        {(q.type === 1) && <img draggable={false} id={`${q.lid}f`} src={sc_icon} alt="" />}
                        {(q.type === 2) && <img draggable={false} id={`${q.lid}f`} src={mc_icon} alt="" />}
                        {(q.type === 3) && <img draggable={false} id={`${q.lid}f`} src={oe_icon} alt="" />}
                        {(q.type === 0) && <h3 draggable={false} id={`${q.lid}g`}>Yes / No</h3>}
                        {(q.type === 1) && <h3 draggable={false} id={`${q.lid}g`}>Single Select</h3>}
                        {(q.type === 2) && <h3 draggable={false} id={`${q.lid}g`}>Multi Select</h3>}
                        {(q.type === 3) && <h3 draggable={false} id={`${q.lid}g`}>Open Ended</h3>}
                    </div>
                </div>
            );
            if (dragOverId && q.lid === dragOverId && q.order > dragOverOrder) r.push(<div key="dragbar" className="dragbar" />);
        });
        return r;
    }

    // Return layout
    return( <div className="lst-mng-si-q-cont">{renderQuestions()}</div> );
}

// Functional Component
function Questions(props) {
    const { showError } = useError();
    
    // Data states
    const [ogQuestions, setOgQuestions] = useState(null);
    const [newQuestions, setNewQuestions] = useState(null);
    const [emailToggle, setEmailToggle] = useState(props.emailsOn);
    const [templates, setTemplates] = useState([]); // Email templates
    const [chosenTemplate, setChosenTemplate] = useState(props.emailTemplate);
    const [presets, setPresets] = useState([]); // Question presets
    const [chosenPreset, setChosenPreset] = useState(null);

    // Page states
    const [isAddQuestionOpen, openAddQuestion] = useState(false);
    const [isEditQuestionOpen, openEditQuestion] = useState(false);
    const [editProps, setEditProps] = useState(null);

    // Load questions & presets
    useEffect(()=>{
        async function loadQuestions() {
            const resp = await getQuestionsWithCode(props.listing_code);
            var questions = [];
            if (resp.status !== 200) {
                showError('An unexpected error occurred.');
            } else {
                questions = await resp.json();
            }
            var tempQ = []; var order = 0;
            questions.forEach(q => {
                if (q.type === 1 || q.type === 2) {
                    // Single or multiple choice
                    var options = [];
                    for (var i=1; i<=10; i++) {
                        if (q[`opt_${i}`]) options.push(q[`opt_${i}`]);
                        else break;
                    }
                    // Note: LID = Local ID (because new questions do not have id, we need to distinguish them)
                    tempQ.push({'type': q.type, 'qid': q.question_id, 'lid': order, 'q': q.question_text, 'order': order, 'ogorder': order, 'options': options});
                } else tempQ.push({'type': q.type, 'qid': q.question_id, 'lid': order, 'q': q.question_text, 'order': order, 'ogorder': order });
                order++;
            });
            setOgQuestions(tempQ);
            setNewQuestions(tempQ);
        }
        async function loadPresets() {
            const resp = await getAutomationsForUser();
            if (resp.status !== 200) {
                if (resp.status !== 401) showError('An unexpected error occurred.');
            } else {
                const all_automations = (await resp.json()).data;
                setTemplates(
                    all_automations.reduce((filtered, atm) => {
                        if (atm.type === 3) filtered.push({label: atm.name, value: atm.code});
                        return filtered;
                    }, [])
                );
                setPresets(
                    all_automations.reduce((filtered, atm) => {
                        if (atm.type === 2) filtered.push({label: atm.name, value: atm.code});
                        return filtered;
                    }, [])
                );
            }
        }
        if (!newQuestions || newQuestions.length === 0) loadQuestions();
        if (!presets || presets.length === 0) loadPresets();
    },[]);

    // Load new questions when preset is chosen
    useEffect(()=>{
        async function loadPresetDetails(code) {
            const resp = await loadAutomationWithCode(code);
            if (resp.status !== 200) {
                if (resp.status !== 401) showError('An unexpected error occurred.');
            } else {
                const result = await resp.json();
                const formatted_questions = (formatBackendQuestions(result.data) || []).map((q) => {
                    const formatted = {'type': q.type, 'q': q.text, 'qid': null, 'lid': q.order, 'order': q.order, 'ogorder': q.order};
                    if (q.type === 1 || q.type === 2) formatted['options'] = q.options;
                    return formatted;
                });
                setNewQuestions(formatted_questions);
            }
        }
        if (chosenPreset && chosenPreset.length > 0) loadPresetDetails(chosenPreset);
        if (!chosenPreset) ((ogQuestions) ? resetChanges() : setNewQuestions([]));
    }, [chosenPreset]);

    // Handle uploading changes
    async function uploadChanges() {
        // Update email settings (if necessary)
        if (props.emailsOn !== emailToggle || props.emailTemplate !== chosenTemplate) {
            const email_resp = await updateListingEmailWithCode(props.listing_code, emailToggle, chosenTemplate);
            if (email_resp.status !== 200) {
                showError('An unexpected error occurred.');
            } else {
                // Update email data
                props.setEmailsOn(emailToggle);
                props.setEmailTemplate(chosenTemplate);
            }
        }

        // Update questions (if necessary)
        if (deepCompare(ogQuestions, newQuestions)) return;

        var update_questions = []; var i=0;

        newQuestions.toSorted((a, b) => a.order - b.order).forEach(q => {
            if (q.order === -1) return; // -1 order means question was removed
            if (q.type === 1 || q.type === 2) update_questions.push({qid: q.qid, text: q.q, type: q.type, order: i, options: q.options});
            else update_questions.push({qid: q.qid, text: q.q, type: q.type, order: i});
            i++;
        });

        const resp = await updateListingQuestionsWithCode(props.listing_code, update_questions);
        if (resp.status !== 200) {
            showError('An unexpected error occurred.');
        } else {
            // Update questions
            setNewQuestions(newQuestions.filter(a => a.order !== -1));
            setOgQuestions(newQuestions.filter(a => a.order !== -1));

            // Notify parents
            props.setDidSave(2);
            props.notifyChanges(false);
        }
    }

    // Handle resetting changes
    function resetChanges() {
        // Reset email data
        setEmailToggle(props.emailsOn);
        setChosenTemplate(props.emailTemplate);

        // Reset preset selection
        setChosenPreset(null);

        // Reset questions to OG
        setNewQuestions([...ogQuestions]);

        // Notify parents
        props.setCancelled(false);
        props.notifyChanges(false);
    }

    // Handle adding new questions
    function addNewQuestion(q) {
        const nextOrder = newQuestions.reduce((max, question) => Math.max(max, question.order), 0) + 1;
        switch (q.type) {
            case 0: case 3:
                setNewQuestions([...newQuestions, {'type': q.type, 'qid': null, 'lid': nextOrder, 'q': q.text, order: nextOrder, ogorder: nextOrder}]);
                break;
            case 1: case 2:
                setNewQuestions([...newQuestions, {'type': q.type, 'qid': null, 'lid': nextOrder, 'q': q.text, order: nextOrder, ogorder: nextOrder, options: q.options}]);
                break;
            default:
                break;
        }
    }

    // Return layout
    return (
        <>
            {(isAddQuestionOpen) && <NewQuestionModal {...{addQuestion: addNewQuestion, closeModal: ()=>{openAddQuestion(false)}}} />}
            {(isEditQuestionOpen) && <EditQuestionModal {...editProps} /> }
            <div className="lst-new-i-d">
                <h2>Sign-In Page</h2>
                <div className="iflex">
                    <div className="lst-mng-si-link iflex">
                        <h3>https://listingopen.com/visit/<span className="orange">{`${props.listing_code}`}</span></h3>
                    </div>
                    <button onClick={()=>{window.open(`/visit/${props.listing_code}`, '_blank')}} className="lst-mng-si-launch">Launch</button>
                </div>
            </div>
            <div className="lst-new-i-d">
                <h2>Follow-Up Emails</h2>
                <div className="flex">
                    <h3 className="lst-mng-hs">Visitors will {(!emailToggle) && "not"} be sent automatic follow-up emails after they sign in.</h3>
                    <div className={"lst-mng-toggle flex" + ((emailToggle) ? "" : " off")} onClick={()=>{setEmailToggle(!emailToggle)}}><div /></div>
                </div>
                <div className="cat-in">
                {(emailToggle) ?
                    <Select
                        key={`esk-${chosenTemplate || 0}`}
                        isClearable={true}
                        value={(templates && templates.length > 0 && chosenTemplate) ? (templates.find((e) => e.value === chosenTemplate) || null) : null}
                        options={(templates && templates.length > 0) ? templates : []}
                        onChange={(c)=>{(c) ? setChosenTemplate(c.value) : setChosenTemplate(null)}}
                        styles={selectStyle}
                        disabled={(!templates || templates.length > 0) ? false : true}
                        placeholder="No Templates Selected"
                        noOptionsMessage={(_) => "No templates found"}
                    />
                    :
                    <></> 
                }
                </div>
            </div>
            <div className="lst-new-i-d mng-si mb0">
                <h2>Questions</h2>
                <div className="iflex">
                    <Select
                        key={`qsk-${chosenPreset || 0}`}
                        isClearable={true}
                        value={(presets && presets.length > 0 && chosenPreset) ? presets.find(e => e.value === chosenPreset) || null : null}
                        options={(presets && presets.length > 0) ? presets : []}
                        onChange={(c)=>{(c) ? setChosenPreset(c.value) : setChosenPreset(null)}}
                        styles={selectStyle}
                        disabled={(!presets || presets.length > 0) ? false : true}
                        placeholder="No Presets Selected"
                        noOptionsMessage={(_) => "No presets found"}
                    />
                    <button onClick={()=>{openAddQuestion(true)}}><img src={plus_icon} alt="" />Add Question</button>
                </div>
                <div className="content-spacer" />
                {(newQuestions) &&
                    <QuestionList {...{
                        openEdit: (lid,text,type,options,order)=>{
                            setEditProps({
                                closeModal:()=>{openEditQuestion(false)},
                                updateQuestion: (lid,text,options,newOrder)=>{
                                    setNewQuestions(newQuestions.map((a) => (a.lid === lid ? { ...a, q: text, options: options, order: newOrder } : a)).toSorted((a, b) => a.order - b.order))
                                },
                                lid:lid,text:text,type:type,options:options,order:order}
                            );
                            openEditQuestion(true);},
                            listing_code: props.listing_code, questions: newQuestions, updateQuestions: setNewQuestions,
                            notifyChanges: props.notifyChanges, setCancelled: props.setCancelled, didCancel: props.didCancel, setDidSave: props.setDidSave, didSave: props.didSave}}
                    />
                }
            </div>
            <div className="dash-cont-b-d iflex">
                <button
                    className={"back" + ((deepCompare(newQuestions, ogQuestions) && props.emailsOn === emailToggle && props.emailTemplate === chosenTemplate) ? " hidden" : "")}
                    disabled={deepCompare(newQuestions, ogQuestions) && props.emailsOn === emailToggle && props.emailTemplate === chosenTemplate}
                    onClick={resetChanges}>Reset</button>
                <button
                    className={"finish" + ((deepCompare(newQuestions, ogQuestions) && props.emailsOn === emailToggle && props.emailTemplate === chosenTemplate) ? " hidden" : "")}
                    disabled={deepCompare(newQuestions, ogQuestions) && props.emailsOn === emailToggle && props.emailTemplate === chosenTemplate}
                    onClick={uploadChanges}
                >Save & Publish</button>
            </div>
        </>
    );
}

export default Questions;