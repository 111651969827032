// Dependencies
import { useState, useEffect } from 'react';

// API Imports
import { getCurrPlan, openPortal, upgradePlan } from "../../../apicalls/billing.js";

// Util Imports
import { useError } from "../../ui/Context/ErrorContext";

// SVG Imports
import icon_standard from "../../../img/svg/signup/standard_plan_lrg.svg";
import icon_premium from "../../../img/svg/signup/premium_plan_lrg.svg";
import icon_enterprise from "../../../img/svg/signup/enterprise_plan_lrg.svg";
import icon_orange_check from "../../../img/svg/signup/check_icon.svg";

// Helper Functions
function isActive(type, currPlan) {
	switch (currPlan) {
		case 0: case 1:
			return type === "standard";
		case 2: case 3:
			return type === "premium";
		case 4:
			return type === "enterprise";
		default:
			return false;
	}
}

function isDowngrade(type, currPlan) {
	switch (type) {
		case "standard":
			return currPlan >= 2;
		case "premium":
			return currPlan >= 4;
		default:
			return false;
	}
}

function getPrice(type, period) {
	switch (type) {
		case "standard":
			return ((period) ? "$27" : "$32");
		case "premium":
			return ((period) ? "$97" : "$115");
		default:
			return "Get In Touch";
	}
}

function getFullPrice(type) {
	switch (type) {
		case "standard":
			return "$32";
		case "premium":
			return "$115";
		default:
			return "";
	}
}

// Functional Component
function PlanCard({
	type, period, currPlan, handleManage, handleUpgrade, handleContact, isLoading
}) {
	const [act, _setAct] = useState(isActive(type, currPlan));

	// Return layout 
	return (
		<div className={"set-bil-pln-d"+((act) ? " sel" : "")}>
			<div className="top flex">
				{(type === "standard") && <img src={icon_standard} alt="" /> }
				{(type === "premium") && <img src={icon_premium} alt="" /> }
				{(type === "enterprise") && <img src={icon_enterprise} alt="" /> }
				{(act) && <div className=""><h3>Current Plan</h3></div>}
			</div>
			{(type === "standard") &&
				<>
					<h1>Agent</h1>
					<h3>Powerful tools for exceptional agents.</h3>
					<h2>What you get:</h2>
					<div className="ft">
						<div className="flex"><img src={icon_orange_check} alt="" /><h3>Unlimited Listings</h3></div>
						<div className="flex"><img src={icon_orange_check} alt="" /><h3>Custom Follow-Up Emails</h3></div>
						<div className="flex"><img src={icon_orange_check} alt="" /><h3>Custom Sign-In Design</h3></div>
						<div className="flex"><img src={icon_orange_check} alt="" /><h3>Unlimited Automations</h3></div>
						<div className="flex"><img src={icon_orange_check} alt="" /><h3>Unlimited Integrations</h3></div>
						<div className="flex"><img src={icon_orange_check} alt="" /><h3>15+ AI Tools</h3></div>
					</div>
				</>
			}
			{(type === "premium") &&
				<>
					<h1>Team</h1>
					<h3>Premier features and support for elite teams.</h3>
					<h2>Everything in Agent, plus...</h2>
					<div className="ft">
						<div className="flex"><img src={icon_orange_check} alt="" /><h3>5 Team Seats</h3></div>
						<div className="flex"><img src={icon_orange_check} alt="" /><h3>Organization Account</h3></div>
						<div className="flex"><img src={icon_orange_check} alt="" /><h3>Migration Support</h3></div>
						<div className="flex"><img src={icon_orange_check} alt="" /><h3>Unlimited Seats for $7/mo.</h3></div>
					</div>
				</>
			}
			{(type === "enterprise") &&
				<>
					<h1>Brokerage</h1>
					<h3>Custom solutions for high-performing brokerages.</h3>
					<h2>Everything in Team, plus...</h2>
					<div className="ft">
						<div className="flex"><img src={icon_orange_check} alt="" /><h3>Custom Email Branding</h3></div>
						<div className="flex"><img src={icon_orange_check} alt="" /><h3>Custom Sign-In Branding</h3></div>
						<div className="flex"><img src={icon_orange_check} alt="" /><h3>Dedicated Support</h3></div>
						<div className="flex"><img src={icon_orange_check} alt="" /><h3>Custom Workflows</h3></div>
					</div>
				</>
			}
			{(!isDowngrade(type, currPlan)) &&
				<>
					{(type !== "enterprise" && !act) &&
						<div className="pr flex">
							<h2>Price</h2>
							<div className="flex"><h1>{getPrice(type, period)}{(type !== "enterprise" && period === 1) ? <span>{getFullPrice(type)}</span> : <></>}</h1>{(type !== "enterprise") && <h3 className="mo">/ mo.</h3>}</div>
						</div>
					}
					{(act) ?
						<button className={"mng"+((isLoading) ? " hidden" : "")} onClick={handleManage}>Manage your plan</button>
						:
						<button className={"upg"+((isLoading) ? " hidden" : "")} onClick={()=>{if (type === "enterprise") handleContact(); else handleUpgrade(type,period);}}>{(type === "enterprise") ? "Contact Us" : "Upgrade"}</button>
					}
				</>
			}
		</div>
	)
}

// Functional Component
function Billing() {
	const { showError } = useError();

	// Page states
	const [isLoading, setIsLoading] = useState(false);
	const [currPlan, setCurrPlan] = useState(-1);
	const [loaderOn, setLoaderOn] = useState(false);
	const [period, setPeriod] = useState(1);

	// Get current plan
	useEffect(()=>{
		async function loadPage() {
			const resp = await getCurrPlan();
            setTimeout(()=>{setLoaderOn(true)}, 500); // Turn preloader on after 0.5s
			if (resp.status !== 200) showError('An unexpected error occurred.');
			else {
				setCurrPlan((await resp.json()).plan);
				setIsLoading(false);
			}
		}
		loadPage();
	},[]);

	// Helper Functions
	async function handleManage() {
		if (isLoading) return;
		const resp = await openPortal();
		if (resp.status !== 200) showError('An unexpected error occurred.');
		else window.open((await resp.json()).link,'_blank');
	}

	async function handleUpgrade(type, period) {
		if (isLoading) return;
		setIsLoading(true);
        const resp = await upgradePlan(type, period);
		if (resp.status === 200) return window.open('/settings','_self'); // Back to Settings
		showError('An unexpected error occurred.');
		setIsLoading(false);
	}

	function handleContact() {
		if (isLoading) return;
		window.open('https://listingopen.com/brokerage', "_blank");
	}

	// Return layout
	return (
		<>
			{(currPlan >= 0) ?
				<>
					<div className="set-bil-per-slider flex">
						<div className={(period === 1) ? "sel" : ""} onClick={()=>{setPeriod(1)}}><h3>Annual</h3></div>
						<div className={(period === 0) ? "sel" : ""} onClick={()=>{setPeriod(0)}}><h3>Monthly</h3></div>
					</div>
					<div className="lst-new-i-d mb0"><h2>Account Plans</h2></div>
					<div className="set-bil-cont">
						<PlanCard type="standard" isLoading={isLoading} period={period} currPlan={currPlan} handleManage={handleManage} handleUpgrade={handleUpgrade} />
						<PlanCard type="premium" isLoading={isLoading} period={period} currPlan={currPlan} handleManage={handleManage} handleUpgrade={handleUpgrade} />
						<PlanCard type="enterprise" isLoading={isLoading} period={period} currPlan={currPlan} handleContact={handleContact} />
					</div>
				</>
				:
            	<>{(loaderOn) && <div className="prl-spin-cont"><div className="prl-spin" /></div>}</>
			}
		</>
	)

}

export default Billing;