// Dependencies
import { useNavigate } from "react-router-dom";

// API Imports
import { createSigninPreset } from "../../../../apicalls/automations.js";

// Util Imports
import { renderQuestionList } from "../../../../utils/sharedQuestionFuns.js";
import { convertToBackendFormat_questions } from "../../../../utils/sharedAutomationFuns.js";
import { useError } from '../../../ui/Context/ErrorContext';
import { useUpgrade } from "../../../ui/Context/UpgradeContext";

// PNG Imports
import si_preset from "../../../../img/png/automations/si_preset.png";


// Functional Component
function Review(props) {
	const navigate = useNavigate();
    const { showError } = useError();
    const { showUpgrade } = useUpgrade();

	// Handle creating question list
	async function handleFinish() {
		const questions = convertToBackendFormat_questions(props.questions);
		const resp = await createSigninPreset(props.automationName, questions);
		switch (resp.status) {
			case 200:
				navigate('/automations',{replace:true});
				break;
			case 401:
				showUpgrade('Automations');
				break;
			default:
				showError('An unexpected error occurred.');
				break;
		}
	}

	// Return layout
	return (
		<>
			<div className="lst-new-i-d">
				<h2>Give your automation a name!</h2>
				<input className="atms-name-in" placeholder="e.g. My Sign-In Preset" onChange={(e) => {props.setAutomationName(e.target.value)}} value={props.automationName || ""}></input>
				<button className="atms-card-b sel rvw">
					<img className="atms-card-img" src={si_preset} alt="" />
					<div className="atms-card-id">
						<h1>{props.automationName || "My Sign-In Preset"}</h1>
						<h3>Sign-In Preset</h3>
					</div>
				</button>
			</div>
			<div className="lst-new-i-d mb0">
				<h2>Questions</h2>
				{renderQuestionList(props.questions)}
			</div>
			<div className="dash-cont-b-d iflex">
				<button className="back" onClick={props.goBack}>&lt;- Back</button>
				<button
					className={"finish"+((!props.automationName || props.automationName.length === 0) ? " hidden" : "")}
					disabled={(!props.automationName || props.automationName.length === 0)}
					onClick={handleFinish}
				>Create Preset</button>
			</div>
		</>
	);
}


export default Review;