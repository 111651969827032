// Dependencies
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

// API Imports
import { getListingWithCode } from "../../../apicalls/listings";

// Component Imports
import Details from "./comp/Details";
import DeleteListing from "./comp/DeleteListing";
import Questions from "./comp/Questions";
import Leads from "./comp/Leads";
import LeadProfile from "./comp/LeadProfile";
import ExportLeads from "../../leads/Export";
import FilterLeads from "../../leads/Filter";
import Integrations from "./comp/Integrations";
import UpdateIntegration from "./comp/UpdateIntegration";
import CreateIntegration from "./comp/CreateIntegration";
import Design from "./comp/Design";

// Util Imports
import { useError } from '../../ui/Context/ErrorContext';
import getPhotoUrlFromId from "../../../utils/getPhotoUrlFromId.js";
import setTitleText from "../../../utils/setTitleText.js";

// SVG Imports
import pin_icon from "../../../img/svg/listing/pin.svg";

// Functional Component
function Controller() {
    // Current page
    const { id } = useParams();
    const { showError } = useError();

    // Page states
    const [didLoad, setDidLoad] = useState(false);
    const [loaderOn, setLoaderOn] = useState(false);
    const [page, setPage] = useState(0);
    const [didChange, setDidChange] = useState(0);
    const [didSave, setDidSave] = useState(0);
    const [didCancel, setCancelled] = useState(false);

    // Listing Data
    const [listingName, setListingName] = useState(null);
    const [listingAddress, setListingAddress] = useState([null, null]);
    const [listingDetails, setListingDetails] = useState({
        address1: null,
        address2: null,
        category: null,
        city: null,
        country: null,
        id: null,
        region: null,
        zipcode: null
    });
    const [listingPhotos, setListingPhotos] = useState([]);
    const detailProps = {
        listingPhotos: listingPhotos,
        setListingPhotos: setListingPhotos,
        details: listingDetails,
        listing_code: id,
        setPage: setPage,
        notifyChanges: setDidChange,
        didSave: didSave,
        setDidSave: setDidSave,
        didCancel: didCancel,
        setCancelled: setCancelled
    };

    // Questions Data
    const [emailsOn, setEmailsOn] = useState(false);
    const [emailTemplate, setEmailTemplate] = useState(null);
    const questionProps = {
        listing_code: id,
        emailsOn: emailsOn,
        setEmailsOn: setEmailsOn,
        emailTemplate: emailTemplate,
        setEmailTemplate: setEmailTemplate,
        notifyChanges: setDidChange,
        didSave: didSave,
        setDidSave: setDidSave,
        didCancel: didCancel,
        setCancelled: setCancelled
    }

    // Leads Data
    const [leadId, setLeadId] = useState(0);
    const [all_leads, setLeads] = useState([]);
    const [filtered_leads, setFilteredLeads] = useState([]);
    const leadsProps = {
        listing_code: id,
        leads: all_leads,
        setLeads: setLeads,
        filteredLeads: filtered_leads,
        setFilteredLeads: setFilteredLeads,
        listing_name: listingName,
        listingAddress: listingAddress,
        openExportLeads: openExportLeads,
        openFilterLeads: openFilterLeads,
        openLead: openLead
    }
    const leadProfileProps = {
        id: leadId,
        listing_code: id,
        listing_name: listingName,
        removeLead: (id)=>{
            setLeads([...all_leads].filter((l) => `${l.id}` !== id));
            setFilteredLeads([...filtered_leads].filter((l) => `${l.id}` !== id));
        },
        goBack: ()=>{setPage(2)}
    }
    const exportLeadsProps = {
        all_leads: all_leads,
        filtered_leads: filtered_leads,
        goBack: ()=>{setPage(2)}
    }
    const [codesRequired, setCodesRequired] = useState([]);
	const [phoneRequired, setPhoneRequired] = useState(false);
	const [emailRequired, setEmailRequired] = useState(false);
	const [questionsRequired, setQuestionsRequired] = useState({});
    const filterLeadsProps = {
        all_leads: all_leads,
        setFilteredLeads: setFilteredLeads,
        listingCodesRequired: codesRequired,
        setListingCodesRequired: setCodesRequired,
        phoneRequired: phoneRequired,
        setPhoneRequired: setPhoneRequired,
        emailRequired: emailRequired,
        setEmailRequired: setEmailRequired,
        questionsRequired: questionsRequired,
        setQuestionsRequired: setQuestionsRequired,
        goBack: ()=>{setPage(2)},
        isManageListing: true
    }

    // Integrations Data
    const [integId, setIntegrationId] = useState("");
    const [integType, setIntegrationType] = useState("");
    const integrationProps = {
        openIntegration: openIntegration,
        createIntegration: createIntegration,
        listing_code: id,
        notifyChanges: setDidChange,
        didSave: didSave,
        setDidSave: setDidSave,
        didCancel: didCancel,
        setCancelled: setCancelled
    };

    // Design Data
    const designProps = {
        details: listingDetails,
        listing_code: id,
        notifyChanges: setDidChange,
        didSave: didSave,
        setDidSave: setDidSave,
        didCancel: didCancel,
        setCancelled: setCancelled
    };


    // Open info for lead
    function openLead(id) {
        setPage(2.1);
        setLeadId(id);
    }

    function openExportLeads() {
        setPage(2.2);
    }

    function openFilterLeads() {
        setPage(2.3);
    }

    // Open info for integration
    function openIntegration(id) {
        setIntegrationId(id);
        setPage(3.1);
    }

    // Create new integration
    function createIntegration(type) {
        setIntegrationType(type);
        setPage(3.2);
    }

    // Load listing details
    async function loadDetails() {
        const resp = await getListingWithCode(id);
        setTimeout(()=>{setLoaderOn(true)}, 500); // Turn preloader on after 0.5s

        if (resp.status !== 200) {
            showError('An unexpected error occurred.');
        } else {
            const all_data = await resp.json();
            const data = all_data.data;
            const listing_name_temp = data.address_1 + (data.address_2 ? `, ${data.address_2}`: "");
            setListingName(listing_name_temp);
            setTitleText(listing_name_temp);
            setListingAddress([data.address_1, data.address_2 || null]);
            setListingDetails({
                address1: data.address_1,
                address2: data.address_2,
                category: data.category,
                city: data.city,
                country: data.country,
                id: data.id,
                region: data.region,
                zipcode: data.zipcode,
                fg_theme: data.fg_theme,
                bg_theme: data.bg_theme
            });
            setEmailsOn((data.email_toggle) ? true : false);
            if (data.email_template !== "") setEmailTemplate(data.email_template);
            setListingPhotos(all_data.imgs.map((i,index) => { return {url: getPhotoUrlFromId(i.photo_id), order: index, status: true} }));
            setDidLoad(true);
        }
    }

    // Load listing details on open page
    useEffect(()=>{
        loadDetails();
    },[]);

    // Reload listing details when changes are published
    async function updateDetails() {
        const resp = await loadDetails();
        if (resp) setDidSave(0);
        else showError("Failed to load changes.");
    }
    useEffect(()=>{
        if (didSave === 2) updateDetails();
    },[didSave]);

    // Return layout
    return (
        <>
            {(didLoad) ?
                <>
                    {(page === 0.1) && <DeleteListing {...{listing_code: id, setPage: setPage}} />}
                    {(page === 2.2) && <ExportLeads {...exportLeadsProps} /> }
                    {(page === 2.3) && <FilterLeads {...filterLeadsProps} />}
                    {(page !== 0.1 && page !== 2.2 && page !== 2.3) && 
                        <div className={"lst-mng-cont" + ((page === 2.5) ? " white" : "")}>
                            {(page !== 2.5) &&
                                <div className="lst-mng-top-cont">
                                    <div className="lst-mng-top-info iflex">
                                        {(listingPhotos && listingPhotos.length > 0) ?
                                            <img src={listingPhotos[0].url} alt="" />
                                            :
                                            <div className="placeholder" />
                                        }
                                        <div className="lst-mng-t-d">
                                            <h1 className="pg-t">{listingName || ""}</h1>
                                            <div className="lst-mng-place-d iflex">
                                                <img src={pin_icon} alt="" />
                                                <h3>{listingDetails.city || ""}</h3>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="lst-mng-top-nav iflex">
                                        <button onClick={()=>{setPage(0)}}><h3>Details</h3><div className={(page === 0) ? "a" : ""} /></button>
                                        <button onClick={()=>{setPage(1)}}><h3>Sign-In</h3><div className={(page === 1) ? "a" : ""} /></button>
                                        <button onClick={()=>{setPage(2)}}><h3>Leads</h3><div className={(page === 2) ? "a" : ""} /></button>
                                        <button onClick={()=>{setPage(3)}}><h3>Integrations</h3><div className={(page === 3) ? "a" : ""} /></button>
                                        <button onClick={()=>{setPage(4)}}><h3>Design</h3><div className={(page === 4) ? "a" : ""} /></button>
                                    </div>
                                </div>
                            }
                            <div className="lst-mng-content">
                                {(page === 0) && <Details {...detailProps}/>}
                                {(page === 1) && <Questions {...questionProps} />}
                                {(page === 2) && <Leads {...leadsProps} />}
                                {(page === 2.1) && <LeadProfile {...leadProfileProps} />}
                                {(page === 3) && <Integrations {...integrationProps} />}
                                {(page === 3.1) && <UpdateIntegration {...{id: integId, listing_name: listingName, listing_code: id, goBack: ()=>{setPage(3)}}} />}
                                {(page === 3.2) && <CreateIntegration {...{type: integType, listing_name: listingName, listing_code: id, goBack: ()=>{setPage(3)}}} />}
                                {(page === 4) && <Design {...designProps} />}
                            </div>
                            { (didChange) &&
                                <div className="lst-mng-chng-cont iflex">
                                    <h3>You have unsaved changes.</h3>
                                    <button className="cancel" onClick={()=>{setCancelled(true)}}>Cancel</button>
                                    <button onClick={()=>{setDidSave(1)}}>Save & Publish</button>
                                </div>
                            }
                        </div>
                    }
                </>
                :
                <>{(loaderOn) && <div className="prl-spin-cont nt"><div className="prl-spin" /></div>}</>
            }
        </>
    );
}

export default Controller;