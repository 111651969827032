// Dependencies
import { useState, useEffect } from "react";
import Select from "react-select";

// Component Imports
import NewQuestionModal from "../../../modals/NewQuestionModal";
import EditQuestionModal from "../../../modals/EditQuestionModal.jsx";

// Util Imports
import { deepCompare } from "../../../../utils/deepCompare.js";
import { getAutomationSmallIcon } from "../../../../utils/sharedAutomationFuns.js";

// SVG Imports
import plus_icon from "../../../../img/svg/listing/plus_small.svg";
import drag_icon from "../../../../img/svg/signin/drag_icon.svg";
import yn_icon from "../../../../img/svg/signin/yn_icon.svg";
import sc_icon from "../../../../img/svg/signin/sc_icon.svg";
import mc_icon from "../../../../img/svg/signin/mc_icon.svg";
import oe_icon from "../../../../img/svg/signin/oe_icon.svg";


// Helper Component
function QuestionList(props) {
    // Draggable
    const [dragId, setDragId] = useState();
    const [dragOverId, setDragOverId] = useState();
    const [dragOverOrder, setDragOverOrder] = useState();

    // Handle clicking a specific question
    function qClick(e) {
        const q_target = props.questions.find(q => q.lid === parseInt(e.target.id));
        props.openEdit(q_target.lid, q_target.text, q_target.type, q_target.options || null, q_target.order);
    }

    // Drag on a question
    function qDragStart(e) {
        e.target.classList.add('dragged');
        setDragId(parseInt(e.target.id));
        setDragOverOrder(props.questions.find((q)=>q.lid===parseInt(e.target.id)).order);
    }

    // Stop dragging
    function qDragEnd(e) {
        const element = e.target;
        element.classList.remove('dragged');
        setDragOverId(null);
        setDragOverOrder(null);
    }

    // Dragged over another question
    function qDragOver(e) {
        e.preventDefault();
        setDragOverId(parseInt(e.target.id));
    }

    // Dropped question - reorder accordingly
    function qDrop(e) {
        const dragQ = props.questions.find((q) => q.lid === dragId);
        const dropQ = props.questions.find((q) => q.lid === parseInt(e.currentTarget.id));

        const dragOrder = dragQ.order; const dropOrder = dropQ.order;

        const newOrder = props.questions.map((q) => {
            if (dragOrder < dropOrder && q.order > dragOrder && q.order <= dropOrder) q.order -= 1;
            else if (q.order >= dropOrder && q.order < dragOrder) q.order += 1;

            if (q.lid === dragId) q.order = dropOrder;

            return q;
        });

        //updateQuestions(newOrder.toSorted((a, b) => a.order - b.order));
        props.updateQuestions(newOrder.toSorted((a, b) => a.order - b.order));
    };

    // Layout rows of questions
    function renderQuestions() {
        var r = [];
        // Order of -1 means they were removed locally
        props.questions.filter(a => a.order !== -1).forEach(q => {
            if (dragOverId && q.lid === dragOverId && q.order < dragOverOrder) r.push(<div key="dragbar" className="dragbar" />);
            r.push(
                <div
                    className="iflex" key={`q-${q.lid}`} id={`${q.lid}a`}
                    draggable={true} onDragStart={qDragStart} onDragEnd={qDragEnd} onDragOver={qDragOver}
                    onDrop={qDrop} onClick={qClick}
                >
                    <div className="iflex" id={`${q.lid}b`} draggable={false}>
                        <img draggable={false} src={drag_icon} id={`${q.lid}c`} alt="" />
                        <h2 draggable={false} id={`${q.lid}d`}>{q.text}</h2>
                    </div>
                    <div className="q-type iflex" id={`${q.lid}e`} draggable={false}>
                        {(q.type === 0) && <img draggable={false} id={`${q.lid}f`} src={yn_icon} alt="" />}
                        {(q.type === 1) && <img draggable={false} id={`${q.lid}f`} src={sc_icon} alt="" />}
                        {(q.type === 2) && <img draggable={false} id={`${q.lid}f`} src={mc_icon} alt="" />}
                        {(q.type === 3) && <img draggable={false} id={`${q.lid}f`} src={oe_icon} alt="" />}
                        {(q.type === 0) && <h3 draggable={false} id={`${q.lid}g`}>Yes / No</h3>}
                        {(q.type === 1) && <h3 draggable={false} id={`${q.lid}g`}>Single Select</h3>}
                        {(q.type === 2) && <h3 draggable={false} id={`${q.lid}g`}>Multi Select</h3>}
                        {(q.type === 3) && <h3 draggable={false} id={`${q.lid}g`}>Open Ended</h3>}
                    </div>
                </div>
            );
            if (dragOverId && q.lid === dragOverId && q.order > dragOverOrder) r.push(<div key="dragbar" className="dragbar" />);
        });
        return r;
    }

    // Return layout
    return(
        <>
            {(props.questions.filter(a => a.order !== -1).length > 0) ?
                <div className="lst-mng-si-q-cont">{renderQuestions()}</div>
            :
                <div className="empty-q-d" />
            }
        </>
    );
}

// Functional Component
function Questions(props) {
    // Custom style for selector
    const selectStyle = {
        control: (styles, { isFocused }) => ({
            ...styles,
            borderColor: isFocused ? 'var(--orange-accent)' : styles.borderColor,
            boxShadow: (isFocused) ? '0 0 0 1px var(--orange-accent)' : styles.boxShadow,
            '&:hover': {
                borderColor: 'var(--orange-accent)'
            }
        }),
        option: (provided, state) => ({
            ...provided,
            fontFamily: 'Inter',
            fontSize: '15px',
            backgroundColor: state.isFocused ? 'var(--orange-accent-lite)' : null,
            color: 'black',
            ':active': {
                backgroundColor: 'var(--orange-accent)'
            },
        }),
        noOptionsMessage: (styles) => ({
            ...styles,
            fontSize: '15px',
            fontFamily: 'Inter',
            color: 'var(--grey-4)'
        }),
        singleValue: (styles) => ({
            ...styles,
            fontSize: '15px',
            fontFamily: 'Inter',
            color: 'black'
        })
    };

    const [presets, setPresets] = useState(null);

    // Wrapper for choosing preset
    function choosePreset(p) {
        if (p) {
            props.setChosenPreset(p.value);
        } else {
            props.setChosenPreset(null);
        }
    }

    // Filter automations for only sign-in presets
    useEffect(()=>{
        setPresets(
            props.automations.reduce((filtered, atm) => {
                if (atm.type === 2 || (atm.type === 0 && atm.code === props.chosenTemplate)) filtered.push({label: atm.name, value: atm.code});
                return filtered;
            }, [])
        );
    }, []);

    // Data states
    const [newQuestions, setNewQuestions] = useState(null);

    // Page states
    const [isAddQuestionOpen, openAddQuestion] = useState(false);
    const [isEditQuestionOpen, openEditQuestion] = useState(false);
    const [editProps, setEditProps] = useState(null);

    // Load questions
    useEffect(()=>{
        setNewQuestions(props.questions.map(q => {
            return {...q, 'lid': q.order, 'ogorder': q.order};
        }));
    },[props.questions]);

    // Handle saving changes
    async function saveChanges() {
        var update_questions = []; var i=0;

        newQuestions.toSorted((a, b) => a.order - b.order).forEach(q => {
            if (q.order === -1) return; // -1 order means question was removed
            if (q.type === 1 || q.type === 2) update_questions.push({text: q.text, type: q.type, order: i, options: q.options});
            else update_questions.push({text: q.text, type: q.type, order: i});
            i++;
        });

        props.setQuestions(update_questions);
    }

    // Handle adding new questions
    function addNewQuestion(q) {
        const nextOrder = newQuestions.reduce((max, question) => Math.max(max, question.order), 0) + 1;
        switch (q.type) {
            case 0: case 3:
                setNewQuestions([...newQuestions, {'type': q.type, 'qid': null, 'lid': nextOrder, 'text': q.text, order: nextOrder, ogorder: nextOrder}]);
                break;
            case 1: case 2:
                setNewQuestions([...newQuestions, {'type': q.type, 'qid': null, 'lid': nextOrder, 'text': q.text, order: nextOrder, ogorder: nextOrder, options: q.options}]);
                break;
            default:
                break;
        }
    }

    // Wrapper for next page
    function handleNext() {
        saveChanges()
        props.goNext();
    }

    // Return layout
    return (
        <>
            {(isAddQuestionOpen) && <NewQuestionModal {...{addQuestion: addNewQuestion, closeModal: ()=>{openAddQuestion(false)}}} />}
            {(isEditQuestionOpen) && <EditQuestionModal {...editProps} /> }
            {(presets) &&
                <>
                    <div className="lst-new-i-d">
                        <div className="iflex atms-lst-t">
                            {getAutomationSmallIcon(2)}
                            <h2>Sign-In Presets</h2>
                        </div>
                        <Select
                            isClearable={true}
                            defaultValue={(props.chosenPreset) ? presets.find(e => e.value === props.chosenPreset) || null : null}
                            options={(presets.length > 0) ? presets : []}
                            onChange={(c)=>{(c) ? choosePreset(c) : choosePreset(null)}}
                            styles={selectStyle}
                            disabled={(presets.length > 0) ? false : true}
                            placeholder="No Presets Selected"
                            noOptionsMessage={(_) => "No presets found"}
                        />
                    </div>
                    <div className="content-spacer" />
                </>
            }
            <div className="lst-new-i-d mng-si mb0">
                <div className="lst-q-t-d">
                    <h2>{(newQuestions) ? newQuestions.filter(a => a.order !== -1).length : ""} Questions</h2>
                    <button onClick={()=>{openAddQuestion(true)}}><img src={plus_icon} alt="" />Add Question</button>
                </div>
                {(newQuestions) &&
                    <QuestionList {...{
                        openEdit: (lid,text,type,options,order)=>{
                            setEditProps({
                                closeModal:()=>{openEditQuestion(false)},
                                updateQuestion: (lid,text,options,newOrder)=>{
                                    setNewQuestions(newQuestions.map((a) => (a.lid === lid ? { ...a, text: text, options: options, order: newOrder } : a)).toSorted((a, b) => a.order - b.order))
                                },
                                lid:lid,text:text,type:type,options:options,order:order}
                            );
                            openEditQuestion(true);
                        },
                        listing_code: props.listing_code, questions: newQuestions, updateQuestions: setNewQuestions}}
                    />
                }
            </div>
            <div className="dash-cont-b-d iflex">
				<button className="back" onClick={props.goBack}>&lt;- Back</button>
				<button className={"next"+((!newQuestions || newQuestions.filter(a => a.order !== -1).length === 0) ? " hidden" : "")} onClick={handleNext} disabled={!newQuestions || newQuestions.filter(a => a.order !== -1).length === 0}>Next -&gt;</button>
			</div>
        </>
    );
}

export default Questions;