/*
----------------------
> SIGN IN API CALLS <
----------------------
*/

const prefix = `${(process.env.REACT_APP_ENV_MODE === "development") ? process.env.REACT_APP_API_URL_DEV : process.env.REACT_APP_API_URL_PROD}/visit`;


export async function getQuestionsWithCode(code) {  
  return await fetch(prefix+`/load/${code}`, {
    method: 'GET', credentials: 'include'
  });
}
  
export async function submitAnswersWithCode(code, contact, answers) {  
  return await fetch(prefix+`/submit/${code}`, {
    method: 'POST', credentials: 'include',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({contact: contact, answers: answers})
  });
}

export async function getNotesWithCode(code) {
  return await fetch(prefix+`/notes/load/${code}`, {
    method: 'GET', credentials: 'include'
  });
}

export async function saveNotesFor(code, lead_id, notes) {
  return await fetch(prefix+`/notes/touch/${code}`, {
    method: 'PATCH', credentials: 'include',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({id: lead_id, notes: notes})
  });
}