// Dependencies
import { useState } from 'react';

// API Imports
import { api_createOpenHouseInvite } from "../../../apicalls/compose.js";

// Component Imports
import AI_Input from "../comp/Input";
import { AI_Single_Select } from "../comp/Select";
import AI_Textbox from "../comp/Textbox";
import AI_Tone from "../comp/Tone";
import AI_PropertyDetails from "../comp/PropertyDetails";
import FinishedCompose from '../comp/FinishedCompose';

// Util Imports
import { useError } from '../../ui/Context/ErrorContext.jsx';
import { useUpgrade } from '../../ui/Context/UpgradeContext';

// Constants
const allPropertyTypes = ["Townhouse","Detached Home","Condominium","Apartment","Duplex","Multiplex","Bungalow","Luxury Property"];


// Functional Component
function Create_OpenHouseInv(props) {
    const { showError } = useError();
    const { showUpgrade } = useUpgrade();

    // Data states
    const [propertyType, setPropertyType] = useState(null);
    const [addressInfo, setAddressInfo] = useState("");
    const [propertyDetails, setPropertyDetails] = useState([
        {title: "Bedrooms", value: null},
        {title: "Bathrooms", value: null},
        {title: "Levels", value: null},
        {title: "Square Footage", value: null},
        {title: "Lot Size (ft²)", value: null},
        {title: "Garages", value: null},
        {title: "Year Built", value: null},
    ]);
    const [timeInfo, setTimeInfo] = useState("");
    const [targetInfo, setTargetInfo] = useState("");
    const [rsvpInfo, setRsvpInfo] = useState("");
    const [propertyHighs, setPropertyHighs] = useState("");
    const [directionInfo, setDirectionInfo] = useState("");
    const [cateringInfo, setCateringInfo] = useState("");
    const [offersInfo, setOffersInfo] = useState("");
    const [addInfo, setAddInfo] = useState("");
    const [tone, setTone] = useState(null);
  
    // Helper functions
    function setPropertyDetailAtIndex(index, newVal) {
        const newTemp = [...propertyDetails];
        newTemp[index].value = newVal;
        setPropertyDetails(newTemp);
    }

    async function generateCompose() {
        const resp = await api_createOpenHouseInvite({
            pt: propertyType,
            pa: addressInfo,
            bed: propertyDetails[0].value,
            bath: propertyDetails[1].value,
            lev: propertyDetails[2].value,
            sf: propertyDetails[3].value,
            ls: propertyDetails[4].value,
            gar: propertyDetails[5].value,
            by: propertyDetails[6].value,
            time: timeInfo,
            ta: targetInfo,
            rsvp: rsvpInfo,
            ph: propertyHighs,
            dir: directionInfo,
            ref: cateringInfo,
            off: offersInfo,
            ai: addInfo
        });
        switch (resp.status) {
            case 200:
                const msg = await resp.json();
                return msg.c;
            case 401:
                showUpgrade('Compose');
                return "Compose is a Team feature. Please upgrade your plan to continue.";
            default:
                showError('An unexpected error occurred.');
                return "Sorry, an unexpected error occurred.";
        }
    }

    // Return layout
    return (
        <>
            {(props.stage === 0) &&
                <>
                    <AI_Single_Select val={propertyType} setVal={setPropertyType} allValues={allPropertyTypes} title="Property Type" includeOther={true} otherTitle="Please specify Property Type" otherPlaceholder="e.g. Semi-Detached" />
                    <AI_Input val={addressInfo} setVal={setAddressInfo} title="Property Address" placeholder="e.g. 123 Main Street" />
                    <AI_PropertyDetails vals={propertyDetails} setValAtIndex={setPropertyDetailAtIndex} />
                    <AI_Input val={timeInfo} setVal={setTimeInfo} title="Date & Time of Open House" placeholder="e.g. 2-5 PM on Saturday, May 17" />
                    <AI_Textbox val={targetInfo} setVal={setTargetInfo} title="Target Audience" description="Describe the ideal buyers or tenants of this property." placeholder="e.g. First-time homebuyers looking for affordability, families seeking a quiet neighborhood, or investors seeking rental income." />
                    <AI_Input val={rsvpInfo} setVal={setRsvpInfo} title="RSVP Details" description="Please provide contact information, such as a phone number or email address, where guests can RSVP, as well as a deadline and any additional instructions, if applicable." placeholder="e.g. Please RSVP to john@mail.com by Saturday, May 3." />
                    <AI_Textbox val={propertyHighs} setVal={setPropertyHighs} title="Property Highlights" description="Describe any unique and/or key features that will be showcased during the open house." placeholder="e.g. Picturesque waterfront view, a spacious open-concept layout, a newly renovated kitchen, and a large backyard perfect for entertaining." />
                    <AI_Textbox val={directionInfo} setVal={setDirectionInfo} title="Special Directions" description="If relevant, provide clear directions to the property, such as parking information, landmarks, points of reference, etc." placeholder="e.g. Convenient parking available on Elm Street, adjacent to Maplewood Park." />
                    <AI_Textbox val={cateringInfo} setVal={setCateringInfo} title="Refreshments or Catering" description="If relevant, provide details about refreshments or catering. Include dietary options if applicable." placeholder="e.g. Beverages available for guests." />
                    <AI_Textbox val={offersInfo} setVal={setOffersInfo} title="Special Incentives" description="Any discounts, promotions, or incentives you'd like to highlight in the invitation." placeholder="e.g. Artisan Coffee Co. is offering an exclusive discount voucher for attendees redeemable at their shop." />
                    <AI_Textbox val={addInfo} setVal={setAddInfo} title="Additional Information" description="Any other relevant information you’d like to include in the neighbourhood description." />
                    {/* <AI_Tone val={tone} setVal={setTone} last={true} /> */}
                </>
            }
            {(props.stage === 1) && <FinishedCompose {...{generate: generateCompose}} /> }
        </>
    );
}

export default Create_OpenHouseInv;