// Dependencies
import { useState } from 'react';

// API Imports
import { api_createNeighbourDesc } from "../../../apicalls/compose.js";

// Component Imports
import AI_Input from "../comp/Input";
import AI_Textbox from "../comp/Textbox";
import AI_Tone from "../comp/Tone";
import FinishedCompose from '../comp/FinishedCompose';

// Util Imports
import { useError } from '../../ui/Context/ErrorContext';
import { useUpgrade } from '../../ui/Context/UpgradeContext';


// Functional Component
function Create_NeighbourDesc(props) {
    const { showError } = useError();
    const { showUpgrade } = useUpgrade();
    
    // Data states
    const [location, setLocation] = useState("");
    const [amenity, setAmenity] = useState("");
    const [safety, setSafety] = useState("");
    const [events, setEvents] = useState("");
    const [schools, setSchools] = useState("");
    const [services, setServices] = useState("");
    const [transport, setTransport] = useState("");
    const [history, setHistory] = useState("");
    const [future, setFuture] = useState("");
    const [addInfo, setAddInfo] = useState("");
    const [tone, setTone] = useState(null);

    // Helper functions
    async function generateCompose() {
        const resp = await api_createNeighbourDesc({
            ln: location,
            na: amenity,
            ss: safety,
            ce: events,
            ls: schools,
            ps: services,
            to: transport,
            hc: history,
            fd: future,
            ai: addInfo,
        });
        switch (resp.status) {
            case 200:
                const msg = await resp.json();
                return msg.c;
            case 401:
                showUpgrade('Compose');
                return "Compose is a Team feature. Please upgrade your plan to continue.";
            default:
                showError('An unexpected error occurred.');
                return "Sorry, an unexpected error occurred.";
        }
    }

    // Return layout
    return (
        <>
            {(props.stage === 0) &&
                <>
                    <AI_Input val={location} setVal={setLocation} title="Neighbourhood Location" placeholder="e.g. Maplewood Heights" />
                    <AI_Textbox val={amenity} setVal={setAmenity} title="Nearby Amenities" placeholder="e.g. Proximity to Maplewood Elementary School, Woodland Park with playgrounds, Oakview Mall for shopping, and the Metro Transit station for easy commuting." description="Describe nearby schools, parks, shopping centres, transit options, etc." />
                    <AI_Textbox val={safety} setVal={setSafety} title="Safety Statistics" placeholder="e.g. Low crime rate, with an average of only two incidents per year, and a well-established neighborhood watch program for added security." description="Provide relevant safety statistics, such as crime rates, incidents per year, etc." />
                    <AI_Textbox val={events} setVal={setEvents} title="Community Events" placeholder="e.g. Frequent community events such as the annual Oakfest carnival, seasonal farmers' markets, and monthly neighborhood clean-up initiatives." description="Describe community events that residents can look forward to." />
                    <AI_Textbox val={schools} setVal={setSchools} title="Local Schools" placeholder="e.g. Nearby educational institutions include Willowbrook Elementary School (K-5), Riverdale Middle School (6-8), and Pinecrest High School (9-12). All are highly rated." description="List nearby schools, their grade levels, and any notable distinctions they have received." />
                    <AI_Textbox val={services} setVal={setServices} title="Public Services" placeholder="e.g. Access to essential services like the Maplewood Public Library, Oakview Medical Center, and the Pinecrest Fire Station, all within a 10-minute drive." description="Describe essential public services & facilities in the area, such as libraries, hospitals, etc." />
                    <AI_Textbox val={transport} setVal={setTransport} title="Transportation Options" placeholder="e.g. Convenient transportation choices, including the Oakview Metro station for commuters, a well-connected bus network, and easy access to the highway." description="Detail the available transportation options, including public transit, major roadways, etc." />
                    <AI_Textbox val={history} setVal={setHistory} title="Historical Context" placeholder="e.g. Rich historical background dating back to the late 19th century, with well-preserved landmarks like the Maplewood Heritage House and the Old Mill Bridge." description="Include details on facts or landmarks relevant to the neighborhood's heritage." />
                    <AI_Textbox val={future} setVal={setFuture} title="Future Development Plans" placeholder="e.g. Ongoing neighborhood development initiatives include expanding green spaces, creating a new community center, and enhancing public infrastructure for a more sustainable future." description="List any ongoing or planned development projects, infrastructure improvements, etc." />
                    <AI_Textbox val={addInfo} setVal={setAddInfo} title="Additional Information" description="Any other relevant information you’d like to include in the neighbourhood description." />
                    <AI_Tone val={tone} setVal={setTone} last={true} />
                </>
            }
            {(props.stage === 1) && <FinishedCompose {...{generate: generateCompose}} /> }
        </>
    );
}

export default Create_NeighbourDesc;