// Dependencies
import { useEffect, useState } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';

// Util Imports
import setTitleText from "../../../utils/setTitleText.js";

// SVG Imports
import su_logo from "../../../img/svg/signup/su_logo.svg";

// PNG Imports
import signup_bg from "../../../img/png/signup/signup_bg_13.png";
import pay_bg from "../../../img/png/signup/pay_modal_bg.png";
import x_icon from "../../../img/png/signup/x_circle_orange.png";
import check_icon from "../../../img/png/signup/check_circle_orange.png";


// Functional Component
function PaymentRedirect() {
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();
	const [paymentSuccess, setPaymentSuccess] = useState(-1);

	// Get status
	useEffect(()=>{
		const successParams = searchParams.get('success');
		if (successParams === "true") {
			setPaymentSuccess(1);
			setTitleText("Welcome to ListingOpen!");
		} else {
			setPaymentSuccess(0);
			setTitleText("Oops!");
		}
	}, []);

	// Navigate to correct page on continue
	function handleContinue() {
		if (paymentSuccess) navigate('/welcome/onboard',{replace:true});
		else navigate('/welcome/plan',{replace:true});
	}

	// Return layout
	return (
		<>
			<div className="su-bg">
				<img src={signup_bg} alt="" />
			</div>
			{(paymentSuccess !== -1) &&
				<>
					<div className="su-cont">
						<img src={su_logo} alt="" />
						<img className="su-pay-bg" src={pay_bg} alt="" />
						<div className="top bl pay">
							<h1>{(paymentSuccess) ? "Your payment was successful!" : "We were unable to process your payment."}</h1>
						</div>
						<div className="in pay">
							<div className="di mb0">
								<h3>{(paymentSuccess) ? "Thank you for choosing ListingOpen! We're thrilled to have you on board. Press 'Continue' to see how we can help make your experience even more seamless and successful." : "Sorry, we were unable to process your payment. Please press 'Try Again' to retry your purchase. If you're having any trouble, don't hesitate to contact us!"}</h3>
							</div>
						</div>
						<img className="su-pay-ic" src={(paymentSuccess) ? check_icon : x_icon} alt="" />
						<div className="su-bd flex">
							<button onClick={handleContinue}>{(paymentSuccess) ? "Continue" : "Try Again"}</button>
						</div>
					</div>
				</>
			}
		</>
	);
}

export default PaymentRedirect;



