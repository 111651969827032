// Dependencies
import { useState, useEffect } from 'react';

// API Imports
import { logoutUser } from "../../apicalls/auth.js";
import { upgradePlan } from "../../apicalls/billing.js";

// Util Imports
import { useError } from "../ui/Context/ErrorContext";

// SVG Imports
import su_logo from "../../img/svg/signup/su_logo.svg";
import check_icon from "../../img/svg/signup/check_icon.svg";

// PNG Imports
import signup_bg from "../../img/png/signup/signup_bg_8.png";
import x_icon from "../../img/svg/signin/x_icon.svg";

// Functional Component 
function UpgradeModal({featureName, closeModal}) {
    const { showError } = useError();

    // Page states
    const [isLoading, setIsLoading] = useState(false);
    const [billPeriod, setBillPeriod] = useState(-1);

    // Helper Functions
    async function handleUpgrade() {
        if (billPeriod < 0 || isLoading) return;
        setIsLoading(true);
        const resp = await upgradePlan("premium", billPeriod);
		if (resp.status === 200 && await logoutUser()) return window.location.reload(false);
        else showError('An unexpected error occurred.');
		setIsLoading(false);
    }

    function handleClose() {
        if (window.history.length > 1) window.history.back();
        else window.open('/listings', '_self');
        closeModal();
    }

	// Return layout
	return (
		<div className="upg-pln-mod-bg">
            <div className="upg-pln-mod-cont-bg">
                <img src={signup_bg} alt="" />
                <div className="upg-pln-mod-cont">
                    <div className="flex">
                        <img src={su_logo} alt="" />
                        <button className="close" onClick={handleClose}><img src={x_icon} alt="Close" /></button>
                    </div>
                    <h1>Upgrade your Plan</h1>
                    <h3>{featureName || "This"} {(featureName || "x").slice(-1) === "s" ? "are" : "is"} a Team feature. Please upgrade your plan to continue.</h3>
                    <h2>What you get...</h2>
                    <div className="ft">
                        <div className="flex mt"><img src={check_icon} alt="" /><h3>5 Team Seats</h3></div>
                        <div className="flex"><img src={check_icon} alt="" /><h3>Organization Account</h3></div>
                        <div className="flex"><img src={check_icon} alt="" /><h3>Migration Support</h3></div>
                        <div className="flex"><img src={check_icon} alt="" /><h3>Unlimited Seats for $7/mo.</h3></div>
                    </div>
                    {(billPeriod !== -1) &&
                        <div className="pr flex">
                            <h3>Price</h3>
                            <div className="flex">
                                <h2>{(billPeriod === 0) ? "$115" : "$97"}</h2>
                                <h3>/ mo.</h3>
                            </div>
                        </div>
                    }
                    <h2>Billing</h2>
                    <div className="bil flex">
                        <button onClick={()=>{setBillPeriod(1)}} className={(billPeriod < 0) ? "" : ((billPeriod === 1) ? "sel" : "unsel")}><h3>Annual<span className="orange">{" (Save 20%)"}</span></h3></button>
                        <button onClick={()=>{setBillPeriod(0)}} className={(billPeriod < 0) ? "" : ((billPeriod === 0) ? "sel" : "unsel")}><h3>Monthly</h3></button>
                    </div>
                    <button className={"upg" + ((billPeriod < 0 || isLoading) ? " hidden" : "")} disabled={(billPeriod < 0 || isLoading)} onClick={handleUpgrade}>Upgrade</button>
                </div>
            </div>
		</div>
	);
}

export default UpgradeModal;