// Dependencies
import { useState, useContext, createContext } from "react";
import UpgradeModal from "../../modals/UpgradeModal";

// Image Imports

// Error Components
const UpgradeContext = createContext();

export const useUpgrade = () => useContext(UpgradeContext);

export function UpgradeProvider ({ children }) {
	const [featureName, setFeatureName] = useState('');
    const [modalVisible, setModalVisible] = useState(false);

    const showUpgrade = (feature_name) => {
    	setFeatureName(feature_name);
        setModalVisible(true);
    };

	const clearUpgrade = () => {
		setModalVisible(false);
	}

	return (
		<UpgradeContext.Provider value={{ featureName, modalVisible, showUpgrade, clearUpgrade }}>
			{children}
		</UpgradeContext.Provider>
	);
};

export function UpgradeDisplay() {
	const { featureName, modalVisible, clearUpgrade } = useUpgrade();

	function closeModal() {
        clearUpgrade();
	}

	return (
        <>
            {(modalVisible) && <UpgradeModal featureName={featureName} closeModal={closeModal} />}
        </>
	);
};
  