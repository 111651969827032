// Functional Component
function AI_PropertyDetails({
    vals, setValAtIndex,
    last=false
}) {
    // Return layout
    return (
        <div className={"ai-tool-input" + (last ? " mb0" : "")}>
            <h2>Property Details</h2>
            <div className="ai-tool-b-d">
                {
                    vals.map((v, index) => {
                        return (
                            <button
                                key={`ais-${index}`}
                                className={"pds" + ((v.value && v.value.length > 0) ? " sel" : "")}
                                value={index}
                            >
                                <h3>{v.title}</h3>
                                <input
                                    id={`aipds-in-${index}`}
                                    placeholder="?"
                                    className="pds-in"
                                    style={{ width: `${(Math.max((v.value || "").length, 2)) + 2}ch` }} /* Min width of 4 chars */
                                    value={v.value || ""}
                                    type="number"
                                    onChange={(e) => {
                                        setValAtIndex(index, e.target.value.slice(0, 7))} /* Max length = 7 chars */
                                    }
                                />            
                            </button>
                        );
                    })
                }
            </div>
        </div>
    );
}

export default AI_PropertyDetails;