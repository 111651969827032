// Dependencies
import { useState, useEffect } from "react";

// API Imports
import { getIntegrationsWithCode } from "../../../../apicalls/integrations.js";

// Util Imports
import { getIntegrationButtonBody } from "../../../../utils/sharedIntegrationFuns.js";
import { useError } from '../../../ui/Context/ErrorContext';

// Functional Component
function Integrations(props) {
    const { showError } = useError();

    // Data states
    const [integ, setInteg] = useState(null);

    // Render rows of integrations
    // isInverse = true -> renders opposite of given integ.
    // For example, if integ_list = hubspot, isInverse = true will render all non-hubspot integrations
    function renderIntegrations(integ_list, isInverse=false) {
        const all_integ = ["hubspot", "pipedrive", "mailchimp"]; /* ["placester", "tb", "hubspot", "salesmate", "gsheets", "gmail", "mailchimp", "twilio", "pipedrive", "zapier"]; */
        var integs = integ_list.map(i => i.type);
        var codes = integ_list.map(i => i.id);

        // Get set difference of existing & all integrations
        if (isInverse) {
            const iset = new Set(integs);
            integs = all_integ.filter(i => !iset.has(i));
        }

        // Return layout
        if (integs.length === 0) return ( <></> );
        return (
            <>
                {(isInverse && integs.length !== all_integ.length) && <div className="content-spacer" />}
                <div className="lst-new-i-d mb0">
                    <h2>{(isInverse) ? "Available" : "Active"}</h2>
                    <div className="intg-card-cont">
                        {
                            integs.map((integ, index) => {
                                return (
                                    <button key={`integrate-${integ}`} value={codes.length >= index+1 ? codes[index] : 0} onClick={(e)=>{isInverse ? props.createIntegration(integ) : props.openIntegration(e.currentTarget.value)}} className="intg-card-b">
                                        <div className="iflex">{getIntegrationButtonBody(integ)}</div>
                                    </button>
                                );
                            })
                        }
                    </div>
                </div>
            </>    
        );
    }

    // Load leads
    useEffect(()=>{
        async function loadIntegrations() {
            const integration_resp = await getIntegrationsWithCode(props.listing_code);
            if (integration_resp.status !== 200) {
                showError('An unexpected error occurred.');
                setInteg([]);
            } else {
                const integration_data = await integration_resp.json();
                setInteg(integration_data.data);
            }
        }
        loadIntegrations();
    },[]);


    // Return layout
    return (
        <>
            {(integ) && 
                <>
                    {(integ.length > 0) && renderIntegrations(integ) }
                    { renderIntegrations(integ, true) }
                </>
            }
        </>
    );
}

export default Integrations;