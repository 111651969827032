// Image Imports
import servererror_img from "../../../img/png/errors/500.png";
import logo from "../../../img/svg/landing/lo_logo.svg";

// Util Imports
import setTitleText from "../../../utils/setTitleText";

// Style Imports
import "./error.css";

// Functional Component
function ServerError_500() {
	setTitleText("Oops!");

	// Return layout
	return (
		<div className="err-nf-404 cont">
			<div className="err-nf-404 top">
				<img src={logo} alt="ListingOpen" />
			</div>
			<div className="err-nf-404 mid">
				<img className="se-500" src={servererror_img} alt="Server Error" />
				<h1>Oops! We'll be back soon.</h1>
				<h2 className="se-500">The system is down at the moment. We're working to resolve the issue as soon as possible. We apologize for the inconvenience and appreciate your patience. Thank you for using ListingOpen!</h2>
				<div className="flex">
					<a href={window.location.href}>Try Again</a>
				</div>
			</div>
		</div>
	);
}

export default ServerError_500;