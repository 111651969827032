// Dependencies
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { InlineWidget } from "react-calendly";

// API Imports
import { logoutUser } from "../../../apicalls/auth.js";

// Util Imports
import setTitleText from "../../../utils/setTitleText.js";

// SVG Imports
import su_logo from "../../../img/svg/signup/su_logo.svg";

// PNG Imports
import signup_bg from "../../../img/png/signup/signup_bg_7.png";
import keys_bg from "../../../img/png/signup/keys_modal_bg.png";

// Functional Component
function Onboarding() {
	setTitleText("Welcome to ListingOpen!");

	const navigate = useNavigate();
	const [page, setPage] = useState(0);

	// Navigate to correct page on continue
	async function handleContinue() {
		if (page === 0) setPage(1);
		if (page === 1) {
			await logoutUser();
			navigate('/login',{replace:true});
		}
	}

	// Go back to schedule onboarding call
	function goBack() { setPage(0); }

	// Return layout
	return (
		<>
			<div className="su-bg">
				<img src={signup_bg} alt="" />
			</div>
			<div className="su-cont">
				<img src={su_logo} alt="" />
				{(page === 1) && <img className="su-pay-bg" src={keys_bg} alt="" />}
				<div className="top bl">
					<h1>{(page === 0) ? "Want to schedule an onboarding call?" : "Welcome to ListingOpen, NAME_HERE!"}</h1>
				</div>
				<div className="in book">
					<div className="di mb0 book">
						<h3>{(page === 0) ? "Book a 15-minute onboarding call, where our team will walk you through how to get started with ListingOpen, explain best practices, offer options for integrations & migration, and answer any and all questions." : "You're all set up! Thank you again for choosing ListingOpen. We really are thrilled to have you on board and can't wait to help you succeed."}</h3>
						{(page === 0) &&
							<InlineWidget
								url="https://calendly.com/listingopen/onboarding"
								styles={{
									height: 'calc(100% - 115px)',
									outline: '1px solid var(--grey-3)',
									borderRadius: '5px',
									backgroundColor: 'var(--grey-2)'
								}}
								pageSettings={{
									hideEventTypeDetails: true,
									hideLandingPageDetails: true,
									primaryColor: 'FF5C3C',
									textColor: '000000',
									hideGdprBanner: true
								}}
							/>
						}
					</div>
				</div>
				<div className="su-bd flex">
					{(page === 1) && <button className="su" onClick={goBack}>&lt;- Back</button>}
					<button onClick={handleContinue}>{(page === 0) ? "Continue" : "Open Dashboard"}</button>
				</div>
			</div>
		</>
	);
}

export default Onboarding;



