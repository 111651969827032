// Dependencies
import { useState, useEffect } from "react";

// API Imports
import { getCredentialsForType } from "../../../apicalls/integrations.js";

// Util Imports
import { useError } from "../../ui/Context/ErrorContext";
import { getIntegrationLogo, getIntegrationTitleDiv, getIntegrationFields, getIntegrationButtonBody } from "../../../utils/sharedIntegrationFuns.js";


// Functional Component - create is just update null entry
function UpdateIntegration(props) {
	const { showError } = useError();

    // Data states
    const [t1, setT1] = useState(props.t1 || null);
    const [t2, setT2] = useState(props.t2 || null);
    
    // Load credentials
    async function loadCreds() {
        const resp = await getCredentialsForType(props.type);
        if (resp.status !== 200) {
            showError('An unexpected error occurred.');
        } else {
            const data = await resp.json();
            setT1(data.target_1);
            setT2(data.target_2 || null);
        }
    }
    useEffect(()=>{ if (!props.t1) loadCreds(); },[]);

    // Save updates
    function saveUpdates() {
        // Find entry + update, or push new
        var temp_intg = props.integ;
        const new_entry = {type: props.type, target_1: t1, target_2: t2};
        const i = temp_intg.findIndex(e => e.type === props.type);
        if (i === -1) temp_intg.push(new_entry);
        else temp_intg[i] = new_entry;
        props.setIntegrations(temp_intg);

        // Go back
        props.goBack();
    }

    function handleDelete() {
        // Find & remove entry
        props.setIntegrations(props.integ.filter(e => e.type !== props.type));

        // Go back
        props.goBack();
    }

    // Return layout 
    return (
        <>
            <div className="flex intg-mng-t-d mt0">
                <img className="intg-mng-img" src={getIntegrationLogo(props.type)} alt="" />
                <div className="intg-mng-t-id mauto">
                    { getIntegrationTitleDiv(props.type) }
                </div>
            </div>
            <div className="content-spacer" />
            { getIntegrationFields(props.type, t1, setT1, t2, setT2) }
            { (props.integ && props.integ.findIndex(e => e.type === props.type) !== -1) &&
                <div className="lst-new-i-d mt30 mb0">
                    <h2>Settings</h2>
                    <div className="lead-prof-b-d iflex">
                        <div>
                            <h2>{(props.pkg_name && props.pkg_name.length > 0) ? `Remove from "${props.pkg_name}"` : "Remove Integration"}</h2>
                            <h3>This action cannot be undone.</h3>
                        </div>
                        <button onClick={handleDelete}>Remove</button>
                    </div>
                </div>
            }
            <div className="dash-cont-b-d iflex">
                <button className="back" onClick={props.goBack}>&lt;- Back</button>
                <button
                    className={"finish"+((!t1 || t1.length===0) ? " hidden" : "")}
                    disabled={!t1 || t1.length===0}
                    onClick={saveUpdates}
                >Save Changes</button>
            </div>
        </>
    );
}


// Functional Component
function Details(props) {
    const [selectedType, setSelectedType] = useState("");
    const [selectedTargets, setSelectedTargets] = useState([null, null]);
    const [page, setPage] = useState(0);

    // Render rows of integrations
    // isInverse = true -> renders opposite of given integ.
    // For example, if integ_list = hubspot, isInverse = true will render all non-hubspot integrations
    function renderIntegrations(integ_list, isInverse=false) {
        const all_integ = ["hubspot", "pipedrive", "mailchimp"]; /* ["placester", "tb", "hubspot", "salesmate", "gsheets", "gmail", "mailchimp", "twilio", "pipedrive", "zapier"]; */
        var integs = integ_list.map(i => i.type);

        // Get set difference of existing & all integrations
        if (isInverse) {
            const iset = new Set(integs);
            integs = all_integ.filter(i => !iset.has(i));
        }

        // Return layout
        if (integs.length === 0) return ( <></> );
        return (
            <>
                {(isInverse && integs.length !== all_integ.length) && <div className="content-spacer" />}
                <div className="lst-new-i-d mb0">
                    <h2>{(isInverse) ? "Available" : "Active"}</h2>
                    <div className="intg-card-cont">
                        {
                            integs.map((integ) => {
                                return (
                                    <button key={`integrate-${integ}`} value={integ} onClick={(e)=>{updateIntegration(e.currentTarget.value)}} className="intg-card-b">
                                        <div className="iflex">{getIntegrationButtonBody(integ)}</div>
                                    </button>
                                );
                            })
                        }    
                    </div>
                </div>
            </>    
        );
    }

    // Open updates
    function updateIntegration(type) {
        setSelectedType(type);
        // Find targets
        const i = props.integrations.findIndex(e => e.type === type);
        if (i === -1) setSelectedTargets([null, null]);
        else setSelectedTargets([props.integrations[i].target_1 || null, props.integrations[i].target_2 || null])
        setPage(1);
    }

    // Return layout
    return (
        <>
            {(props.integrations && page === 0) && 
                <>
                    {(props.integrations.length > 0) && renderIntegrations(props.integrations, false) }
                    { renderIntegrations(props.integrations, true) }
                    {(props.isManage) ?
                        <div className="dash-cont-b-d iflex">
                            <button
                                className={"next"+((props.isLoading || (!props.isListTemplate && (!props.integrations || props.integrations.length === 0))) ? " hidden" : "")}
                                disabled={props.isLoading || (!props.isListTemplate && (!props.integrations || props.integrations.length === 0))}
                                onClick={props.saveChanges}
                            >Save Changes</button>
                        </div>
                        :
                        <div className="dash-cont-b-d iflex">
                            <button className="back" onClick={props.goBack}>&lt;- Back</button>
                            <button
                                className={"next"+((!props.isListTemplate && (!props.integrations || props.integrations.length === 0)) ? " hidden" : "")}
                                disabled={(!props.isListTemplate && (!props.integrations || props.integrations.length === 0))}
                                onClick={props.goNext}
                            >Next -&gt;</button>
                        </div>
                    }
                </>
            }
            {(page === 1) &&
                <UpdateIntegration {...{pkg_name: props.pkg_name, type: selectedType, t1: selectedTargets[0], t2: selectedTargets[1], integ: props.integrations, setIntegrations: props.setIntegrations, goBack: ()=>{setPage(0)} }} />
            }
        </>
    );
}

export default Details;