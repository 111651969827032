// Dependencies
import { useState, useEffect } from "react";

// API Imports
import { updateCategoryWithId, deleteCategoryWithId } from "../../apicalls/listings";

// Util Imports
import { useError } from '../ui/Context/ErrorContext';

// SVG Imports
import x_icon from "../../img/svg/signin/x_icon.svg";


// Functional Component
function EditCategoryModal(props) {
    const { showError } = useError();
	
	// Data states
	const [name, setName] = useState(props.name);

	// Handle creating category
	async function handleSubmit() {
		if (name.length === 0) return;
		const resp = await updateCategoryWithId(props.id, name);
		if (resp.status !== 200) {
            showError('An unexpected error occurred.');
		} else {
			props.triggerRefresh();
			props.closeModal();
		}
	}

	// Handle deleting category
	async function handleDelete() {
		const resp = await deleteCategoryWithId(props.id);
		if (resp.status !== 200) {
            showError('An unexpected error occurred.');
		} else {
			props.triggerRefresh();
			props.closeModal();
		}
	}

	// Return layout
	return (
		<div className="set-cat-mod-bg">
			<div className="set-cat-mod-cont">
				<div className="set-cat-mod-top iflex">
					<h2>Edit Category</h2>
					<button onClick={props.closeModal}><img src={x_icon} alt="" /></button>
				</div>
				<div className="set-cat-mod-spacer" />
				<div className="set-cat-mod-btm">
					<h3 className="set-cat-mod-sbt">Category Name</h3>
					<input
						className="set-cat-mod-in"
						placeholder="e.g. My Listing Category"
						value={name || ""}
						onChange={(e)=>{setName(e.target.value || "")}} />
					<div className="flex">
						<button className="set-cat-mod-sm-b del" onClick={handleDelete}>Delete</button>
						<button
							className={"set-cat-mod-sm-b sv"+ ((name.length === 0 || props.name === name) ? " hidden" : "")}
							disabled={name.length === 0 || props.name === name}
							onClick={handleSubmit}
						>Save & Publish</button>
					</div>
				</div>
			</div>
		</div>
	);
}

export default EditCategoryModal;