// Dependencies
import { useState } from 'react';

// API Imports
import { api_explainLease } from "../../../apicalls/compose.js";

// Component Imports
import { AI_Single_Select } from '../comp/Select';
import AI_Textbox from "../comp/Textbox";
import AI_Tone from "../comp/Tone";
import FinishedCompose from '../comp/FinishedCompose.jsx';

// Util Imports
import { useError } from '../../ui/Context/ErrorContext';
import { useUpgrade } from '../../ui/Context/UpgradeContext';

// Constants
const allLeaseTypes = ["Fixed-Term", "Month-to-Month", "Sublease"];


// Functional Component
function Enhance_ListingDesc(props) {
    const { showError } = useError();
    const { showUpgrade } = useUpgrade();

    // Data states
    const [leaseType, setLeaseType] = useState(null);
    const [clauses, setClauses] = useState("");
    const [terms, setTerms] = useState("");
    const [addInfo, setAddInfo] = useState("");
    const [tone, setTone] = useState(null);

    // Helper functions
    async function generateCompose() {
        const resp = await api_explainLease({
            sc: clauses,
            lt: leaseType,
            ri: terms,
            ai: addInfo,
        });
        switch (resp.status) {
            case 200:
                const msg = await resp.json();
                return msg.c;
            case 401:
                showUpgrade('Compose');
                return "Compose is a Team feature. Please upgrade your plan to continue.";
            default:
                showError('An unexpected error occurred.');
                return "Sorry, an unexpected error occurred.";
        }
    }

    // Return layout
    return (
        <>
            {(props.stage === 0) &&
                <>
                    <AI_Single_Select val={leaseType} setVal={setLeaseType} allValues={allLeaseTypes} title="Lease Type" includeOther={true} otherTitle="Please specify Lease Type" otherPlaceholder="e.g. Ground Lease" />
                    <AI_Textbox val={clauses} setVal={setClauses} title="Target Clauses" placeholder="e.g. Rent Increase" description="Which lease clauses should be simplified / explained?" />
                    <AI_Textbox val={terms} setVal={setTerms} title="Relevant Lease Terms" description="Include any information about specific lease terms relevant to an explanation." />
                    <AI_Textbox val={addInfo} setVal={setAddInfo} title="Additional Information" description="Any other relevant information you’d like to include in the explanation." />
                    <AI_Tone val={tone} setVal={setTone} last={true} />
                </>
            }
            {(props.stage === 1) && <FinishedCompose {...{generate: generateCompose}} /> }
        </>
    );
}

export default Enhance_ListingDesc;