// Dependencies
import { useState, useEffect } from "react";

// API Imports
import { createCategoryWithName } from "../../apicalls/listings";

// Util Imports
import { useError } from '../ui/Context/ErrorContext';

// SVG Imports
import x_icon from "../../img/svg/signin/x_icon.svg";


// Functional Component
function NewCategoryModal(props) {
	const { showError } = useError();

	// Data states
	const [name, setName] = useState("");

    // Handle creating category
    async function handleSubmit() {
        if (name.length === 0) return;
		const resp = await createCategoryWithName(name);
		if (resp.status !== 200) {
			showError('An unexpected error occurred.');
		} else {
			props.triggerRefresh();
			props.closeModal();
		}
    }

	// Return layout
	return (
		<div className="set-cat-mod-bg">
			<div className="set-cat-mod-cont">
				<div className="set-cat-mod-top iflex">
					<h2>Create Category</h2>
					<button onClick={props.closeModal}><img src={x_icon} alt="" /></button>
				</div>
				<div className="set-cat-mod-spacer" />
				<div className="set-cat-mod-btm">
					<h3 className="set-cat-mod-sbt">Category Name</h3>
					<input
						className="set-cat-mod-in"
						placeholder="e.g. My Listing Category"
						value={name || ""}
						onChange={(e)=>{setName(e.target.value || "")}} />
					<button
						className={"set-cat-mod-lrg-b"+ ((name.length === 0) ? " hidden" : "")}
						disabled={name.length === 0}
						onClick={handleSubmit}
					>Save & Publish</button>
				</div>
			</div>
		</div>
	);
}

export default NewCategoryModal;