// SVG Imports
import yn_icon from "../img/svg/signin/yn_icon.svg";
import sc_icon from "../img/svg/signin/sc_icon.svg";
import mc_icon from "../img/svg/signin/mc_icon.svg";
import oe_icon from "../img/svg/signin/oe_icon.svg";

// Qlist format: {order: int, type: int, text: str}
export function renderQuestionList(qlist) {
	var r = [];

	// Add contact details
	// r.push(<div className="iflex" key={`q-cntct`}><div className="iflex"><h2>Contact Details</h2></div></div>);

    // Layout rows of questions (-1 means removed locally)
	qlist.filter(a => a.order !== -1).toSorted((a, b) => a.order - b.order).forEach((q, index) => {
		r.push(
			<div className="iflex" key={`qk-${index}`}>
				<div className="iflex"><h2>{q.text}</h2></div>
				<div className="q-type iflex">
					{(q.type === 0) && <img src={yn_icon} alt="" />}
					{(q.type === 1) && <img src={sc_icon} alt="" />}
					{(q.type === 2) && <img src={mc_icon} alt="" />}
					{(q.type === 3) && <img src={oe_icon} alt="" />}
					{(q.type === 0) && <h3>Yes / No</h3>}
					{(q.type === 1) && <h3>Single Select</h3>}
					{(q.type === 2) && <h3>Multi Select</h3>}
					{(q.type === 3) && <h3>Open Ended</h3>}
				</div>
			</div>
		);
	});

    // Return layout
    return( <div className="lst-mng-si-q-cont">{r}</div> );
}