// Dependencies
import { useState } from 'react';
import Select from 'react-select';

// API Imports
import { createUser, loginUser } from "../../../apicalls/auth.js";

// Util Imports
import setTitleText from "../../../utils/setTitleText.js";

// SVG Imports
import su_logo from "../../../img/svg/signup/su_logo.svg";
import check_icon from "../../../img/svg/signup/checkmark.svg";

// PNG Imports
import signup_bg from "../../../img/png/signup/signup_bg_6.png";

// Constants 
const selectStyle = {
	control: (styles, { isFocused }) => ({
		...styles,
		borderColor: isFocused ? 'var(--orange-accent)' : styles.borderColor,
		boxShadow: (isFocused) ? '0 0 0 1px var(--orange-accent)' : styles.boxShadow,
		'&:hover': {
			borderColor: 'var(--orange-accent)'
		}
	}),
	option: (provided, state) => ({
		...provided,
		fontFamily: 'Inter',
		fontSize: '15px',
		backgroundColor: state.isFocused ? 'var(--orange-accent-lite)' : null,
		color: 'black',
		':active': {
			backgroundColor: 'var(--orange-accent)'
		},
	}),
	noOptionsMessage: (styles) => ({
		...styles,
		fontSize: '15px',
		fontFamily: 'Inter',
		color: 'var(--grey-4)'
	}),
	singleValue: (styles) => ({
		...styles,
		fontSize: '15px',
		fontFamily: 'Inter',
		color: 'black'
	})
};

const countries = [
    {value: 'CAN', label: '🇨🇦 Canada'},
    {value: 'USA', label: '🇺🇸 United States'}
];


// Functional Component
function SignUp() {
    setTitleText("Sign Up");

    // Page states
    const [showPass, toggleShowPass] = useState(false);

    // Data states
    const [email, setEmail] = useState("");
    const [name, setName] = useState("");
    const [surname, setSurname] = useState("");
    const [selectedCountry, setSelectedCountry] = useState(null);
    const [password, setPassword] = useState("");
    const [acceptEmails, toggleAcceptEmails] = useState(false);
    const [acceptTerms, toggleAcceptTerms] = useState(false);

    // Handle creation of user
    async function handleCreateUser() {
        const didCreate = await createUser(name, surname, email, password);
        if (!didCreate) {
            // TODO: Handle error
            return;
        }

        // Log in
        const login = await loginUser(email, password);
        if (!login) {
            // TODO: Handle error
            return;
        }

        // Reload
        window.location.reload(false);
    }

    // Return layout
    return(
        <>
            <div className="su-bg">
                <img src={signup_bg} alt="" />
            </div>
            <div className="su-cont">
                <img src={su_logo} alt="" />
                <div className="top bl">
                    <h1>Sign Up</h1>
                </div>
                <div className="content-spacer" />
                <div className="in">
                    <div className="di">
                        <h2>Country</h2>
                        <Select
                            className="su-select"
							options={countries}
							styles={selectStyle}
                            value={countries.find((c) => c.value === selectedCountry) || null}
							placeholder="Select your country"
							onChange={(e)=>{if (e) { setSelectedCountry(e.value) }}}
						/>
                    </div>
                    <div className="di">
                        <h2>Name</h2>
                        <div className="split flex">
                            <input placeholder="Forename / First Name" type="text" value={name} onChange={(e)=>{setName(e.target.value)}} />
                            <input placeholder="Surname / Last Name" type="text" value={surname} onChange={(e)=>{setSurname(e.target.value)}} />
                        </div>
                    </div>
                    <div className="di">
                        <h2>Email Address</h2>
                        <input placeholder="e.g. jdoe@dreamrealty.com" type="email" value={email} onChange={(e)=>{setEmail(e.target.value)}} />
                    </div>
                    <div className="di mb0">
                        <div className="su-pws flex">
                            <h2>Password</h2>
                            <button onClick={(_)=>{toggleShowPass(!showPass)}}>{(showPass) ? "Hide" : "Show"}</button>
                        </div>
                        <input placeholder="•••••" type={(showPass) ? "text" : "password"} value={password} onChange={(e)=>{setPassword(e.target.value)}} />
                    </div>
                </div>
                <div className="accept">
                    <div className="flex">
                        <button className={(acceptEmails) ? "sel" : ""} onClick={(_) => {toggleAcceptEmails(!acceptEmails)}}>
                            {(acceptEmails) && <img src={check_icon} alt="" />}
                        </button>
                        <h3>Get emails from ListingOpen about product updates, industry news, and events. You can unsubscribe at any time.</h3>
                    </div>
                    <div className="flex">
                        <button className={(acceptTerms) ? "sel" : ""} onClick={(_) => {toggleAcceptTerms(!acceptTerms)}}>
                            {(acceptTerms) && <img src={check_icon} alt="" />}
                        </button>
                        <h3>I have read and agree to the ListingOpen Terms.</h3>
                    </div>
                </div>
                <button
                    className={((!email.length || !name.length || !surname.length || !password.length || !selectedCountry || !acceptTerms) ? "hidden" : "")}
                    disabled={(!email.length || !name.length || !surname.length || !password.length || !selectedCountry || !acceptTerms)}
                    onClick={handleCreateUser}>Create Account</button>
            </div>
        </>
    );
}

export default SignUp;