/*
------------------
> AUTH API CALLS <
------------------
*/

const prefix = `${(process.env.REACT_APP_ENV_MODE === "development") ? process.env.REACT_APP_API_URL_DEV : process.env.REACT_APP_API_URL_PROD}/billing`;


export async function upgradePlan(type, period) {
  var type_str = "";
  switch (type) {
    case "standard":
      type_str = ((period === 0) ? "sm" : "sa");
      break;
    case "premium":
      type_str = ((period === 0) ? "pm" : "pa");
      break;
    default:
      break;
  }
  return await fetch(prefix+'/upgrade', {
      method: 'POST', credentials: 'include',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({type: type_str})
    });
}

// Plan: "b" or "p"
// Term: "mo" or "an"
export async function choosePlan(plan, term) {  
    return await fetch(prefix+`/checkout/${plan}/${term}`, {
        method: 'GET', credentials: 'include'
    });
}

export async function getCurrPlan() {
  return await fetch(prefix+'/me', {
    method: 'GET', credentials: 'include'
  });
}

export async function openPortal() {
  return await fetch(prefix+'/portal', {
    method: 'GET', credentials: 'include'
  });
}