// Dependencies
import { useState } from 'react';

// API Imports
import { api_explainClosing } from "../../../apicalls/compose.js";

// Component Imports
import { AI_Single_Select } from '../comp/Select';
import AI_Textbox from "../comp/Textbox";
import AI_Tone from "../comp/Tone";
import FinishedCompose from '../comp/FinishedCompose.jsx';

// Util Imports
import { useError } from '../../ui/Context/ErrorContext';
import { useUpgrade } from '../../ui/Context/UpgradeContext';

// Constants
const allTransactionTypes = ["Purchase", "Sale", "Lease"];
const allClientRoles = ["Buyer","Seller","Landlord","Tenant"];
const allComplexityLevels = ["Very Basic","Basic","Moderate","Expert"];


// Functional Component
function Explain_Closing(props) {
    const { showError } = useError();
    const { showUpgrade } = useUpgrade();

    // Data states
    const [type, setType] = useState(null);
    const [role, setRole] = useState(null);
    const [curStage, setCurStage] = useState("");
    const [quest, setQuest] = useState("");
    const [legal, setLegal] = useState("");
    const [timeline, setTimeline] = useState("");
    const [complexity, setComplexity] = useState(null);
    const [addInfo, setAddInfo] = useState("");
    const [tone, setTone] = useState(null);


    // Helper functions
    async function generateCompose() {
        const resp = await api_explainClosing({
            t: type,
            cr: role,
            cs: curStage,
            sq: quest,
            rl: legal,
            tt: timeline,
            ai: addInfo,
        });
        switch (resp.status) {
            case 200:
                const msg = await resp.json();
                return msg.c;
            case 401:
                showUpgrade('Compose');
                return "Compose is a Team feature. Please upgrade your plan to continue.";
            default:
                showError('An unexpected error occurred.');
                return "Sorry, an unexpected error occurred.";
        }
    }

    // Return layout
    return (
        <>
            {(props.stage === 0) &&
                <>
                    <AI_Single_Select val={type} setVal={setType} allValues={allTransactionTypes} title="Type of Transaction" includeOther={true} otherTitle="Please specify the type of transaction." otherPlaceholder="e.g. Lease-Option" />
                    <AI_Single_Select val={role} setVal={setRole} allValues={allClientRoles} title="Client's Role" includeOther={true} otherTitle="Please specify the client's role in the transaction." otherPlaceholder="e.g. Lender" />
                    <AI_Textbox val={curStage} setVal={setCurStage} title="Current Stage" placeholder="e.g. Awaiting the inspection report to identify any necessary repairs before proceeding with negotiations." description="Specify the current closing stage and provide relevant details." />
                    <AI_Textbox val={quest} setVal={setQuest} title="Specific Questions or Uncertainties" placeholder="e.g. What potential issues may arise from the inspection, and is the seller is willing to address them?" description="Share client questions or uncertainties regarding the closing." />
                    <AI_Textbox val={legal} setVal={setLegal} title="Relevant Legal or Contractual Details" placeholder="e.g. Per the terms in the purchase agreement, the buyer has the right to request repairs or credits based on the inspection findings during the inspection contingency." description="Include any relevant legal or contract-related information." />
                    <AI_Textbox val={timeline} setVal={setTimeline} title="Transaction Timeline" placeholder="e.g. We have a 10 day window to conduct inspections and negotiate any necessary repairs. Contingency is set to expire on September 25th." description="Outline the expected timeline for this closing stage." />
                    <AI_Single_Select val={complexity} setVal={setComplexity} allValues={allComplexityLevels} title="Complexity Level" description="How basic or complex should the explanation be?" />
                    <AI_Textbox val={addInfo} setVal={setAddInfo} title="Additional Information" description="Any other relevant information you’d like to include in the explanation." />
                    <AI_Tone val={tone} setVal={setTone} last={true} />
                </>
            }
            {(props.stage === 1) && <FinishedCompose {...{generate: generateCompose}} /> }
        </>
    );
}

export default Explain_Closing;