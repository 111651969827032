// Dependencies
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

// API Imports
import { getListingWithCode } from "../../apicalls/listings.js";
import { getQuestionsWithCode, submitAnswersWithCode } from "../../apicalls/visit.js";

// Util Imports
import getPhotoUrlFromId from "../../utils/getPhotoUrlFromId.js";
import setTitleText from "../../utils/setTitleText.js";

// Component Imports
import Manage from "./comp/Manage.jsx";
import CrossfadeImage from "./comp/Crossfader.jsx";
import SignIcon from "../../img/svg/signin/sign.jsx";

// SVG Imports
import metallic_logo from "../../img/svg/signin/metallic_logo.svg";

// PNG Imports
import thanksOverlay from "../../img/png/signin/thanks_overlay.png";


// Helper Component for Questions (on right side)
function Questions(props) {
    // Data states
    const [question, setQuestion] = useState(0);

    const [contactDetails, setContactDetails] = useState(['','','']);
    const [currAnswer, setCurrAnswer] = useState(null);
    const [currMcAnswer, setCurrMcAnswer] = useState([]);
    const [currFeedback, setCurrFeedback] = useState("");

    const allQuestions = props.questions;
    const [allAnswers, setAllAnswers] = useState(null);

    const [didSubmit, setDidSubmit] = useState(false);

    // Render progress circles
    function renderProgress(q) {
        const len = allQuestions.length;

        // Show finish icon
        if (q >= allQuestions.length) {
            return <div className="visit-icon-d"><SignIcon {...{fill: props.theme[0]}} /></div>
        }

        // Show progress circles
        var r=[];
        for (var i=0; i<len; i++) r.push(<div key={`prog-${i}`} className={(q>=i) ? "filled" : ""} />);
        return (<div className="visit-prog-cont"><div className="visit-prog-d iflex">{r}</div></div>);
    }

    // Save answer for Y/N and single choice questions
    function saveAnswer() {
        if (!allAnswers && currAnswer) setAllAnswers([currAnswer]);
        else if (currAnswer) setAllAnswers(allAnswers.concat([currAnswer]));
        else if (!allAnswers) setAllAnswers([null]);
        else setAllAnswers(allAnswers.concat([null]));
        setCurrAnswer(null);
        nextQuestion();
    }

    // Move to next question
    function nextQuestion() {
        setQuestion(question+1);
    }

    // Submit answers
    async function submitSignIn() {
        var submitContact = {name: contactDetails[0], email: contactDetails[1], phone: contactDetails[2]};

        var submitAnswers = [];
        allQuestions.forEach((q, i) => {
            if (i === 0) return; // 'Contact' question gets added, ignore it.
            else submitAnswers.push({qid: q.qid, answer: (allAnswers[i-1] ? ((typeof allAnswers[i-1] === "string") ? allAnswers[i-1] : allAnswers[i-1].join(", ")) : "N/A")});
        });

        const submitResp = await submitAnswersWithCode(props.listingCode, submitContact, submitAnswers);
        if (submitResp.status !== 200) {
            // TODO: Handle error
        } else {
            setDidSubmit(true);
        }
    }

    // Render questions
    function renderQuestion(q) {
        const len = allQuestions.length;

        // Show finish screen
        if (q >= len) {
            // Submit answers
            if (!didSubmit) submitSignIn();

            const title = document.getElementById('visit-q-title');
            title.classList.add('finished');
            
            title.innerHTML = `Thank you for signing in, ${contactDetails[0].charAt(0).toUpperCase() + contactDetails[0].slice(1)}!`
            return(
                <>
                    <div className="visit-q-d">
                        <h3>You've successfully signed into the open house at {props.listingName}.</h3>
                    </div>
                    <button className="finished" onClick={props.goStartPage}>Continue</button>
                    <img className="thanks-overlay-img" src={thanksOverlay} alt="" />
                </>
            );
        }

        // Render questions based on type
        switch (allQuestions[q]['type']) {
            case -1:
                return(
                    <>
                        <div className="visit-q-d">
                            <div key="contact1">
                                <h2>Name</h2>
                                <input id="visit-contact-name" value={contactDetails[0]} onChange={(e)=>{setContactDetails([e.target.value,contactDetails[1],contactDetails[2]])}} placeholder="e.g. John Doe" type="text" />
                            </div>
                            <div key="contact2">
                                <h2>Email Address</h2>
                                <input id="visit-contact-email" value={contactDetails[1]} onChange={(e)=>{setContactDetails([contactDetails[0],e.target.value,contactDetails[2]])}} placeholder="e.g. jdoe@inmail.com" type="email" />
                            </div>
                            <div key="contact3">
                                <h2>Phone Number</h2>
                                <input id="visit-contact-phone" value={contactDetails[2]} onChange={(e)=>{setContactDetails([contactDetails[0],contactDetails[1],e.target.value])}} placeholder="e.g. 416-555-1212" type="tel" />
                            </div>
                        </div>
                        <button className={(contactDetails[0].length > 0 && contactDetails[1].length > 0 && contactDetails[2].length > 0) ? "" : "hidden"} onClick={()=>{
                            if (contactDetails[0].length === 0 || contactDetails[1].length === 0 || contactDetails[2].length === 0) return;
                            nextQuestion();
                        }}>Continue</button>
                    </>
                );
            case 0:
                return(
                    <>
                        <div className="visit-q-d">
                            <div>
                                <h2>{allQuestions[q]['q']}</h2>
                                <button key="yes-k" id="yes-b" className={(!currAnswer) ? "" : ((currAnswer !== 'y') ? "hidden" : "chosen")} onClick={()=>{setCurrAnswer('y')}}><div className="visit-select-c"><div /></div>Yes</button>
                                <button key="no-k" id="no-b" className={(!currAnswer) ? "" : ((currAnswer !== 'n') ? "hidden" : "chosen")} onClick={()=>{setCurrAnswer('n')}}><div className="visit-select-c"><div /></div>No</button>
                            </div>
                        </div>
                        <button className={currAnswer ? "" : "hidden"} onClick={currAnswer ? saveAnswer : ()=>{}}>Continue</button>
                    </>
                );
            case 1:
                var r=[]; var num_options = allQuestions[q]['options'].length;
                for (var i=0; i<num_options; i++) {
                    var currOption = allQuestions[q].options[i];
                    r.push(<button key={`sc-${i}`} id="yes-b" className={(!currAnswer) ? "" : ((currAnswer !== currOption) ? "hidden" : "chosen")} value={currOption} onClick={(e)=>{setCurrAnswer(e.target.value)}}><div className="visit-select-c"><div /></div>{currOption}</button>);
                }
                return(
                    <>
                        <div className="visit-q-d">
                            <div>
                                <h2>{allQuestions[q]['q']}</h2>
                                {r}
                            </div>
                        </div>
                        <button className={currAnswer ? "" : "hidden"} onClick={currAnswer ? saveAnswer : ()=>{}}>Continue</button>
                    </>
                );
            case 2:
                var r=[]; var num_options = allQuestions[q]['options'].length;
                for (var i=0; i<num_options; i++) {
                    var currOption = allQuestions[q].options[i];
                    r.push(
                        <button
                            key={`mc-${i}`}
                            id="yes-b"
                            className={(currMcAnswer.length === 0) ? "" : ((currMcAnswer.includes(currOption)) ? "chosen" : "hidden")}
                            value={currOption}
                            onClick={(e)=>{
                                const ind = currMcAnswer.indexOf(e.target.value);
                                if (ind >= 0) setCurrMcAnswer(currMcAnswer.filter(item => item !== e.target.value));
                                else setCurrMcAnswer(currMcAnswer.concat([e.target.value]));
                            }}
                        ><div className="visit-select-c"><div /></div>{currOption}</button>);
                }
                return(
                    <>
                        <div className="visit-q-d">
                            <div>
                                <h2>{allQuestions[q]['q']}<br/><span>Please select all that apply.</span></h2>
                                {r}
                            </div>
                        </div>
                        <button
                            className={currMcAnswer.length > 0 ? "" : "hidden"}
                            onClick={currMcAnswer.length > 0 ? ()=>{
                                if (allAnswers) setAllAnswers(allAnswers.concat([currMcAnswer]));
                                else setAllAnswers([currMcAnswer]);
                                setCurrMcAnswer([]);
                                nextQuestion();
                            } : ()=>{}}
                        >Continue</button>
                    </>
                );
            case 3:
                return(
                    <>
                        <div className="visit-q-d">
                            <div>
                                <h2>{allQuestions[q]['q']}</h2>
                                <textarea id="visit-contact-feedback" onChange={(e) => {setCurrFeedback(e.target.value)}} value={currFeedback} placeholder="Your response here..." />
                            </div>
                        </div>
                        <button onClick={()=>{
                            if (allAnswers) setAllAnswers(allAnswers.concat([currFeedback]));
                            else setAllAnswers([currFeedback]);
                            setCurrFeedback("");
                            nextQuestion();
                        }}>Continue</button>
                    </>
                );
            default:
                return <></>;
        }
    }

    return(
        <div className={"visit-q-cont"+(` ${props.theme[0]} ${props.theme[1]}`)}>
            { renderProgress(question) }
            <h1 id="visit-q-title">Let's get to know you...</h1>
            { renderQuestion(question) }
        </div>
    );
}

// Functional Component
function Controller() {
    const { id } = useParams();

    // Page states
    const [page, setPage] = useState(0);
    const [pageLoaded, setPageLoaded] = useState(false);
    const [manageOpen, setManageOpen] = useState(false);

    // Data states
    const [listingName, setListingName] = useState(null);
    const [listingTheme, setListingTheme] = useState(null);
    const [questions, setQuestions] = useState(null);

    // Change background image
    var [currImg, setCurrImg] = useState(0);
    const [listingPhotos, setListingPhotos] = useState([]); //["https://images.pexels.com/photos/3288103/pexels-photo-3288103.png?cs=srgb&dl=pexels-curtis-adams-3288103.jpg&fm=jpg", "https://www.compass.com/m/b9183cab1a60ba1a9059d3eefb6cae4259075b10_img_5/origin.jpg", "https://www.compass.com/m/2ab36ec7896ec113b9d6956a5b36529e22965ce6_img_11_4ee92/origin.jpg", "https://remax-listingphotos-ca5.imgix.net/rets-images-vancouver-can/db622c91189ef79c9599b534a373f97541b5023f-1-large.jpeg", "https://remax-listingphotos-ca5.imgix.net/rets-images-edmonton-can/b1f12ec0b0c33301ad022b67286c08c7247c8d21-3-large.jpeg"];
    var interval;
    useEffect(() => {
        if (page === 0) interval = setInterval(() => { setCurrImg((currImg + 1) % listingPhotos.length); }, 3000);
        else interval = setInterval(() => { setCurrImg((currImg + 1) % listingPhotos.length); }, 8000);

        return () => clearInterval(interval);
    }, [currImg, listingPhotos]);

    // Load all info from DB
    useEffect(()=>{
        async function loadPage() {
            const listingInfo_resp = await getListingWithCode(id);
            if (listingInfo_resp.status !== 200) {
                // TODO: Handle error
            } else {
                const resp_data = await listingInfo_resp.json();
                const listingInfo = resp_data.data;
                const listing_name_temp = listingInfo.address_1 + (listingInfo.address_2 ? `, ${listingInfo.address_2}`: "")
                setListingName(listing_name_temp);
                setTitleText(`Welcome to ${listing_name_temp}!`);
                setListingTheme([listingInfo.fg_theme, listingInfo.bg_theme])
                setListingPhotos(resp_data.imgs.map((i) => {
                    return getPhotoUrlFromId(i.photo_id);
                }));
            }

            const questions_resp = await getQuestionsWithCode(id);
            if (questions_resp.status !== 200) {
                // TODO: Handle error
            } else {
                const questions = await questions_resp.json();
                var tempQ = [{'type': -1}];
                questions.forEach(q => {
                    if (q.type === 1 || q.type === 2) {
                        // Single or multiple choice
                        var options = [];
                        for (var i=1; i<=10; i++) {
                            if (q[`opt_${i}`]) options.push(q[`opt_${i}`]);
                            else break;
                        }
                        tempQ.push({'type': q.type, 'qid': q.question_id, 'q': q.question_text, 'options': options});
                    } else tempQ.push({'type': q.type, 'qid': q.question_id, 'q': q.question_text});
                });
                setQuestions(tempQ);
            }

            setPageLoaded(true);
        }
        loadPage();

        // Clear image slideshow interval on close
        return () => clearInterval(interval);
    },[]);

    // Close "Manage" when page changes
    useEffect(()=>{
        setManageOpen(false);
    },[page])

    // Return layout
    return (
        <>
            <Manage {...{open: manageOpen, code: id, close: ()=>{setManageOpen(false)}}} />
            {(page === 0 && pageLoaded) &&
                <div className="visit-start-cont">
                    {listingName &&
                        <div className="visit-start-info">
                            <h3>Welcome to</h3>
                            <h1>{listingName}</h1>
                            <button onClick={questions ? ()=>{setPage(1)} : ()=>{}} className="iflex">Sign In <img src={null} alt="" /></button>
                        </div>
                    }
                    <button className="visit-mng-set" onClick={()=>{setManageOpen(true)}}>
                        <img src={metallic_logo} alt="" />
                    </button>
                    <div className="visit-start-bg" />
                    {(listingPhotos && listingPhotos.length > 0) ?
                        <CrossfadeImage
                            src={listingPhotos[currImg]}
                            duration={500}
                            timingFunction={"ease-out"}
                        />
                        :
                        <div className="placeholder" />
                    }
                </div>
            }
            {(page === 1 && pageLoaded) &&
                <div className="visit-in-cont iflex">
                    <div className="visit-side-img">
                        {(listingPhotos && listingPhotos.length > 0) ?
                            <CrossfadeImage
                                src={listingPhotos[currImg]}
                                duration={1000}
                                timingFunction={"ease-out"}
                            />
                            :
                            <div className="placeholder" />
                        }
                    </div>
                    <Questions {...{questions: questions, listingCode: id, listingName: listingName, theme: listingTheme, goStartPage: ()=>{setPage(0)}}} />
                </div>
            }
        </>
    );
}

export default Controller;