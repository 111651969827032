// Dependencies
import { useState, useEffect } from "react";
import Select from "react-select";

// API Imports
import { loadLeadQuestions_bulk } from "../../apicalls/leads.js";

// Util Imports
import { useError } from '../ui/Context/ErrorContext';

// SVG Imports
import back_icon from "../../img/svg/listing/back_chevron.svg";
import filter_orange from "../../img/svg/topbar/filter_orange.svg";
import house_orange from "../../img/svg/topbar/house_orange.svg";
import yn_icon from "../../img/svg/signin/yn_icon.svg";
import sc_icon from "../../img/svg/signin/sc_icon.svg";
import mc_icon from "../../img/svg/signin/mc_icon.svg";
import oe_icon from "../../img/svg/signin/oe_icon.svg";


// Constants 
const selectStyle = {
	control: (styles, { isFocused }) => ({
		...styles,
		borderColor: isFocused ? 'var(--orange-accent)' : styles.borderColor,
		boxShadow: (isFocused) ? '0 0 0 1px var(--orange-accent)' : styles.boxShadow,
		'&:hover': {
			borderColor: 'var(--orange-accent)'
		}
	}),
	option: (provided, state) => ({
		...provided,
		fontFamily: 'Inter',
		fontSize: '15px',
		backgroundColor: state.isFocused ? 'var(--orange-accent-lite)' : null,
		color: 'black',
		':active': {
			backgroundColor: 'var(--orange-accent)'
		},
	}),
	noOptionsMessage: (styles) => ({
		...styles,
		fontSize: '15px',
		fontFamily: 'Inter',
		color: 'var(--grey-4)'
	}),
	singleValue: (styles) => ({
		...styles,
		fontSize: '15px',
		fontFamily: 'Inter',
		color: 'black'
	})
};

// Helper Functional Component
function Question({typ, text, options, id, required, addFilter, removeFilter}) {
	const [expanded, setExpanded] = useState(false || required.length > 0);
	const [selectedOptions, setSelectedOptions] = useState(required);

	// Return layout
	return (
		<div onClick={(_)=>{setExpanded(!expanded || required.length > 0)}} className={(selectedOptions.length > 0) ? "sel" : ""}>
			<div className="flex">
				<h3>{text}</h3>
				<div className="q-type iflex" draggable={false}>
					{(typ === 0) && <img draggable={false} src={yn_icon} alt="" />}
					{(typ === 1) && <img draggable={false} src={sc_icon} alt="" />}
					{(typ === 2) && <img draggable={false} src={mc_icon} alt="" />}
					{(typ === 3) && <img draggable={false} src={oe_icon} alt="" />}
					{(typ === 0) && <h3 draggable={false}>Yes / No</h3>}
					{(typ === 1) && <h3 draggable={false}>Single Select</h3>}
					{(typ === 2) && <h3 draggable={false}>Multi Select</h3>}
					{(typ === 3) && <h3 draggable={false}>Open Ended</h3>}
				</div>
			</div>
			{(expanded) &&
				<div className="o-cont">
					{(typ === 0) && 
						<>
							<button className={(selectedOptions.includes("y")) ? "sel" : ""} onClick={(e)=>{ e.stopPropagation(); if (selectedOptions.includes("y")) { setSelectedOptions(selectedOptions.filter(x => x !== "y")); removeFilter("y", id); } else { setSelectedOptions([...selectedOptions, "y"]); addFilter("y", id); }}}><div className="c-cont"><div /></div>Yes</button>
							<button className={(selectedOptions.includes("n")) ? "sel" : ""} onClick={(e)=>{ e.stopPropagation(); if (selectedOptions.includes("n")) { setSelectedOptions(selectedOptions.filter(x => x !== "n")); removeFilter("n", id); } else { setSelectedOptions([...selectedOptions, "n"]); addFilter("n", id); }}}><div className="c-cont"><div /></div>No</button>
						</>
					}
					{(typ === 1 || typ === 2) &&
						<>
							{options.map((opt, ind)=>{ return (<button key={`${id}-${ind}`} className={(selectedOptions.includes(opt)) ? "sel" : ""} onClick={(e)=>{ e.stopPropagation(); if (selectedOptions.includes(opt)) { setSelectedOptions(selectedOptions.filter(x => x !== opt)); removeFilter(opt, id); } else { setSelectedOptions([...selectedOptions, opt]); addFilter(opt, id); } }}><div className="c-cont"><div /></div>{opt}</button>); })}
						</>
					}
					{(typ === 3) &&
						<>
							<button className={(selectedOptions.includes("r")) ? "sel" : ""} onClick={(e)=>{ e.stopPropagation(); if (selectedOptions.includes("r")) { setSelectedOptions(selectedOptions.filter(x => x !== "r")); removeFilter("r", id); } else { setSelectedOptions([...selectedOptions, "r"]); addFilter("r", id); } }}><div className="c-cont"><div /></div>Responded</button>
							<button className={(selectedOptions.includes("nr")) ? "sel" : ""} onClick={(e)=>{ e.stopPropagation(); if (selectedOptions.includes("nr")) { setSelectedOptions(selectedOptions.filter(x => x !== "nr")); removeFilter("nr", id); } else { setSelectedOptions([...selectedOptions, "nr"]); addFilter("nr", id); } }}><div className="c-cont"><div /></div>Didn't Respond</button>
						</>
					}
				</div>
			}
		</div>
	);
}

// Helper Functional Component
function RenderQuestions({questions, questionsRequired, addFilter, removeFilter}) {
	// Return rendered rows
	function render(quest) {
		var r = [];
		quest.forEach((q, ind) => {
			r.push(<Question key={`fqd-${ind}`} typ={q.type} text={q.text} options={Object.keys(q.options)} id={ind} required={questionsRequired[ind]} addFilter={addFilter} removeFilter={removeFilter} />);
		});
		return r;
	}

	// Return layout
	if (!questions || questions.length === 0) return (<></>);
	else return (<div className="filt-q-cont">{render(questions)}</div>);
}

// Helper Functional Component
function RenderListings({ listings, removeListing }) {
	function render(l) {
		var r = [];
		l.forEach((e) => {
			r.push(
				<div className="filt-lst-d-cont" key={"k"+e.code}>
					<div className="filt-lst-d"><img src={house_orange} alt="" /><h2>{e.address}</h2></div>
					<button onClick={()=>{removeListing(e.code, e.address)}}>
						Remove
						<img src={null} alt="" />
					</button>
				</div>
			);
		});
		return r;
	}
	return render(listings);
}


// Functional Component
function FilterLeads({
	all_leads, setFilteredLeads,
	listingsRequired, setListingsRequired,
	listingCodesRequired=[], setListingCodesRequired,
	phoneRequired, setPhoneRequired,
	emailRequired, setEmailRequired,
	questionsRequired, setQuestionsRequired,
	goBack,
	isManageListing = false
}) {
    const { showError } = useError();

	// Misc page states
	const [selectValueHook, setSelectValueHook] = useState(null);
	const [listingsSelectValues, setListingsSelectValues] = useState([]);

	// Data states
	const [loadedQuestions, setLoadedQuestions] = useState([]);

	// Add a required question
	function addQuestionFilter(option, ind) {
		var newFilters = {...questionsRequired};
		newFilters[ind] = [...questionsRequired[ind], option];
		setQuestionsRequired(newFilters);
	}

	// Remove a required question
	function removeQuestionFilter(option, ind) {
		var newFilters = {...questionsRequired};
		newFilters[ind] = questionsRequired[ind].filter((o) => o !== option);
		setQuestionsRequired(newFilters);
	}

	// Add a required listing
	function addRequiredListing(code, address) {
		if (listingsRequired.filter((l) => l.code === code).length !== 0) return;
		setListingsRequired([...listingsRequired, {code: code, address: address}]);
		setListingCodesRequired([...listingCodesRequired, code]);
		setListingsSelectValues(listingsSelectValues.filter((v) => v.value !== code));
		setSelectValueHook(null);
	}

	// Remove a required listing
	function removeRequiredListing(code, name) {
		var updatedCodes = [];
		const updated = listingsRequired.filter((listing) => {
			const include = listing.code !== code;
			if (include) updatedCodes.push(listing.code);
			return include;
		});
		setListingsRequired(updated);
		setListingCodesRequired(updatedCodes)
		setListingsSelectValues([...listingsSelectValues, {value: code, label: name}]);
	}

	// Load page data
	useEffect(()=>{
		async function loadPage() {
			// Load questions to filter by answer
			const resp = await loadLeadQuestions_bulk(all_leads.map((e)=>{return e.id}));
			if (resp.status !== 200) showError('An unexpected error occurred.');
			else {
				const data = await resp.json();
				if (!data.q || data.q.length <= 0) return;
				if (Object.keys(questionsRequired).length <= 0) setQuestionsRequired(Array.from({length: data.q.length}, (_, i) => i).reduce((acc, cur) => ({...acc, [cur]: []}), {}));
				setLoadedQuestions(data.q);
			}

			// Load listings into select box
			var temp = []; var m = {};
			all_leads.forEach(lead => {
				const address = lead.address_1 + (lead.address_2 ? `, ${lead.address_2}` : "");
				if (m[address] || listingCodesRequired.includes(lead.code)) return;
				else {
					temp.push({value: lead.code, label: address});
					m[address] = "x";
				}
			});
			setListingsSelectValues(temp);
		}
		loadPage();
	}, []);

	// Apply filters
	useEffect(()=>{
		// Filter by question first
		var ids = all_leads.map((e)=>{return e.id}); // Get all ID's
		if (loadedQuestions.length !== 0) {
			for (const [question_index, options] of Object.entries(questionsRequired)) {
				if (options.length === 0) continue;
				var curr_ids = [];
				// "OR" within each required question
				options.forEach((opt) => { curr_ids = curr_ids.concat(loadedQuestions[question_index].options[opt]); });
				// "AND" between required questions + Set to remove duplicates
				ids = [... new Set(ids.filter(x => curr_ids.includes(x)))];
			}
		}
		// Then, use other filters
		const lr_length = listingCodesRequired.length || 0;
		const filtered = all_leads.filter(l =>
			(ids.includes(l.id)) &&
			(!phoneRequired || (l.phone && l.phone.length > 0)) &&
			(!emailRequired || (l.email && l.email.length > 0)) &&
			(lr_length === 0 || listingCodesRequired.includes(l.code))
		);
		setFilteredLeads(filtered);
	}, [loadedQuestions, phoneRequired, emailRequired, listingsRequired, questionsRequired]);

	// Return layout
	return (
		<div className="pop-pg-cont">
			<button className="lead-prof-back-b" onClick={goBack}>
				<img src={back_icon} alt="" />
			</button>
			<div className="pop-pg-t flex">
				<img className="tb-t-icon" src={filter_orange} alt="" />
				<h1 className="pg-t">Filter Leads</h1>
			</div>
			<div className="content-spacer" />
			{(!isManageListing) &&
				<div className="lst-new-i-d">
					<h2>Listings Visited</h2>
					<RenderListings {...{listings: listingsRequired, removeListing: removeRequiredListing}} />
					{(listingsSelectValues.length > 0) && 
						<Select
							options={listingsSelectValues}
							styles={selectStyle}
							placeholder="No Listing Selected"
							value={selectValueHook}
							onChange={(e)=>{if (e) { addRequiredListing(e.value, e.label) }}}
							noOptionsMessage={(_) => "No listings found"}
						/>
					}
				</div>
			}
			<div className="lst-new-i-d">
				<h2>Contact Provided</h2>
				<button className={"sel-b" + ((phoneRequired) ? " active" : "")} onClick={()=>{setPhoneRequired(!phoneRequired)}}>
					<div><div/></div>
					<h3>Phone Number</h3>
				</button>
				<button className={"sel-b" + ((emailRequired) ? " active" : "")} onClick={()=>{setEmailRequired(!emailRequired)}}>
					<div><div/></div>
					<h3>Email Address</h3>
				</button>
			</div>
			{(loadedQuestions.length > 0) && 
				<div className="lst-new-i-d mb0">
					<h2>Answers</h2>
					<RenderQuestions questions={loadedQuestions} questionsRequired={questionsRequired} addFilter={addQuestionFilter} removeFilter={removeQuestionFilter} />
				</div>
			}
		</div>
	);
}

export default FilterLeads;