// Dependencies
import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

// API Imports
import { getLeadProfileFor, handleDeleteLeadWithId } from "../../apicalls/leads.js";

// Util Imports
import { useError } from '../ui/Context/ErrorContext';
import { formatPhoneNumber } from "../../utils/formatPhone.js";
import setTitleText from "../../utils/setTitleText.js";

// SVG Imports
import back_icon from "../../img/svg/listing/back_chevron.svg";
import house_icon from "../../img/svg/listing/house_small.svg";
import email_icon from "../../img/svg/listing/email_orange.svg";
import phone_icon from "../../img/svg/listing/phone_orange.svg";
import no_icon from "../../img/svg/listing/x_circle.svg";
import yes_icon from "../../img/svg/listing/check_circle.svg";

// Functional Component
function LeadProfile() {
    const { id } = useParams();
    const navigate = useNavigate();
    const { showError } = useError();

    // Page states
    const [didLoad, setDidLoad] = useState(false);
    const [loaderOn, setLoaderOn] = useState(false);

    // Data states
    const [profileDetails, setProfileDetails] = useState(null);
    const [answers, setAnswers] = useState(null);

    // Load info
    async function loadPage() {
        const resp = await getLeadProfileFor(id);
        setTimeout(()=>{setLoaderOn(true)}, 500);
        if (resp.status !== 200) {
            showError('An unexpected error occurred.');
        } else {
            const profile = await resp.json();
            setProfileDetails(profile.profile);
            setTitleText(profile.profile.name);
            setAnswers(profile.answers);
            setDidLoad(true);
        }
    }
    // Load info on page load
    useEffect(()=>{
        loadPage();
    },[])

    // Render rows of answers
    function renderAnswers() {
        var r = [];
        answers.forEach((answer, i) => {
            r.push(
                <div key={`la-${i}`} className={"lead-prof-si-ans-d" + ((answer.answer === 'y' || answer.answer === 'n') ? " yn": "")}>
                    <h3>{answer.question_text}</h3>
                    <div className="iflex">
                        {(answer.answer === 'y' || answer.answer === 'n') ?
                            <>
                                <img src={(answer.answer === 'y') ? yes_icon : no_icon} alt="" />
                                <h3>{(answer.answer === 'y') ? "Yes" : "No"}</h3>
                            </>
                            :
                            <>
                                <h3>{answer.answer || "N/A"}</h3>
                            </>
                        }
                    </div>
                </div>
            );
        });
        return r;
    }

    // Handle delete lead
    async function handleDeleteLead() {
        const resp = await handleDeleteLeadWithId(id);
        if (resp.status !== 200) {
            showError('An unexpected error occurred.');
        } else {
            // TODO: navigate back to leads page
            navigate("/leads",{replace:true})
        }
    }

    // Return layout
    return(
        <div className="lst-mng-lead-prof-cont lds">
            {(didLoad && profileDetails) ?
                <>
                    <div className="lead-prof-top iflex">
                        <div className="lead-prof-pf flex"><h1>{profileDetails.name.match(/\b\w/g).slice(0, 2).join('').toUpperCase()}</h1></div>
                        <div className="r">
                            <h1 className="lead-prof-nm lp">{profileDetails.name || ""}</h1>
                            <div className="lead-prof-place iflex">
                                <img src={house_icon} alt="" />
                                <h3>{profileDetails.address_1 + (profileDetails.address_2 ? `, ${profileDetails.address_2}` : "")}</h3>
                            </div>
                        </div>
                    </div>
                    <div className="content-spacer" />
                    <div className="lst-new-i-d">
                        <h2>Contact Info</h2>
                        <div className="iflex">
                            <div className="lead-prof-contact-d iflex">
                                <img src={email_icon} alt="" />
                                <h3>{profileDetails.email || "N/A"}</h3>
                            </div>
                            <div className="lead-prof-contact-d iflex">
                                <img src={phone_icon} alt="" />
                                <h3>{formatPhoneNumber(profileDetails.phone) || "N/A"}</h3>
                            </div>
                        </div>                
                    </div>
                    <div className="lst-new-i-d">
                        <h2>Notes</h2>
                        <h3 className="lead-prof-notes">{profileDetails.notes || "No notes available."}</h3>
                    </div>
                    <div className="lst-new-i-d">
                        <h2>Sign-In</h2>
                        { (answers) && renderAnswers() }
                    </div>
                    {/*<div className="lst-new-i-d">
                        <h2>Events</h2>
                        { loadEvents() }
                    </div>*/}
                    <div className="lst-new-i-d mb0">
                        <h2>Settings</h2>
                        <div className="lead-prof-b-d iflex">
                            <div>
                                <h2>Delete Lead</h2>
                                <h3>This action cannot be undone.</h3>
                            </div>
                            <button onClick={handleDeleteLead}>Delete Lead</button>
                        </div>
                    </div>
                </>
                :
                <>{(loaderOn) && <div className="prl-spin-cont nt"><div className="prl-spin" /></div>}</>
            }
        </div>
    );
}

export default LeadProfile;