// Dependencies
import { useState, useEffect } from "react";

// API Imports
import { getLeadProfileFor, handleDeleteLeadWithId } from "../../../../apicalls/leads.js";

// Util Imports
import { formatPhoneNumber } from "../../../../utils/formatPhone.js";
import { useError } from '../../../ui/Context/ErrorContext';

// SVG Imports
import back_icon from "../../../../img/svg/listing/back_chevron.svg";
import house_icon from "../../../../img/svg/listing/house_small.svg";
import email_icon from "../../../../img/svg/listing/email_orange.svg";
import phone_icon from "../../../../img/svg/listing/phone_orange.svg";
import no_icon from "../../../../img/svg/listing/x_circle.svg";
import yes_icon from "../../../../img/svg/listing/check_circle.svg";


// Functional Component
function LeadProfile(props) {
    const { showError } = useError();

    // Page states
    const [didLoad, setDidLoad] = useState(false);
    const [loaderOn, setLoaderOn] = useState(false);

    // Data states
    const [profileDetails, setProfileDetails] = useState(null);
    const [answers, setAnswers] = useState(null);

    // Load info
    useEffect(()=>{
        const leadId = props.id;

        async function loadPage() {
            const resp = await getLeadProfileFor(leadId);
            setTimeout(()=>{setLoaderOn(true)}, 500); // Turn preloader on after 0.5s

            if (resp.status !== 200) {
                showError('An unexpected error occurred.');
            } else {
                const profile = await resp.json();
                setProfileDetails(profile.profile);
                setAnswers(profile.answers);
                setDidLoad(true);
            }
        }
        loadPage();
    },[]);

    // Render rows of answers
    function renderAnswers() {
        var r = [];
        answers.forEach((answer,i) => {
            r.push(
                <div key={`la-${i}`} className={"lead-prof-si-ans-d" + ((answer.answer === 'y' || answer.answer === 'n') ? " yn": "")}>
                    <h3>{answer.question_text}</h3>
                    <div className="iflex">
                        {(answer.answer === 'y' || answer.answer === 'n') ?
                            <>
                                <img src={(answer.answer === 'y') ? yes_icon : no_icon} alt="" />
                                <h3>{(answer.answer === 'y') ? "Yes" : "No"}</h3>
                            </>
                            :
                            <>
                                <h3>{answer.answer || "N/A"}</h3>
                            </>
                        }
                    </div>
                </div>
            );
        });
        return r;
    }

    // Load events
    function loadEvents() {
        var r = [];
        return r;
    }

    // Handle delete lead
    async function handleDeleteLead() {
        const resp = await handleDeleteLeadWithId(props.id);
        if (resp.status !== 200) {
            showError('An unexpected error occurred.');
        } else {
            // TODO: navigate back to leads page
            props.removeLead(props.id);
            props.goBack();
        }
    }

    // Return layout
    return(
        <>
            {(didLoad && profileDetails) ?
                <>
                    <button className="lead-prof-back-b" onClick={props.goBack}>
                        <img src={back_icon} alt="" />
                    </button>
                    <h1 className="lead-prof-nm">{profileDetails.name || ""}</h1>
                    <div className="lead-prof-place iflex">
                        <img src={house_icon} alt="" />
                        <h3>{props.listing_name}</h3>
                    </div>
                    <div className="content-spacer" />
                    <div className="lst-new-i-d">
                        <h2>Contact Info</h2>
                        <div className="iflex">
                            <div className="lead-prof-contact-d iflex">
                                <img src={email_icon} alt="" />
                                <h3>{profileDetails.email || "N/A"}</h3>
                            </div>
                            <div className="lead-prof-contact-d iflex">
                                <img src={phone_icon} alt="" />
                                <h3>{(profileDetails.phone) ? formatPhoneNumber(profileDetails.phone) : "N/A"}</h3>
                            </div>
                        </div>                
                    </div>
                    <div className="lst-new-i-d">
                        <h2>Notes</h2>
                        <h3 className="lead-prof-notes">{profileDetails.notes || "No notes available."}</h3>
                    </div>
                    <div className="lst-new-i-d">
                        <h2>Sign-In</h2>
                        { (answers) && renderAnswers() }
                    </div>
                    {/*<div className="lst-new-i-d">
                        <h2>Events</h2>
                        { loadEvents() }
                    </div>*/}
                    <div className="lst-new-i-d mb0">
                        <h2>Settings</h2>
                        <div className="lead-prof-b-d iflex">
                            <div>
                                <h2>Delete Lead</h2>
                                <h3>This action cannot be undone.</h3>
                            </div>
                            <button onClick={handleDeleteLead}>Delete Lead</button>
                        </div>
                    </div>
                </>
                :
                <>{(loaderOn) && <div className="prl-spin-cont"><div className="prl-spin" /></div>}</>    
            }
        </>
    );
}

export default LeadProfile;