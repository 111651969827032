// Dependencies
import { useState } from 'react';

// API Imports 
import { api_requestFeature } from "../../apicalls/compose.js";

// Util Imports
import { useError } from "../ui/Context/ErrorContext";

// SVG Imports
import back_icon from "../../img/svg/listing/back_chevron.svg";

// Functional Component
function ComposeRequest({goHome}) {
	const { showError } = useError();

	// Page States
	const [internalPage, setInternalPage] = useState(0);

	// Data States
	const [requestDescA, setRequestDescA] = useState("");
	const [requestDescB, setRequestDescB] = useState("");
	const [requestDescC, setRequestDescC] = useState("");

	// Handle uploading request
	async function handleMakeRequest() {
		const resp = await api_requestFeature(requestDescA, requestDescB, requestDescC);
		if (resp.status !== 200) showError("An unexpected error occurred.");
		else setInternalPage(1);
	}

	// Handle refreshing request (ie. make a new request after already doing one)
	function refreshRequest() {
		setRequestDescA(""); setRequestDescB(""); setRequestDescC("");
		setInternalPage(0);
	}

	// Return layout
	return (
		<div className="pop-pg-cont">
			<button className="lead-prof-back-b" onClick={goHome}>
				<img src={back_icon} alt="" />
			</button>
			<div className="ai-tool-t-d flex">
				<div className="flex m0">
					<h1>{(internalPage === 0) ? "Request a Compose Preset" : "We've Received Your Request!"}</h1>
				</div>
			</div>
			<div className="content-spacer ai-tool" />
			{(internalPage === 0) &&
				<>
					<div className="ai-tool-input">
						<h2>What preset are you missing?</h2>
						<h3>Please describe the preset you feel is missing. What information would the preset ask for? What would it do?</h3>
						<textarea maxLength={650} value={requestDescA} onChange={(e)=>{setRequestDescA(e.target.value)}} placeholder={"Describe your request here..."} />
					</div>
					<div className="ai-tool-input">
						<h2>What problem would it solve for you?</h2>
						<h3>Please explain what the ultimate goal of this preset would be. How would it help you and your team?</h3>
						<textarea maxLength={650} value={requestDescB} onChange={(e)=>{setRequestDescB(e.target.value)}} placeholder={"Describe your ultimate goal here..."} />
					</div>
					<div className="ai-tool-input mb0">
						<h2>Additional Information</h2>
						<h3>Any other relevant information you’d like to include in this request.</h3>
						<textarea maxLength={650} value={requestDescC} onChange={(e)=>{setRequestDescC(e.target.value)}} />
					</div>
					<div className="dash-cont-b-d iflex">
						<button className={"next"+((requestDescA.length === 0 || requestDescB.length === 0) ? " hidden" : "")} disabled={requestDescA.length === 0 || requestDescB.length === 0} onClick={handleMakeRequest}>Submit Request</button>
					</div>
				</>
			}
			{(internalPage === 1) &&
				<>
					<div className="ai-tool-input mb0">
						<h3>
							<span className="tm">
								Thank you for your submission! 🧡
								<br /><br />
								We are always looking for ways to improve ListingOpen for our users.
								<br /><br />
								We will take your preset request into consideration as we continue to develop and update ListingOpen. Your input is valuable to us — we might reach out to you for more information on your preset request.
								<br /><br />
								If you have any further feedback or suggestions, please don't hesitate to contact us.
								<br /><br />
								We are always here to listen and help!
							</span>
						</h3>
					</div>
					<div className="dash-cont-b-d iflex">
						<button className="back" onClick={goHome}>&lt;- Go Back</button>
						<button className="next" onClick={refreshRequest}>New Request</button>
					</div>
				</>
			}
		</div>
	);
}

export default ComposeRequest;