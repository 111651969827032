// Dependencies
import { useState, useRef } from 'react';

// Util Imports
import { prettyFileSize } from "../../utils/prettyFileSize.js";

// SVG Imports
import x_icon from "../../img/svg/signin/x_icon.svg";
import upload_orange from "../../img/svg/listing/upload_orange.svg";

// Functional Component 
function AddPhotoModal(props) {
	const fileInput = useRef(null);
	const [imageBlob, setImageBlob] = useState(null);
	const [imageFile, setImageFile] = useState(null);
	const [imageUrl, setImageUrl] = useState(null);

	function handleChooseFile() {
		fileInput.current.click();
	}

	function handleFileChange(e) {
		const file = e.target.files[0];
		if (file) {
			// Convert file to blob
			const blob = new Blob([file], { type: file.type });
			setImageBlob(blob);
			setImageFile(file);
			setImageUrl(URL.createObjectURL(blob));
		}
	}

	function handleRemoveImage() {
		setImageBlob(null);
		setImageFile(null);
		setImageUrl(null);
	}

	function handleAddImage() {
		if (!imageBlob || !imageUrl || !imageFile) return;
		if (props.singleImage) {
			props.setImageBlob(imageBlob);
			props.setImageUrl(imageUrl);
		} else {
			props.setImageBlobs([...props.imageBlobs, imageBlob]);
			props.setImageUrls([...props.imageUrls, imageUrl]);
		}
		props.closeModal();
	}

	<div>
		<h2>Upload new image...</h2>
		<button onClick={handleChooseFile}>New Image</button>
	</div>

	// Return layout
	return (
		<div className="add-img-mod-bg">
			<div className="add-img-mod-cont">
				<div className="add-img-mod-top iflex">
					<h2>Add Photo</h2>
					<button onClick={props.closeModal}><img src={x_icon} alt="" /></button>
				</div>
				<div className="add-img-mod-spacer" />
				<div className="add-img-mod-btm">
					{(!imageUrl) ?
						<>
							<input type="file" ref={fileInput} accept=".jpg,.jpeg,.png" onChange={handleFileChange} style={{display: 'none'}} />
							<button className="add-img-mod-b" onClick={handleChooseFile}>
								<img src={upload_orange} alt="" />
								<h2><span className="orange">Choose file</span> to upload</h2>
								<h3>PNG, JPG, or JPEG</h3>
							</button>
						</>
						:
						<>
							<div className="add-img-mod-prv-d iflex">
								<img src={imageUrl} alt="" />
								<div>
									<h2>{imageFile.name}</h2>
									<h3>{prettyFileSize(imageFile.size)}</h3>
								</div>
							</div>
							<div className="flex">
								<button className="add-img-mod-sm-b del" onClick={handleRemoveImage}>Remove</button>
								<button className="add-img-mod-sm-b sv" onClick={handleAddImage}>Add Photo</button>
							</div>
						</>
					}
				</div>
			</div>
		</div>
	);
}

export default AddPhotoModal;