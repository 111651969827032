// Dependencies
import { useState, useEffect, useRef } from 'react';

// Helper Functions
function parseText(text) {
  const parts = text.split(/({.*?})/g);
  return parts.map((part, index) => 
    part.startsWith('{') && part.endsWith('}') && (part === "{name}" || part === "{address}") ? (
      <span key={index} className="orange">{part}</span>
    ) : (part)
  );
}

// Functional Component
export function PersonalizeInput ({ val, setVal }) {
  // Personalize text
  const handleChange = (evt) => {
    setVal(evt.target.value);
  };

  // Return layout
  return (
    <div className="atm-em-prs-in">
      <div>{parseText(val)}</div>
      <input value={val} onChange={handleChange} maxLength={65} />
    </div>
  );
};

// Functional Component
export function PersonalizeTextArea ({ val, setVal }) {
  // Personalize text
  const handleChange = (evt) => {
    setVal(evt.target.value);
  }

  // Auto-height
  const taRef = useRef(null);

  useEffect(() => {
    if (taRef.current) {
      // Reset the height momentarily to get the correct scrollHeight for the textarea
      taRef.current.style.height = "0px";
      const scrollHeight = taRef.current.scrollHeight;
      // We then set the height directly, outside of the render loop
      // Trying to set this with state or a ref will product an incorrect value.
      taRef.current.style.height = (scrollHeight - 30) + "px"; // Minus 30 to get rid of padding
    }
  }, [taRef.current, val]);

  // Return layout
  return (
    <div className="atm-em-prs-ta">
      <div>{parseText(val)}</div>
      <textarea
        value={val}
        onChange={handleChange}
        ref={taRef}
        rows={1}
      />
    </div>
  )
}

export function AutoHeight_Textarea({ val, setVal, className="", placeholder="" }) {
	const taRef = useRef(null);

	useEffect(() => {
		if (taRef.current) {
		  // Reset the height momentarily to get the correct scrollHeight for the textarea
		  taRef.current.style.height = "0px";
		  const scrollHeight = taRef.current.scrollHeight;
		  // We then set the height directly, outside of the render loop
		  // Trying to set this with state or a ref will product an incorrect value.
		  taRef.current.style.height = (scrollHeight - 30) + "px"; // Minus 30 to get rid of padding
		}
	  }, [taRef.current, val]);

	// Return layout
	return (
		<textarea
			className="atm-in"
      placeholder={placeholder}
      className={className}
			value={val}
			onChange={(e)=>{setVal(e.target.value)}}
			ref={taRef}
			rows={1}
		/>
	);
}