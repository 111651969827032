// Dependencies
import { useState } from 'react';

// API Imports
import { api_highlightFeatures } from "../../../apicalls/compose.js";

// Component Imports
import AI_Input from "../comp/Input";
import AI_Textbox from "../comp/Textbox";
import AI_Tone from "../comp/Tone";
import { AI_Single_Select } from '../comp/Select';
import FinishedCompose from '../comp/FinishedCompose.jsx';

// Util Imports
import { useError } from '../../ui/Context/ErrorContext';
import { useUpgrade } from '../../ui/Context/UpgradeContext';

// Constants
const allPropertyTypes = ["Townhouse","Detached Home","Condominium","Apartment","Duplex","Multiplex","Bungalow","Luxury Property"];


// Functional Component
function Highlight_PropertyHighs(props) {
    const { showError } = useError(); 
    const { showUpgrade } = useUpgrade();

    // Data states
    const [propertyType, setPropertyType] = useState(null);
    const [propertyAddress, setPropertyAddress] = useState("");
    const [usp, setUsp] = useState("");
    const [target, setTarget] = useState("");
    const [addInfo, setAddInfo] = useState("");
    const [tone, setTone] = useState(null);

    // Helper functions
    async function generateCompose() {
        const resp = await api_highlightFeatures({
            pt: propertyType,
            pa: propertyAddress,
            usp: usp,
            ta: target,
            ai: addInfo,
        });
        switch (resp.status) {
            case 200:
                const msg = await resp.json();
                return msg.c;
            case 401:
                showUpgrade('Compose');
                return "Compose is a Team feature. Please upgrade your plan to continue.";
            default:
                showError('An unexpected error occurred.');
                return "Sorry, an unexpected error occurred.";
        }
    }

    // Return layout
    return (
        <>
            {(props.stage === 0) &&
                <>
                    <AI_Single_Select val={propertyType} setVal={setPropertyType} allValues={allPropertyTypes} title="Property Type" includeOther={true} otherTitle="Please specify Property Type" otherPlaceholder="e.g. Semi-Detached" />
                    <AI_Input val={propertyAddress} setVal={setPropertyAddress} title="Property Address" placeholder="e.g. 123 Main Street" />
                    <AI_Textbox val={usp} setVal={setUsp} title="Unique Selling Points" description="List the key features that make this property special." placeholder="e.g. Picturesque waterfront view, a spacious open-concept layout, a newly renovated kitchen, and a large backyard perfect for entertaining." />
                    <AI_Textbox val={target} setVal={setTarget} title="Target Audience" description="Describe the ideal buyers or tenants of this property." placeholder="e.g. First-time homebuyers looking for affordability, families seeking a quiet neighborhood, or investors seeking rental income." />
                    <AI_Textbox val={addInfo} setVal={setAddInfo} title="Additional Information" description="Any other relevant information you’d like to include in the property highlights." />
                    <AI_Tone val={tone} setVal={setTone} last={true} />
                </>
            }
            {(props.stage === 1) &&  <FinishedCompose {...{generate: generateCompose}} /> }
        </>
    );
}

export default Highlight_PropertyHighs;