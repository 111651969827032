// Dependencies
import { useState } from "react";

// Component Imports
import ComposeRequest from "./ComposeRequest";
import Create_ListingDesc from "./create/ListingDesc";
import Create_NeighbourDesc from "./create/NeighbourDesc";
import Create_OpenHouseInv from "./create/OpenHouseInv";
import Enhance_ListingDesc from "./enhance/ListingDesc";
import Enhance_PersonalizeDesc from "./enhance/PersonalizeDesc";
import Highlight_PropertyHighs from "./highlight/PropertyHighs";
import Highlight_PropertyComps from "./highlight/PropertyComps";
import Highlight_InvestBenefits from "./highlight/InvestBenefits";
import Explain_Closing from "./explain/Closing";
import Explain_LeaseAgr from "./explain/LeaseAgr";
import Explain_Jargon from "./explain/Jargon";

// Util Imports
import setTitleText from "../../utils/setTitleText.js";

// SVG Imports
import feather_orange from "../../img/svg/topbar/feather_orange.svg";
import request_icon from "../../img/svg/ai_suite/request.svg";
import back_icon from "../../img/svg/listing/back_chevron.svg";
import search_icon from "../../img/svg/topbar/search_icon.svg";

// PNG Imports
import book_icon from "../../img/png/ai_suite/book.png";
import build_icon from "../../img/png/ai_suite/build.png";
import compare_icon from "../../img/png/ai_suite/compare.png";
import door_icon from "../../img/png/ai_suite/door.png";
import group_icon from "../../img/png/ai_suite/group.png";
import handshake_icon from "../../img/png/ai_suite/handshake.png";
import hat_icon from "../../img/png/ai_suite/hat.png";
import leaf_icon from "../../img/png/ai_suite/leaf.png";
import money_icon from "../../img/png/ai_suite/money.png";
import pen_icon from "../../img/png/ai_suite/pen.png";
import road_icon from "../../img/png/ai_suite/road.png";
import sign_icon from "../../img/png/ai_suite/sign.png";
import star_icon from "../../img/png/ai_suite/star.png";
import sun_icon from "../../img/png/ai_suite/sun.png";
import target_icon from "../../img/png/ai_suite/target.png";


// Constants
const presets = [
	// Note: ID + 1 = page number (ex. "page 2" = id #1 = Create Open House Invite)
	{id: 0, title: "Create Listing Description", desc: "Craft magnetic property listings that convert.", tag: "Create", img: pen_icon},
	{id: 1, title: "Create Open House Invite", desc: "Drive open house attendance with enticing copy.", tag: "Create", img: door_icon},
	{id: 2, title: "Create Neighbourhood Description", desc: "Entice appeal with vivid neighbourhood descriptions.", tag: "Create", img: road_icon},
	{id: 3, title: "Enhance Listing Description", desc: "Elevate property listings with compelling copy.", tag: "Enhance", img: hat_icon},
	{id: 4, title: "Personalize Listing Description", desc: "Tailor listing descriptions to specific client needs.", tag: "Enhance", img: target_icon},
	{id: 5, title: "Showcase Property Highlights", desc: "Emphasize unique property features.", tag: "Highlight", img: star_icon},
	{id: 6, title: "Highlight Property Comparisons", desc: "Streamline comparisons for easier decision-making.", tag: "Highlight", img: compare_icon},
	{id: 7, title: "Highlight Investment Benefits", desc: "Quantify investment potential for informed decisions.", tag: "Highlight", img: money_icon},
	//{id: 8, title: "Highlight Seasonal Advantages", desc: "Describe seasonal benefits of a property.", tag: "Highlight", img: sun_icon},
	//{id: 9, title: "Highlight Sustainable Features", desc: "Appeal to environmentally-conscious buyers.", tag: "Highlight", img: leaf_icon},
	//{id: 10, title: "Highlight Property Usages", desc: "Expand market potential with property usage options.", tag: "Highlight", img: group_icon},
	//{id: 11, title: "Highlight Renovation Impact", desc: "Help clients envision renovation benefits", tag: "Highlight", img: build_icon},
	{id: 12, title: "Explain Closing Process", desc: "Reduce client stress by explaining the closing process.", tag: "Explain", img: handshake_icon},
	{id: 13, title: "Explain Industry Jargon", desc: "Ensure clients understand complex industry terms.", tag: "Explain", img: book_icon},
	{id: 14, title: "Explain Lease Agreements", desc: "Foster better tenant-landlord relationships.", tag: "Explain", img: sign_icon},
];

// Helper Functional Component
function LayoutPresets(props) {
	return (
		<>
			<div className="dash-result-d flex">
				<h2>Presets</h2>
				<div className="dash-result-num">
					<h3>{props.presets.length || "0"}</h3>
				</div>
			</div>
			<div className="ai-tools-card-cont">
				{
					props.presets.map((preset, i) => {
						return (
							<button className="ai-tools-card" key={`tk-${i}`} value={preset.id} onClick={props.handleOpen}>
								<div className="ai-tools-card-top flex">
									<img src={preset.img} alt="" />
									<div className={`tag ${preset.tag.slice(0,2).toLowerCase()}`}><h3>{preset.tag}</h3></div>
								</div>
								<h2>{preset.title}</h2>
								<h3>{preset.desc}</h3>
							</button>
						)
					})
				}
			</div>
		</>
	);
}


// Functional Component
function Controller(_props) {
	setTitleText("Compose");

	// Page states
	const [page, setPage] = useState(0);
    const [stage, setStage] = useState(0); // 0 => Input info, 1 => Submit
	function goHome() { setPage(0) }
	function goNext() { setStage(1) }
	function goBack() { setStage(0) }
	const [currPreset, setCurrPreset] = useState(null);
	const [searchInput, setSearchInput] = useState("");

	// Handle open preset
	function handleOpenPreset(e) {
		try { 
			const id =  parseInt(e.currentTarget.value);
			setCurrPreset(presets.filter(p => p.id === id)[0]);
			setPage(id + 1);
			window.scrollTo(0, 0); // Scroll to Top
		} catch (err) {}
	}

	// Handle open request feature
	function handleOpenRequest() {
		setPage(0.1);
	}

	// Return layout
	return (
		<>
			{(page === 0) &&
				<>
					<div className="tb-cont">
						<div className="tb-top">
							<div className="iflex">
								<img className="tb-t-icon" src={feather_orange} alt="" />
								<h1 className="pg-t">Compose</h1>
							</div>
							<div className="lst-dsh-tools iflex">
								<label>
									<img src={search_icon} alt="" />
									<input placeholder="Search templates..." onChange={(e)=>{setSearchInput(e.target.value)}} />
								</label>
								{ /* <Select isClearable={true} options={didLoad ? categories : null} onChange={(e)=>{setFilterCategories(e.map(option => option.value))}} disabled={didLoad ? ((categories.length > 0) ? false : true) : true} isMulti={true} styles={selectStyle} placeholder="All Categories"/> */}
								<button className="lst-mng-leads-b sort" onClick={handleOpenRequest}><img src={request_icon} alt="" />Request</button>
							</div>
						</div>
					</div>
					<div className="dash-cont">
						<LayoutPresets {...{
							presets: presets.filter((t) => t.title.toLowerCase().includes(searchInput.toLowerCase())),
							handleOpen: handleOpenPreset	
						}} />
					</div>
				</>
			}
			{(page === 0.1) && <ComposeRequest goHome={goHome} /> }
			{(page >= 1) &&
				<div className="pop-pg-cont">
					<button className="lead-prof-back-b" onClick={()=>{if (stage === 0) goHome(); else goBack();}}>
						<img src={back_icon} alt="" />
					</button>
					<div className="ai-tool-t-d flex">
						<div className="flex">
							<img src={currPreset.img} alt="" />
							<h1>{currPreset.title}</h1>
						</div>
						<div className={`tag ${currPreset.tag.slice(0,2).toLowerCase()}`}>
							<h3>{currPreset.tag}</h3>
						</div>
					</div>
					<div className="content-spacer ai-tool" />
					{(page === 1) && <Create_ListingDesc {...{stage: stage}} />}
					{(page === 2) && <Create_OpenHouseInv {...{stage: stage}} />}
					{(page === 3) && <Create_NeighbourDesc {...{stage: stage}} />}
					{(page === 4) && <Enhance_ListingDesc {...{stage: stage}} />}
					{(page === 5) && <Enhance_PersonalizeDesc {...{stage: stage}} />}
					{(page === 6) && <Highlight_PropertyHighs {...{stage: stage}} />}
					{(page === 7) && <Highlight_PropertyComps {...{stage: stage}} />}
					{(page === 8) && <Highlight_InvestBenefits {...{stage: stage}} />}
					{(page === 13) && <Explain_Closing {...{stage: stage}} />}
					{(page === 14) && <Explain_Jargon {...{stage: stage}} />}
					{(page === 15) && <Explain_LeaseAgr {...{stage: stage}} />}
					{(stage === 0) &&
						<div className="dash-cont-b-d iflex">
							<button className="next" disabled={false} onClick={goNext}>Next -&gt;</button>
						</div>
					}
				</div>
			}
		</>
	);
}

export default Controller;