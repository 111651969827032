// API Imports
import { logoutUser } from "../../../apicalls/auth.js";

// Util Imports
import { formatPhoneNumber } from "../../../utils/formatPhone.js";
import { useError } from '../../ui/Context/ErrorContext';

// PNG Imports
import logo_hspot from "../../../img/png/integrations/hubspot.png";
import logo_smate from "../../../img/png/integrations/salesmate.png";
import logo_gsheets from "../../../img/png/integrations/gsheets.png";
import logo_gmail from "../../../img/png/integrations/gmail.png";
import logo_mchimp from "../../../img/png/integrations/mailchimp.png";
import logo_twil from "../../../img/png/integrations/twilio.png";
import logo_pipe from "../../../img/png/integrations/pipedrive.png";
import logo_zap from "../../../img/png/integrations/zapier.png";

// SVG Imports
import email_icon from "../../../img/svg/listing/email_orange.svg";
import building_icon from "../../../img/svg/profile/building_orange.svg";


// Functional Component
function Profile(props) {
	const { showError } = useError();

	// Logout user
	async function handleLogout() {
		if (await logoutUser()) window.location.reload(false);
		else showError('An unexpected error occurred.');
	}

	// Copy API key to clipboard
	function copyApiKey() {
		navigator.clipboard.writeText(props.profileData.api_key || "").then(function() {
			// TODO: Handle success
		}, function(err) {
			// TODO: Handle error
			showError('An unexpected error occurred.');
		});
	}

	// Return layout
	return (
		<>
			<div className="lst-new-i-d">
				<div className="set-card-d flex">
					<div className={"l" + ((props.profileData.org && props.profileData.org.length > 0) ? "" : " sm")}>
						{(props.profileData.img && props.profileData.img.length > 0) ? 
							<img src={props.profileData.img} alt="" />
							:
							<div className="pfp-plh flex">
								<h1>{(props.profileData.name && props.profileData.surname) ? `${props.profileData.name.charAt(0)}${props.profileData.surname.charAt(0)}` : ""}</h1>
							</div>
						}
					</div>
					<div className="r">
						<h2>{(props.profileData.name && props.profileData.surname) ? `${props.profileData.name} ${props.profileData.surname}` : ""}</h2>
						<div className="flex">
							<img src={email_icon} alt="" />
							<h3>{props.profileData.email || ""}</h3>
						</div>
						{(props.profileData.org) &&
							<div className="flex">
								<img src={building_icon} alt="" />
								<h3>{props.profileData.org}</h3>
							</div>
						}
					</div>
				</div>
			</div>
			<div className="lst-new-i-d">
				<h2>Credentials</h2>
				<h3 className="set-int-hs">Manage credentials for applicable integrated platforms.</h3>
				<div className="set-int-b-cont">
					<button className="set-int-b" onClick={(_)=>{props.setSelIntg("hubspot"); props.setPage(0.3);}}>
						<img src={logo_hspot} />
						<h2>Hubspot</h2>
					</button>
					<button className="set-int-b" onClick={(_)=>{props.setSelIntg("mailchimp"); props.setPage(0.3);}}>
						<img src={logo_mchimp} />
						<h2>Mailchimp</h2>
					</button>
					<button className="set-int-b" onClick={(_)=>{props.setSelIntg("pipedrive"); props.setPage(0.3);}}>
						<img src={logo_pipe} />
						<h2>Pipedrive</h2>
					</button>
					{/*
						<button className="set-int-b" onClick={(_)=>{props.setSelIntg("zapier"); props.setPage(0.3);}}>
							<img src={logo_zap} />
							<h2>Zapier</h2>
						</button>
					*/}
				</div>
			</div>
			<div className="lst-new-i-d">
				<h2>API Key</h2>
				<h3 className="set-int-hs">Keep your API key secure, as it can be used to access your ListingOpen data.</h3>
				<div className="iflex">
					<div className="set-prof-api-d iflex"><h3>{props.profileData.api_key || ""}</h3></div>
					<button className="set-prof-api-b" onClick={copyApiKey}>Copy</button>
				</div>
			</div>
			<div className="lst-new-i-d">
				<h2>Actions</h2>
				<div className="set-prof-b-d iflex">
					<div>
						<h2>Change Password</h2>
						<h3>Update your current password.</h3>
					</div>
					<button onClick={()=>{props.setPage(0.1)}}>Change Password</button>
				</div>
				<div className="set-prof-b-d iflex">
					<div>
						<h2>Sign Out</h2>
						<h3>Log out of your account.</h3>
					</div>
					<button onClick={handleLogout}>Sign Out</button>
				</div>
				<div className="set-prof-b-d iflex">
					<div>
						<h2>Delete Account</h2>
						<h3>This action cannot be undone.</h3>
					</div>
					<button onClick={()=>{props.setPage(0.2)}} className="del">Delete</button>
				</div>
			</div>
		</>
	);
}

export default Profile