import React, { useEffect, useState } from 'react';

// Functional Component
function Typewriter ({ type="default", text, loading, currIndex, setIndexTyped }) {
  const [idx, setIdx] = useState(0);

  useEffect(() => {
    if (loading) return;

    const timeout = setTimeout(() => {
      setIdx((i) => (i < text.length ? i + 1 : i));
    }, 20);
    
    return () => {
      clearTimeout(timeout);
      if (!loading) setIndexTyped(currIndex);
    }
  }, [idx, text, loading]);

  useEffect(() => {
    if (!loading) setIdx(0);
  }, [text, loading]);

  return (
    <h3>
      {!loading && text.slice(0, idx)}
      {(idx < text.length || loading) && <span className={"cursor"+((type==="enhance") ? " enhance" : "")} />}
    </h3>
  );
};

export default Typewriter;
