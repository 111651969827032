// SVG Imports
import list_template_icon from "../img/svg/automations/list_template_small.svg";
import intg_pack_icon from "../img/svg/automations/intg_pack_small.svg";
import si_preset_icon from "../img/svg/automations/si_preset_small.svg";
import em_template_icon from "../img/svg/automations/em_template_small.svg";

// PNG Imports
import em_template from "../img/png/automations/em_template.png";
import list_template from "../img/png/automations/list_template.png";
import int_pack from "../img/png/automations/int_pack.png";
import si_preset from "../img/png/automations/si_preset.png";


export function convertToBackendFormat_integrations(integrations) {
	return integrations.map((i) => {
		return {type: i.type, t1: i.target_1, t2: i.target_2 || null, t3: i.target_3 || null};
	});
}

export function convertToBackendFormat_questions(questions) {
	return questions.map((q) => {
		var formatted = {type: q.type, text: q.text, order: q.order};
		if (q.options) {
			q.options.forEach((opt, i) => {
				formatted[`opt${i+1}`] = opt;
			});
		}
		return formatted;
	});
}

export function getAutomationSmallIcon(type) {
	switch (type) {
		case 0:
			return <div className="atms-icon-sm lt"><img src={list_template_icon} alt="" /></div>;
		case 1:
			return <div className="atms-icon-sm ip"><img src={intg_pack_icon} alt="" /></div>;
		case 2:
			return <div className="atms-icon-sm sp"><img src={si_preset_icon} alt="" /></div>;
		case 3:
			return <div className="atms-icon-sm em"><img src={em_template_icon} alt="" /></div>;
		default:
			return <></>;
	}
}

export function formatBackendIntegrations(intg) {
    return intg.map((i) => {
        return {type: i.type, target_1: i.t1, target_2: i.t2 || null, target_3: i.t3 || null}
    });
}

export function formatBackendQuestions(quest) {
    return quest.map((q) => {
        var t = {order: q.order, text: q.text, type: q.type};
        if (q["opt_1"]) t["options"] = [q["opt_1"]];
        for (var i=1; i<10; i++) {
            if (q[`opt_${i+1}`]) t["options"].push(q[`opt_${i+1}`]);
            else break;
        }
        return t;
    });
}

export function getLogoForType(type) {
    switch (type) {
        case "listing":
            return list_template;
        case "integrations":
            return int_pack;
        case "questions":
            return si_preset;
        case "email":
            return em_template;
        default:
            return null;
    }
}

export function getDescForType(type) {
    switch(type) {
        case "listing":
            return "Listing Template";
        case "questions":
            return "Sign-In Preset";
        case "integrations":
            return "Integration Package";
        case "email":
            return "Email Template";
        default:
            return "";
    }
}