// Dependencies
import { useNavigate } from "react-router-dom";

// API Imports
import { createEmailTemplate } from "../../../../apicalls/automations.js";
import uploadPhoto from "../../../../utils/uploadPhoto.js";

// Util Imports
import { useError } from '../../../ui/Context/ErrorContext';
import { useUpgrade } from "../../../ui/Context/UpgradeContext";

// PNG Imports
import em_template from "../../../../img/png/automations/em_template.png";


// Helper Functions
function replaceWithExamples(str) {
	return str.replaceAll('{name}', 'John Doe').replaceAll('{address}', '123 Main Street');
}


// Functional Component
function EmailReview(props) {
	const navigate = useNavigate();
	const { showError } = useError();
    const { showUpgrade } = useUpgrade();

	// Handle creating email template
	async function handleFinish() {		
        // 0. Upload Photos to S3
		var imageId = null;
        if (props.profileBlob) imageId = await uploadPhoto(props.profileBlob);

        // 1. Create template
		const resp = await createEmailTemplate(props.automationName, props.subjectText, props.headingText, props.bodyText, props.closingText, props.contactName, props.contactText, imageId);
		switch (resp.status) {
			case 200:
				navigate(`/automations`,{replace:true});
				break;
			case 401:
				showUpgrade('Automations');
				break;
			default:
				showError('An unexpected error occurred.')
				break;
		}
	}

	// Return layout
	return (
		<>
			<div className="lst-new-i-d">
				<h2>Give your automation a name!</h2>
				<input className="atms-name-in" placeholder="e.g. My Email Template" onChange={(e) => {props.setAutomationName(e.target.value)}} value={props.automationName || ""}></input>
				<button className="atms-card-b sel rvw">
					<img className="atms-card-img" src={em_template} alt="" />
					<div className="atms-card-id">
						<h1>{props.automationName || "My Email Template"}</h1>
						<h3>Integration Package</h3>
					</div>
				</button>
			</div>
			<div className="lst-new-i-d mb0">
				<h2>Preview</h2>
				<div className="atm-em-prvw-sbj">
					<h3>{replaceWithExamples(props.subjectText)}</h3>
				</div>
				<div className="atm-em-prvw-d-cont">
					<div className="atm-em-prvw-d">
						<div className="prop-i" />
						<h2>{replaceWithExamples(props.headingText)}</h2>
						<h3>{replaceWithExamples(props.bodyText)}</h3>
						<h3>{replaceWithExamples(props.closingText)}</h3>
						{(props.profileUrl) && <img className="contact-i" src={props.profileUrl} alt="" />}
						<h2 className="nm">{props.contactName}</h2>
						<h3>{props.contactText}</h3>
					</div>
				</div>
			</div>
			<div className="dash-cont-b-d iflex">
				<button className="back" onClick={props.goBack}>&lt;- Back</button>
				<button
					className={"finish"+((!props.automationName || props.automationName.length === 0) ? " hidden" : "")}
					disabled={(!props.automationName || props.automationName.length === 0)}
					onClick={handleFinish}
				>Create Template</button>
			</div>
		</>
	);
}

export default EmailReview;