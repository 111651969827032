// PNG Imports
import logo_pster from "../img/png/integrations/placester.png";
import logo_tb from "../img/png/integrations/totalbrokerage.png";
import logo_hspot from "../img/png/integrations/hubspot.png";
//import logo_zil from "../img/png/integrations/zillow.png";
import logo_smate from "../img/png/integrations/salesmate.png";
import logo_gsheets from "../img/png/integrations/gsheets.png";
import logo_gmail from "../img/png/integrations/gmail.png";
import logo_mchimp from "../img/png/integrations/mailchimp.png";
import logo_twil from "../img/png/integrations/twilio.png";
import logo_pipe from "../img/png/integrations/pipedrive.png";
import logo_zap from "../img/png/integrations/zapier.png";

// Get logo image based on type
export function getIntegrationLogo(type) {
	switch (type) {
		case "placester":
			return logo_pster;
		case "tb":
			return logo_tb;
		case "hubspot":
			return logo_hspot;
		case "salesmate":
			return logo_smate;
		case "gsheets":
			return logo_gsheets;
		case "gmail":
			return logo_gmail;
		case "mailchimp":
			return logo_mchimp;
		case "twilio":
			return logo_twil;
		case "pipedrive":
			return logo_pipe;
		case "zapier":
			return logo_zap;
		default:
			return null;
	}
}

// Get title based on type
export function getIntegrationTitle(type) {
	switch (type) {
		case "placester":
			return "Placester";
		case "tb":
			return "Total Brokerage";
		case "hubspot":
			return "Hubspot";
		case "salesmate":
			return "Salesmate";
		case "gsheets":
			return "Google Sheets";
		case "gmail":
			return "Gmail";
		case "mailchimp":
			return "Mailchimp";
		case "twilio":
			return "Twilio";
		case "pipedrive":
			return "Pipedrive";
		case "zapier":
			return "Zapier";
		default:
			return "";
	}
}

export function getIntegrationSubtitle(type) {
	switch (type) {
		case "placester":
			return "Add new visitors to Leads";
		case "tb":
			return "Add new visitors to Leads";
		case "hubspot":
			return "Add new visitors to Contacts";
		case "salesmate":
			return "Create contact for new visitors";
		case "gsheets":
			return "Add new visitors to Spreadsheet";
		case "gmail":
			return "Auto-email new visitor information";
		case "mailchimp":
			return "Add new visitors to Subscribers";
		case "twilio":
			return "Add new visitors to Audience";
		case "pipedrive":
			return "Add new visitors to Leads";
		case "zapier":
			return "Create your own Zap";
		default:
			return "";
	}
}

export function getIntegrationDescription(type) {
	switch (type) {
		case "placester":
			return "Add new visitors to Leads.";
		case "tb":
			return "Add new visitors to Leads.";
		case "hubspot":
			return "Add new visitors to Contacts.";
		case "salesmate":
			return "Create contact for new visitors.";
		case "gsheets":
			return "Add new visitors to Spreadsheet.";
		case "gmail":
			return "Auto-email new visitor information.";
		case "mailchimp":
			return "Subscribe new visitors to a MailChimp Audience.";
		case "twilio":
			return "Add new visitors to Audience.";
		case "pipedrive":
			return "Create contact, lead, and note for new visitors.";
		case "zapier":
			return "Create your own ListingOpen-integrated Zap.";
		default:
			return "";
	}
}

// Title & subtitle based on type
export function getIntegrationTitleDiv(type) {
	return (
		<>
			<h1 className="lead-prof-nm">{getIntegrationTitle(type)} Integration</h1>
			<h2 className="intg-mng-st">{getIntegrationSubtitle(type)}</h2>
		</>
	);
}

// Get button based on type
export function getIntegrationButtonBody(type) {
    switch (type) {
        case "placester":
            return <><img className="intg-card-img" src={logo_pster} alt="" /><div className="intg-card-id"><h1>Placester</h1><h3>Add new visitors to Leads</h3></div></>;
        case "tb":
            return <><img className="intg-card-img" src={logo_tb} alt="" /><div className="intg-card-id"><h1>Total Brokerage</h1><h3>Add new visitors to Leads</h3></div></>; 
        case "hubspot":
            return <><img className="intg-card-img" src={logo_hspot} alt="" /><div className="intg-card-id"><h1>Hubspot</h1><h3>Add new visitors to Contacts</h3></div></>;
        case "salesmate":
            return <><img className="intg-card-img" src={logo_smate} alt="" /><div className="intg-card-id"><h1>Salesmate</h1><h3>Create contact for new visitors</h3></div></>; 
        case "gsheets":
            return <><img className="intg-card-img" src={logo_gsheets} alt="" /><div className="intg-card-id"><h1>Google Sheets</h1><h3>Add new visitors to Spreadsheet</h3></div></>;
        case "gmail":
            return <><img className="intg-card-img" src={logo_gmail} alt="" /><div className="intg-card-id"><h1>Gmail</h1><h3>Auto-email new visitor information</h3></div></>;
        case "mailchimp":
            return <><img className="intg-card-img" src={logo_mchimp} alt="" /><div className="intg-card-id"><h1>Mailchimp</h1><h3>Add new visitors to Subscribers</h3></div></>; 
        case "twilio":
            return <><img className="intg-card-img" src={logo_twil} alt="" /><div className="intg-card-id"><h1>Twilio</h1><h3>Add new visitors to Audience</h3></div></>;    
        case "pipedrive":
			return <><img className="intg-card-img" src={logo_pipe} alt="" /><div className="intg-card-id"><h1>Pipedrive</h1><h3>Add new visitors to Leads</h3></div></>;
		case "zapier":
			return <><img className="intg-card-img" src={logo_zap} alt="" /><div className="intg-card-id"><h1>Zapier</h1><h3>Create your own Zap</h3></div></>;
		default:
            return <></>;
    }
}

// Get entry fields
// Need get/set state hooks for target 1 & 2
export function getIntegrationFields(type, t1_hook, set_t1_hook, t2_hook, set_t2_hook) {
	switch (type) {
		case "hubspot":
			return(
				<>
					<div className="lst-new-i-d mb0">
						<h2>Private App Access Token</h2>
						<h3 className="intg-mng-hs">Go to "Settings" -&gt; "Integrations" -&gt; "Private Apps" -&gt; "Create". Under "Scopes", search "crm.objects.contacts" and enable "Write". Create the Private App to retrieve the Access Token.</h3>
						<input className="intg-mng-in" placeholder="e.g. pat-na1-c9us90zy-ad91-9924-25fd-sj62cxrbouyn" onChange={(e)=>{set_t1_hook(e.target.value)}} value={t1_hook || ""} />
					</div>
				</>
			);
		case "mailchimp":
			return(
				<>
					<div className="lst-new-i-d">
						<h2>API Key</h2>
						<h3 className="intg-mng-hs">Can be found in "Profile" -&gt; "Extras" -&gt; "API keys" -&gt; "Create A Key".</h3>
						<input className="intg-mng-in" placeholder="e.g. 09bb47fed8fc36decfc4e8bbcdca37" onChange={(e)=>{set_t1_hook(e.target.value)}} value={t1_hook || ""} />
					</div>
					<div className="lst-new-i-d mb0">
						<h2>Audience ID</h2>
						<h3 className="intg-mng-hs">Can be found in "Audience" -&gt; "Settings" -&gt; "Audience name and campaign defaults".</h3>
						<input className="intg-mng-in" placeholder="e.g. 7695882c5b" onChange={(e)=>{set_t2_hook(e.target.value)}} value={t2_hook || ""} />
					</div>
				</>
			);
		case "pipedrive":
			return(
				<>
					<div className="lst-new-i-d mb0">
						<h2>API Token</h2>
						<h3 className="intg-mng-hs">Can be found in "My Account" -&gt; "Personal Preferences" -&gt; API -&gt; "Your personal API token".</h3>
						<input className="intg-mng-in" placeholder="e.g. w7R4pK3zT9ySvA1D6oQ8qL0hFxU2gM5jE1vY3cH4" onChange={(e)=>{set_t1_hook(e.target.value)}} value={t1_hook || ""} />
					</div>
				</>
			);
		case "gsheets":
			return(
				<>
					<div className="lst-new-i-d">
						<h2>Google Sheets ID</h2>
						<input className="intg-mng-in" placeholder="e.g. 3A9ZKlRs7Xz1GDa2vQhIy8PWS6btHO5E6O20G-m8Rh2d" onChange={(e)=>{set_t1_hook(e.target.value)}} value={t1_hook || ""} />
					</div>
					<div className="lst-new-i-d mb0">
						<h2>Sheet Name</h2>
						<input className="intg-mng-in" placeholder="e.g. My Leads Sheet" onChange={(e)=>{set_t2_hook(e.target.value)}} value={t2_hook || ""} />
					</div>
				</>
			);
		case "zapier":
			return <></>;
		case "placester":
			return <></>;
		case "tb":
			return <></>;
		case "salesmate":
			return <></>;
		case "gmail":
			return <></>;
		case "twilio":
			return <></>;
		default:
			return (
				<>
					<div className="lst-new-i-d mb0">
						<h2>Action</h2>
						<h3 className="intg-mng-hs">New visitors will be added to...</h3>
						<input className="intg-mng-in" />
					</div>
				</>
			);
	}
}