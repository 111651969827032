// Dependencies
import { useState, useEffect } from 'react';

// API Imports
import {getIntegrationWithId, handleUpdateIntegrationWithId, handleDeleteIntegrationWithId } from "../../../../apicalls/integrations.js";

// Util Imports
import { getIntegrationFields, getIntegrationTitle, getIntegrationDescription, getIntegrationLogo } from '../../../../utils/sharedIntegrationFuns.js';

// SVG Imports
import back_icon from "../../../../img/svg/listing/back_chevron.svg";
import house_icon from "../../../../img/svg/listing/house_small.svg";


// Functional Component
function UpdateIntegration(props) {
    const [data, setData] = useState(null);
    const [t1, setT1] = useState(null);
    const [t2, setT2] = useState(null);
    const [t1_og, setT1_og] = useState(null);
    const [t2_og, setT2_og] = useState(null);

    // Delete wrapper
    async function handleDelete() {
        const resp = await handleDeleteIntegrationWithId(props.listing_code, props.id);
        if (!resp) {
            // TODO: Handle failed delete
        } else {
            // Success
            props.goBack();
        }
    }

    // Update wrapper
    async function handleUpdate() {
        const resp = await handleUpdateIntegrationWithId(props.listing_code, props.id, t1, t2);
        if (!resp) {
            // TODO: Handle failed update
        } else {
            setT1_og(t1);
            setT2_og(t2);
        }
    }

    // Load details
    useEffect(()=>{
        async function loadDetails() {
            const resp = await getIntegrationWithId(props.listing_code, props.id);
            if (resp.status !== 200) {
                // TODO: Handle failed fetch
            } else {
                const data = await resp.json();
                setT1(data.data.target_1); setT1_og(data.data.target_1);
                setT2(data.data.target_2); setT2_og(data.data.target_2);
                setData(data.data);
            }
        }
        loadDetails();
    }, []);

    // Return layout 
    return (
        <div className="lst-mng-lead-prof-cont">
            {(data) &&
                <>
                    <div className="intg-mng-t-d flex">
                        <img className="intg-mng-img" src={getIntegrationLogo(data.type)} alt="" />
                        <div className="intg-mng-t-id mauto">
                            <h1 className="lead-prof-nm">Create {getIntegrationTitle(data.type)} Integration</h1>
                            <h3 className="intg-mng-hs">{getIntegrationDescription(data.type)}</h3>
                        </div>
                    </div>
                    <div className="content-spacer" />
                    <div className="lst-new-i-d">
                        <h2>Trigger</h2>
                        <h3 className="intg-mng-hs">Action will be taken when a visitor signs in at...</h3>
                        <div className="intg-mng-trigger-d iflex">
                            <img src={house_icon} alt="" />
                            <h3>{props.listing_name}</h3>
                        </div>                
                    </div>
                    { getIntegrationFields(data.type, t1, setT1, t2, setT2) }
                    <div className="lst-new-i-d mt30 mb0">
                        <h2>Settings</h2>
                        <div className="lead-prof-b-d iflex">
                            <div>
                                <h2>Remove Integration</h2>
                                <h3>This action cannot be undone.</h3>
                            </div>
                            <button onClick={handleDelete}>Remove</button>
                        </div>
                    </div>
                    <div className="dash-cont-b-d iflex">
                        <button className="back" onClick={props.goBack}>&lt;- Back</button>
                        <button
                            className={"finish" + ((t1 === t1_og && t2 === t2_og) ? " hidden" : "")}
                            disabled={(t1 === t1_og && t2 === t2_og)}
                            onClick={handleUpdate}>Save & Publish</button>
                    </div>
                </>
            }
        </div>
    );
}

export default UpdateIntegration;
