// Dependencies
import { useState } from 'react';

// Functional Component
export function AI_Single_Select({
    val, setVal, allValues,
    title, description="",
    includeOther=false, otherTitle="", otherDesc="", otherPlaceholder="",
    last=false
}) {
    const [other, setOther] = useState(false);

    // Return layout
    return (
        <div className={"ai-tool-input" + (last ? " mb0" : "")}>
            <h2>{title}</h2>
            {(description && description.length > 0) && <h3>{description}</h3> }
            <div className="ai-tool-b-d">
                {
                    allValues.map((v, index) => {
                        return (
                            <button
                                key={`ais-${index}`}
                                className={"" + ((v === val) ? " sel" : ((val !== null) ? " unsel" : ""))}
                                value={index}
                                onClick={(e) => {
                                    const cv = allValues[e.currentTarget.value];
                                    setOther(false);
                                    if (val === cv) setVal(null);
                                    else setVal(cv);
                                }}
                            >
                                <div className={"ai-tool-b-c" + ((v === val) ? " sel" : " unsel")}>
                                    <div />
                                </div>
                                <h3>{v}</h3>
                            </button>
                        );
                    })
                }
                {(includeOther) &&
                    <button
                        key="ais-othr"
                        className={"" + ((other) ? " sel" : ((val !== null) ? " unsel" : ""))}
                        onClick={(_) => { setVal(null); setOther(!other); }}
                    >
                        <div className={"ai-tool-b-c" + ((other) ? " sel" : " unsel")}>
                            <div />
                        </div>
                        <h3>Other</h3>
                    </button>
                }
            </div>
            {(includeOther && other) &&
                <>
                    <h2 className="ai-tool-h-othr">{otherTitle}</h2>
                    {(otherDesc && otherDesc.length > 0) && <h3>{otherDesc}</h3>}
                    <input
                        value={val}
                        onChange={(e)=>{ setVal(e.currentTarget.value)}}
                        placeholder={otherPlaceholder}
                    />
                </>
            }
        </div>
    );
}

// Exact same but different select mechanism
export function AI_Multi_Select({
    vals, setVals, allValues,
    title, description="",
    includeOther=false, otherTitle="", otherDesc="", otherPlaceholder="",
    last=false
}) {
    const [other, setOther] = useState(false);
    const [otherVal, setOtherVal] = useState("");

    // Return layout
    return (
        <div className={"ai-tool-input" + (last ? " mb0" : "")}>
            <h2>{title}</h2>
            {(description && description.length > 0) && <h3>{description}</h3> }
            <div className="ai-tool-b-d">
                {
                    allValues.map((v, index) => {
                        return (
                            <button
                                key={`ais-${index}`}
                                className={"" + ((vals.includes(v)) ? " sel" : ((vals.length !== 0) ? " unsel" : ""))}
                                value={index}
                                onClick={(e) => {
                                    const cv = allValues[e.currentTarget.value];
                                    const cv_index = vals.indexOf(cv);
                                    if (cv_index !== -1) setVals([...vals].splice(cv_index, 1)); // Remove from selected values
                                    else setVals([...vals, cv]); // Push to selected values
                                }}
                            >
                                <div className={"ai-tool-b-c" + ((vals.includes(v)) ? " sel" : " unsel")}>
                                    <div />
                                </div>
                                <h3>{v}</h3>
                            </button>
                        );
                    })
                }
                {(includeOther) &&
                    <button
                        key="ais-othr"
                        className={"" + ((other) ? " sel" : ((vals.length !== 0) ? " unsel" : ""))}
                        onClick={(_) => { setOther(!other); }}
                    >
                        <div className={"ai-tool-b-c" + ((other) ? " sel" : " unsel")}>
                            <div />
                        </div>
                        <h3>Other</h3>
                    </button>
                }
            </div>
            {(includeOther && other) &&
                <>
                    <h2 className="ai-tool-h-othr">{otherTitle}</h2>
                    {(otherDesc && otherDesc.length > 0) && <h3>{otherDesc}</h3>}
                    <input
                        value={otherVal}
                        onChange={(e)=>{ setOtherVal(e.currentTarget.value) /* TODO */ }}
                        placeholder={otherPlaceholder}
                    />
                </>
            }
        </div>
    );
}