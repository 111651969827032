// Dependencies
import { useState } from 'react';
import Select from 'react-select';

// SVG Imports
import x_icon from "../../img/svg/signin/x_icon.svg";

// Constants
const selectStyle = {
    control: (styles, { isFocused }) => ({
        ...styles,
        borderColor: isFocused ? 'var(--orange-accent)' : styles.borderColor,
        boxShadow: (isFocused) ? '0 0 0 1px var(--orange-accent)' : styles.boxShadow,
        '&:hover': {
            borderColor: 'var(--orange-accent)'
        }
    }),
    option: (provided, state) => ({
        ...provided,
        fontFamily: 'Inter',
        fontSize: '15px',
        backgroundColor: state.isFocused ? 'var(--orange-accent-lite)' : null,
        color: 'black',
        ':active': {
            backgroundColor: 'var(--orange-accent)'
        },
    }),
    noOptionsMessage: (styles) => ({
        ...styles,
        fontSize: '15px',
        fontFamily: 'Inter',
        color: 'var(--grey-4)'
    }),
    singleValue: (styles) => ({
        ...styles,
        fontSize: '15px',
        fontFamily: 'Inter',
        color: 'black'
    })
};
const roleOptions = [
    {value: 1, label: "Manager"},
    {value: 2, label: "Agent"}
];


// Functional Component
function InviteMemberModal({
	orgName,
    handleSubmit, closeModal
}) {
	const [email, setEmail] = useState("");
	const [role, setRole] = useState(2);

	function handleSubmitWrapper() {
		handleSubmit(email, role);
	}

	// Return layout
	return (
		<div className="set-cat-mod-bg">
			<div className="set-cat-mod-cont">
				<div className="set-cat-mod-top iflex">
					<h2>Invite Member</h2>
					<button onClick={closeModal}><img src={x_icon} alt="" /></button>
				</div>
				<div className="set-cat-mod-spacer" />
				<div className="set-cat-mod-btm">
					<h3 className="set-cat-mod-sbt">Email</h3>
					<input
						className="set-cat-mod-in"
						placeholder="e.g. jdoe@dreamrealty.com"
						value={email || ""}
						onChange={(e)=>{setEmail(e.target.value || "")}} />
					<h3 className="set-cat-mod-sbt mt">Role</h3>
					<Select
						options={roleOptions} 
						styles={selectStyle}
						value={roleOptions.find((r) => r.value === role)}
						onChange={(e) => {if (e) setRole(e.value)}}
						isClearable={false} />
					<button
						className={"set-cat-mod-lrg-b"+ ((email.length === 0) ? " hidden" : "")}
						disabled={email.length === 0}
						onClick={handleSubmitWrapper}
					>Add to {orgName}</button>
				</div>
			</div>
		</div>
	);
}

export default InviteMemberModal;