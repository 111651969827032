// Dependencies
import { useState, useEffect } from 'react';

// API Imports
import { handleCreateIntegrationWithCode } from "../../../../apicalls/integrations.js";
import { getCredentialsForType } from "../../../../apicalls/integrations.js";

// SVG Imports
import house_icon from "../../../../img/svg/listing/house_small.svg";

// Util Imports
import { getIntegrationTitle, getIntegrationLogo, getIntegrationDescription, getIntegrationFields } from '../../../../utils/sharedIntegrationFuns.js';
import { useError } from '../../../ui/Context/ErrorContext';


// Functional Component
function CreateIntegration(props) {
    const { showError } = useError();

    // Data states
    const [t1, setT1] = useState(null);
    const [t2, setT2] = useState(null);

    // Create wrapper
    async function handleCreate() {
        const resp = await handleCreateIntegrationWithCode(props.listing_code, props.type, t1, t2);
        if (!resp) {
            // TODO: Handle failed delete
        } else {
            // Success
            props.goBack();
        }
    }

    // Load credentials
    async function loadCreds() {
        const resp = await getCredentialsForType(props.type);
        if (resp.status !== 200) {
            showError('An unexpected error occurred.');
        } else {
            const data = await resp.json();
            setT1(data.target_1);
            setT2(data.target_2 || null);
        }
    }
    useEffect(()=>{ loadCreds(); },[]);

    // Return layout 
    return (
        <div className="lst-mng-lead-prof-cont">
            <div className="intg-mng-t-d flex">
                <img className="intg-mng-img" src={getIntegrationLogo(props.type)} alt="" />
                <div className="intg-mng-t-id mauto">
                    <h1 className="lead-prof-nm">Create {getIntegrationTitle(props.type)} Integration</h1>
                    <h3 className="intg-mng-hs">{getIntegrationDescription(props.type)}</h3>
                </div>
            </div>
            <div className="content-spacer" />
            <div className="lst-new-i-d">
                <h2>Trigger</h2>
                <h3 className="intg-mng-hs">Action will be taken when a visitor signs in at...</h3>
                <div className="intg-mng-trigger-d iflex">
                    <img src={house_icon} alt="" />
                    <h3>{props.listing_name}</h3>
                </div>                
            </div>
            {getIntegrationFields(props.type, t1, setT1, t2, setT2)}
            <div className="dash-cont-b-d iflex">
				<button className="back" onClick={props.goBack}>&lt;- Back</button>
				<button className="finish" onClick={handleCreate}>Save & Publish</button>
			</div>
        </div>
    );
}

export default CreateIntegration;
