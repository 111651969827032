// Dependencies
import { useState } from 'react';

// API Imports
import { api_highlightComparison } from "../../../apicalls/compose.js";

// Component Imports
import AI_Input from '../comp/Input';
import AI_Textbox from "../comp/Textbox";
import AI_Tone from "../comp/Tone";
import FinishedCompose from '../comp/FinishedCompose.jsx';

// Util Imports
import { useError } from '../../ui/Context/ErrorContext';
import { useUpgrade } from '../../ui/Context/UpgradeContext';


// Functional Component
function Highlight_PropertyComps(props) {
    const { showError } = useError();
    const { showUpgrade } = useUpgrade();

    // Data states
    const [addA, setAddA] = useState("");
    const [descA, setDescA] = useState("");
    const [addB, setAddB] = useState("")
    const [descB, setDescB] = useState("");
    const [criteria, setCriteria] = useState("");
    const [addInfo, setAddInfo] = useState("");
    const [tone, setTone] = useState(null);

    // Helper functions
    async function generateCompose() {
        const resp = await api_highlightComparison({
            pa1: addA,
            pa2: addB,
            d1: descA,
            d2: descB,
            cc: criteria,
            ai: addInfo,
        });
        switch (resp.status) {
            case 200:
                const msg = await resp.json();
                return msg.c;
            case 401:
                showUpgrade('Compose');
                return "Compose is a Team feature. Please upgrade your plan to continue.";
            default:
                showError('An unexpected error occurred.');
                return "Sorry, an unexpected error occurred.";
        }
    }

    // Return layout
    return (
        <>
            {(props.stage === 0) &&
                <>
                    <AI_Input val={addA} setVal={setAddA} title="Address of Property #1" placeholder="e.g. 123 Main Street" />
                    <AI_Textbox val={descA} setVal={setDescA} title="Description of Property #1" placeholder="e.g. Detached home featuring a modern kitchen with granite countertops and stainless steel appliances. Open living space with abundant natural light. The backyard garden is perfect for outdoor enthusiasts." />
                    <AI_Input val={addB} setVal={setAddB} title="Address of Property #2" placeholder="e.g. 456 Elm Avenue" />
                    <AI_Textbox val={descB} setVal={setDescB} title="Description of Property #2" placeholder="e.g. Townhouse with hardwood floors and an open-concept living area, allowing for a spacious and airy feel. Enjoy a scenic view with ample natural light. Community amenities include a pool and fitness center. Prime location near schools, shopping, and transit." />
                    <AI_Textbox val={criteria} setVal={setCriteria} title="Comparison Criteria" placeholder="e.g. Price, Lot Size, Neighborhood Amenities" description="What specific features should be compared?" />
                    <AI_Textbox val={addInfo} setVal={setAddInfo} title="Additional Information" description="Any other relevant information you’d like to include in the comparison." />
                    <AI_Tone val={tone} setVal={setTone} last={true} />
                </>
            }
            {(props.stage === 1) && <FinishedCompose {...{generate: generateCompose}} /> }
        </>
    );
}

export default Highlight_PropertyComps;