// Dependencies
import { useState } from 'react';

// API Imports
import { api_personalizeListingDesc } from "../../../apicalls/compose.js";

// Component Imports
import AI_Textbox from "../comp/Textbox";
import AI_Tone from "../comp/Tone";
import FinishedCompose from '../comp/FinishedCompose';

// Util Imports
import { useError } from '../../ui/Context/ErrorContext';
import { useUpgrade } from '../../ui/Context/UpgradeContext';

// Functional Component
function Enhance_PersonalizeDesc(props) {
    const { showError } = useError();
    const { showUpgrade } = useUpgrade();
    
    // Data states
    const [desc, setDesc] = useState("");
    const [notes, setNotes] = useState("");
    const [addInfo, setAddInfo] = useState("");
    const [tone, setTone] = useState(null);

    // Helper functions
    async function generateCompose() {
        const resp = await api_personalizeListingDesc({
            ld: desc,
            bp: notes,
            ai: addInfo,
        });
        switch (resp.status) {
            case 200:
                const msg = await resp.json();
                return msg.c;
            case 401:
                showUpgrade('Compose');
                return "Compose is a Team feature. Please upgrade your plan to continue.";
            default:
                showError('An unexpected error occurred.');
                return "Sorry, an unexpected error occurred.";
        }
    }

    // Return layout
    return (
        <>
            {(props.stage === 0) &&
                <>
                    <AI_Textbox val={desc} setVal={setDesc} title="Listing Description" placeholder="e.g. Nestled in the heart of the charming Willowbrook community, this beautifully maintained 3-bedroom, 2-bathroom home offers a perfect blend of modern comfort and classic charm. The spacious open-concept layout welcomes you with abundant natural light, while the recently renovated kitchen boasts granite countertops and stainless steel appliances. Step outside to the serene backyard oasis, complete with a deck and lush garden, ideal for relaxing and entertaining. Top-rated schools, nearby parks, and convenient shopping are just minutes away." />
                    <AI_Textbox val={notes} setVal={setNotes} title="Buyer Preferences" placeholder="e.g. Family of four looking for a home with modern amenities. They prioritize a safe neighborhood and proximity to good schools for their children. A spacious backyard for their dog and outdoor gatherings is also a key preference." description="Include any notes on the buyer and/or their specific preferences and needs." />
                    <AI_Textbox val={addInfo} setVal={setAddInfo} title="Additional Information" description="Any other relevant information you’d like to include in the personalized description." />
                    <AI_Tone val={tone} setVal={setTone} last={true} />
                </>
            }
            {(props.stage === 1) && <FinishedCompose {...{generate: generateCompose}} /> }
        </>
    );
}

export default Enhance_PersonalizeDesc;