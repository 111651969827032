/*
-------------------
> LEADS API CALLS <
-------------------
*/

const prefix = `${(process.env.REACT_APP_ENV_MODE === "development") ? process.env.REACT_APP_API_URL_DEV : process.env.REACT_APP_API_URL_PROD}/leads`;


export async function getAllLeads() {
    return await fetch(prefix+'/all', {
        method: 'GET', credentials: 'include'
    });
}

export async function getLeadsWithCode(code) {
    return await fetch(prefix+`/for/${code}`, {
        method: 'GET', credentials: 'include'
    });
}

export async function getLeadProfileFor(lead_id) {
    return await fetch(prefix+`/load/${lead_id}`, {
        method: 'GET', credentials: 'include'
    });
}

export async function handleDeleteLeadWithId(lead_id) {
    return await fetch(prefix+`/forget/${lead_id}`, {
        method: 'DELETE', credentials: 'include'
    })
}

export async function loadLeadAnswers_bulk(lead_ids) {
    return await fetch(prefix+'/answers/more', {
        method: 'POST', credentials: 'include',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ ids: lead_ids })
    });
}

export async function loadLeadQuestions_bulk(lead_ids) {
    return await fetch(prefix+'/questions/more', {
        method: 'POST', credentials: 'include',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ ids: lead_ids })
    });
}