// Dependencies
import { useState } from 'react';

// API Imports
import { choosePlan } from "../../../apicalls/billing.js";

// Util Imports
import setTitleText from "../../../utils/setTitleText.js";

// SVG Imports
import su_logo from "../../../img/svg/signup/su_logo.svg";
import standard_icon from "../../../img/svg/signup/standard_plan.svg";
import premium_icon from "../../../img/svg/signup/premium_plan.svg";
import enterprise_icon from "../../../img/svg/signup/enterprise_plan.svg";
import check_icon from "../../../img/svg/signup/check_icon.svg";
import x_icon from "../../../img/svg/signup/x_icon.svg";

// PNG Imports
import signup_bg from "../../../img/png/signup/signup_bg_12.png";


// Functional Component
function ChoosePlan() {
    setTitleText("Choose a plan");
    
    // Page states
    const [page, setPage] = useState(0);

    // Data states
    const [plan, setPlan] = useState(0);
    const [billFreq, setBillFreq] = useState(0);

    // Handle creation of user
    async function handleContinue() {
        if (plan === 2) {
            // Contact Us
            window.open("https://listingopen.com/brokerage","_blank");
            return;
        }
        if (page === 0) return setPage(1);

        const resp = await choosePlan((plan === 0) ? "b" : "p", (billFreq === 0) ? "mo" : "an");
        if (resp.status !== 200) {
            // TODO: Handle error
        } else {
            const data = await resp.json();
            window.open(data.url, '_self');
        }
    }

    // Return layout
    return(
        <>
            <div className="su-bg">
                <img src={signup_bg} alt="" />
            </div>
            <div className="su-cont">
                <img src={su_logo} alt="" />
                {(page === 0) &&
                    <>
                        <div className="top bl">
                            <h1>Now, choose your plan.</h1>
                        </div>
                        <div className="in">
                            <div className="di plans">
                                <div className={"flex" + ((plan === 0) ? " sel" : ((plan !== -1) ? " hidden" : ""))} onClick={(_)=>{setPlan(0)}}><img src={standard_icon} alt="" /><h2>Agent</h2></div>
                                <div className={"flex" + ((plan === 1) ? " sel" : ((plan !== -1) ? " hidden" : ""))} onClick={(_)=>{setPlan(1)}}><img src={premium_icon} alt="" /><h2>Team</h2></div>
                                <div className={"flex" + ((plan === 2) ? " sel" : ((plan !== -1) ? " hidden" : ""))} onClick={(_)=>{setPlan(2)}}><img src={enterprise_icon} alt="" /><h2>Brokerage</h2></div>
                            </div>
                            <div className="di price flex">
                                <h2>Price</h2>
                                {(plan === -1) && <h3>Select a Plan</h3>}
                                {(plan === 0) && <h3 className="orange">$32 / mo.</h3>}
                                {(plan === 1) && <h3 className="orange">$115 / mo.</h3>}
                                {(plan === 2) && <h3 className="orange">Contact Us</h3>}
                            </div>
                            <div className="di mb0">
                                <h2>Features</h2>
                                <div className={"ft mt" + ((plan < 0) ? " hidden" : "")}>
                                    <div className="flex"><img src={(plan < 0) ? x_icon : check_icon} alt="" /><h3>Unlimited Listings</h3></div>
                                    <div className="flex"><img src={(plan < 0) ? x_icon : check_icon} alt="" /><h3>Custom Follow-Up Emails</h3></div>
                                    <div className="flex"><img src={(plan < 0) ? x_icon : check_icon} alt="" /><h3>Custom Sign-In Design</h3></div>
                                    <div className="flex"><img src={(plan < 0) ? x_icon : check_icon} alt="" /><h3>Unlimited Automations</h3></div>
                                    <div className="flex"><img src={(plan < 0) ? x_icon : check_icon} alt="" /><h3>Unlimited Integrations</h3></div>
                                    <div className="flex"><img src={(plan < 0) ? x_icon : check_icon} alt="" /><h3>AI Tools</h3></div>
                                </div>
                                <div className={"ft" + ((plan < 1) ? " hidden" : "")}>
                                    <div className="flex"><img src={(plan < 1) ? x_icon : check_icon} alt="" /><h3>5 Team Seats</h3></div>
                                    <div className="flex"><img src={(plan < 1) ? x_icon : check_icon} alt="" /><h3>Organization Account</h3></div>
                                    <div className="flex"><img src={(plan < 1) ? x_icon : check_icon} alt="" /><h3>Migration Support</h3></div>
                                    <div className="flex"><img src={(plan < 1) ? x_icon : check_icon} alt="" /><h3>Unlimited Seats for $7/mo.</h3></div>
                                </div>
                                <div className={"ft" + ((plan < 2) ? " hidden" : "")}>
                                    <div className="flex"><img src={(plan < 2) ? x_icon : check_icon} alt="" /><h3>Custom Email Branding</h3></div>
                                    <div className="flex"><img src={(plan < 2) ? x_icon : check_icon} alt="" /><h3>Custom Sign-In Branding</h3></div>
                                    <div className="flex"><img src={(plan < 2) ? x_icon : check_icon} alt="" /><h3>Dedicated Support</h3></div>
                                    <div className="flex"><img src={(plan < 2) ? x_icon : check_icon} alt="" /><h3>Custom Workflows</h3></div>
                                </div>
                            </div>
                        </div>
                    </>
                }
                {(page === 1) &&
                    <>
                        <div className="top bl">
                            <h1>How often do you want to get billed?</h1>
                        </div>
                        <div className="in">
                            <div className="di bill">
                                <h3>Save up to 20% with annual billing.</h3>
                                <div className={"flex" + ((billFreq === 0) ? " sel" : "")} onClick={(_)=>{setBillFreq(0)}}><h2>Every Month</h2><h3>${(plan === 1) ? "115 / mo." : "32 / mo."}</h3></div>
                                <div className={"flex" + ((billFreq === 1) ? " sel" : "")} onClick={(_)=>{setBillFreq(1)}}><h2>Every Year</h2><h3>${(plan === 1) ? "97 / mo." : "27 / mo."}</h3></div>
                            </div>
                        </div>
                    </>
                }
                <div className="su-bd flex">
                    {(page === 1) && <button className="su" onClick={(_)=>{setPage(0)}}>&lt;- Back</button>}
                    <button className={((plan === -1) ? "hidden" : "")} disabled={(plan === -1)} onClick={handleContinue}>{(plan !== 2) ? "Continue" : "Contact Us"}</button>
                </div>
            </div>
        </>
    );
}

export default ChoosePlan;