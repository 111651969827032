// Dependencies
import { useState, useEffect } from "react";

// Component Imports
import NewCategoryModal from "../../modals/NewCategoryModal";
import EditCategoryModal from "../../modals/EditCategoryModal";

// API Imports
import { getUserCategories } from "../../../apicalls/listings";

// Util Imports
import { useError } from '../../ui/Context/ErrorContext';

// SVG Imports
import tag_orange from "../../../img/svg/listing/tag_orange.svg";
import search_icon from "../../../img/svg/topbar/search_icon.svg";


// Functional Component
function CategoryResults(props) {
	// Render list of categories
	function renderList(cats) {
		var r = [];
		cats.forEach((c, index) => {
			r.push(
				<button className="set-cat-b" key={'k'+index} onClick={(_e)=>{props.openEdit(c.id, c.category_name)}}>
					<h2>{c.category_name}</h2>
					<div className="iflex">
						<h3>{c.count}</h3>
						<img src={tag_orange} alt="" />
					</div>
				</button>
			)
		});
		return r;
	}

	// Return layout
	return (
		<div>
			<div className="dash-result-d flex">
				<h2>Results</h2>
				<div className="dash-result-num"><h3>{props.categories.length}</h3></div>
			</div>
			{ renderList(props.categories) }
		</div>
	);
}


// Functional Component
function Categories(_props) {
	const { showError } = useError();

	// Page states
	const [didLoad, setDidLoad] = useState(false);
	const [loaderOn, setLoaderOn] = useState(false);

	// Data states
	const [loadct, setLoadCt] = useState(0);
	const [cats, setCats] = useState([]);
	const [searchInput, setSearchInput] = useState("");
	const [newOpen, setNewOpen] = useState(false);
	const [editOpen, setEditOpen] = useState(false);
	const [editId, setEditId] = useState(-1);
	const [editName, setEditName] = useState("");

	// Load all categories
	async function loadCategories() {
		const resp = await getUserCategories();
		setTimeout(()=>{setLoaderOn(true)}, 500); // Turn preloader on after 0.5s
		if (resp.status !== 200) {
            showError('An unexpected error occurred.');
		} else {
			const data = await resp.json();
			setCats(data);
			setDidLoad(true);
		}
	}
	
	// Load data on page load + trigger refresh on modal changes
	useEffect(()=>{ loadCategories(); }, [loadct]);
	function triggerRefresh() { setLoadCt(loadct+1) }

	// Return layout
	return (
		<>
			{(didLoad) ?
				<>
					{(newOpen) && <NewCategoryModal {...{triggerRefresh: triggerRefresh, closeModal: ()=>{setNewOpen(false)}}} /> }
					{(editOpen) && <EditCategoryModal {...{id: editId, name: editName, triggerRefresh: triggerRefresh, closeModal: ()=>{setEditOpen(false)}}} /> }
					<div className="lst-new-i-d">
						<h2>Listing Categories</h2>
						<h3 className="set-int-hs">Manage your listing categories.</h3>
					</div>
					<div className="flex">
						<label className="set-mng-in">
							<img src={search_icon} alt="" />
							<input placeholder="Search categories..." onChange={(e)=>{setSearchInput(e.target.value)}} value={searchInput || ""} />
						</label>
						<button className="set-mng-b" onClick={()=>{setNewOpen(true)}}>Create</button>
					</div>
					<div className="content-spacer" />
					{(cats) && <CategoryResults {...{openEdit: (id,nm)=>{setEditId(id); setEditName(nm); setEditOpen(true);}, categories: cats.filter(c => c.category_name.toLowerCase().includes(searchInput.toLowerCase()))}} />}
				</>
				:
            	<>{(loaderOn) && <div className="prl-spin-cont"><div className="prl-spin" /></div>}</>
			}
		</>
	);
}

export default Categories;