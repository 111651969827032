// Dependencies
import { useState } from 'react';

// API Imports
import { api_enhanceListingDesc } from "../../../apicalls/compose.js";

// Component Imports
import AI_Textbox from "../comp/Textbox";
import AI_Tone from "../comp/Tone";
import FinishedCompose from '../comp/FinishedCompose.jsx';

// Util Imports
import { useError } from '../../ui/Context/ErrorContext';
import { useUpgrade } from '../../ui/Context/UpgradeContext';

// Functional Component
function Enhance_ListingDesc(props) {
    const { showError } = useError();
    const { showUpgrade } = useUpgrade();

    // Data states
    const [desc, setDesc] = useState("");
    const [feedback, setFeedback] = useState("");
    const [addInfo, setAddInfo] = useState("");
    const [tone, setTone] = useState(null);

    // Helper functions
    async function generateCompose() {
        const resp = await api_enhanceListingDesc({
            cd: desc,
            fb: feedback,
            ai: addInfo,
        });
        switch (resp.status) {
            case 200:
                const msg = await resp.json();
                return msg.c;
            case 401:
                showUpgrade('Compose');
                return "Compose is a Team feature. Please upgrade your plan to continue.";
            default:
                showError('An unexpected error occurred.');
                return "Sorry, an unexpected error occurred.";
        }
    }

    // Return layout
    return (
        <>
            {(props.stage === 0) &&
                <>
                    <AI_Textbox val={desc} setVal={setDesc} title="Existing Listing Description" placeholder="e.g. This 2-bedroom, 1-bathroom home is situated in a quiet suburban neighborhood. The backyard is small but manageable, and the local school and grocery store are within walking distance. Ideal for those looking for an affordable fixer-upper opportunity." />
                    <AI_Textbox val={feedback} setVal={setFeedback} title="Feedback on Existing Listing Description" placeholder="e.g. The description needs more details about the property's age, condition, and unique features. Mention the neighborhood and nearby amenities for a better understanding of the location. Highlight the property's potential." description="What specific features of the existing description should be improved?" />
                    <AI_Textbox val={addInfo} setVal={setAddInfo} title="Additional Information" description="Any other relevant information you’d like to include in the listing description." />
                    <AI_Tone val={tone} setVal={setTone} last={true} />
                </>
            }
            {(props.stage === 1) && <FinishedCompose {...{generate: generateCompose}} /> }
        </>
    );
}

export default Enhance_ListingDesc;