// Image Imports
import notfound_img from "../../../img/png/errors/404.png";
import logo from "../../../img/svg/landing/lo_logo.svg";

// Util Imports
import setTitleText from "../../../utils/setTitleText.js";

// Style Imports
import "./error.css";

// Functional Component
function NotFound_404() {
	setTitleText("Oops!");

	// Return layout
	return (
		<div className="err-nf-404 cont">
			<div className="err-nf-404 top">
				<img src={logo} alt="ListingOpen" />
			</div>
			<div className="err-nf-404 mid">
				<img src={notfound_img} alt="Not Found" />
				<h1>This page isn't on the market yet!</h1>
				<h2>We can't find the webpage you're looking for, but the links below may help.</h2>
				<div className="flex">
					<a href="/">Home</a>
					<a href="/login">Dashboard</a>
				</div>
			</div>
		</div>
	);
}

export default NotFound_404;