/*
------------------
> AUTH API CALLS <
------------------
*/

const prefix = `${(process.env.REACT_APP_ENV_MODE === "development") ? process.env.REACT_APP_API_URL_DEV : process.env.REACT_APP_API_URL_PROD}/compose`;


export async function api_createListingDesc(info) {  
	return await fetch(prefix+'/create/ldesc', {
		method: 'POST', credentials: 'include',
		headers: {
		'Content-Type': 'application/json'
		},
		body: JSON.stringify({info: info})
	});
}

export async function api_createNeighbourDesc(info) {  
	return await fetch(prefix+'/create/ndesc', {
		method: 'POST', credentials: 'include',
		headers: {
		'Content-Type': 'application/json'
		},
		body: JSON.stringify({info: info})
	});
}

export async function api_createOpenHouseInvite(info) {
	return await fetch(prefix+'/create/ohinv', {
		method: 'POST', credentials: 'include',
		headers: {
		'Content-Type': 'application/json'
		},
		body: JSON.stringify({info: info})
	});
}

export async function api_enhanceListingDesc(info) {  
	return await fetch(prefix+'/enhance/ldesc', {
		method: 'POST', credentials: 'include',
		headers: {
		'Content-Type': 'application/json'
		},
		body: JSON.stringify({info: info})
	});
}

export async function api_personalizeListingDesc(info) {  
	return await fetch(prefix+'/enhance/pdesc', {
		method: 'POST', credentials: 'include',
		headers: {
			'Content-Type': 'application/json'
		},
		body: JSON.stringify({info: info})
	});
}

export async function api_explainClosing(info) {  
	return await fetch(prefix+'/explain/closing', {
		method: 'POST', credentials: 'include',
		headers: {
			'Content-Type': 'application/json'
		},
		body: JSON.stringify({info: info})
	});
}

export async function api_explainJargon(info) {  
	return await fetch(prefix+'/explain/jargon', {
		method: 'POST', credentials: 'include',
		headers: {
			'Content-Type': 'application/json'
		},
		body: JSON.stringify({info: info})
	});
}

export async function api_explainLease(info) {  
	return await fetch(prefix+'/explain/lease', {
		method: 'POST', credentials: 'include',
		headers: {
			'Content-Type': 'application/json'
		},
		body: JSON.stringify({info: info})
	});
}

export async function api_highlightComparison(info) {  
	return await fetch(prefix+'/highlight/comp', {
		method: 'POST', credentials: 'include',
		headers: {
			'Content-Type': 'application/json'
		},
		body: JSON.stringify({info: info})
	});
}

export async function api_highlightInvest(info) {  
	return await fetch(prefix+'/highlight/invest', {
		method: 'POST', credentials: 'include',
		headers: {
			'Content-Type': 'application/json'
		},
		body: JSON.stringify({info: info})
	});
}

export async function api_highlightFeatures(info) {  
	return await fetch(prefix+'/highlight/features', {
		method: 'POST', credentials: 'include',
		headers: {
			'Content-Type': 'application/json'
		},
		body: JSON.stringify({info: info})
	});
}

export async function api_requestFeature(desc, goal, extra) {
	return await fetch(prefix+'/request', {
		method: 'POST', credentials: 'include',
		headers: {
			'Content-Type': 'application/json'
		},
		body: JSON.stringify({a: desc, b: goal, c: extra})
	});
}

export async function api_enhance(og, feedback) {
	return await fetch(prefix+'/enhance/simple', {
		method: 'POST', credentials: 'include',
		headers: {
			'Content-Type': 'application/json'
		},
		body: JSON.stringify({og: og, fb: feedback})
	});
}