// Dependencies
import { useState, useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import Select from 'react-select';

// API Imports
import { getAutomationsForUser } from "../../../apicalls/automations.js";

// Util Imports
import { useError } from '../../ui/Context/ErrorContext';
import { useUpgrade } from "../../ui/Context/UpgradeContext";
import { getLogoForType, getDescForType } from "../../../utils/sharedAutomationFuns.js";
import setTitleText from "../../../utils/setTitleText.js";

// SVG Imports
import zap_orange from "../../../img/svg/topbar/zap_orange.svg";
import search_icon from "../../../img/svg/topbar/search_icon.svg";

// Constants
const allTypes = [{value: "listing", label: "Listing Template"}, {value: "questions", label: "Sign-In Preset"}, {value: "integrations", label: "Integration Package"}, {value: "email", label: "Email Template"}];

// Custom styling for select boxes
const selectStyle = {
    control: (styles, { isFocused }) => ({
        ...styles,
        borderColor: isFocused ? 'var(--orange-accent)' : styles.borderColor,
        boxShadow: (isFocused) ? '0 0 0 1px var(--orange-accent)' : styles.boxShadow,
        '&:hover': {
            borderColor: 'var(--orange-accent)'
        }
    }),
    option: (provided, state) => ({
        ...provided,
        fontFamily: 'Inter',
        fontSize: '15px',
        backgroundColor: state.isFocused ? 'var(--orange-accent-lite)' : null,
        color: state.isSelected ? 'white' : 'black',
        ':active': {
        backgroundColor: 'var(--orange-accent)'
        },
    }),
    multiValue: (styles) => ({
        ...styles,
        backgroundColor: 'var(--grey-2)',
    }),
    multiValueLabel: (styles) => ({
        ...styles,
        color: 'black',
        fontFamily: 'Inter',
        fontSize: '13px'
    }),
    multiValueRemove: (styles) => ({
        ...styles,
        color: 'var(--grey-4)',
        marginLeft: '3px',
        ':hover': {
            backgroundColor: 'var(--delete-bg)',
            color: 'var(--delete-fg)',
        }
    }),
    noOptionsMessage: (styles) => ({
        ...styles,
        fontSize: '15px',
        fontFamily: 'Inter',
        color: 'var(--grey-4)'
    })
};

// Render all automations
function AutomationsLayout(props) {
    return (
        <>
            <div className="dash-result-d flex">
                <h2>Results</h2>
                <div className="dash-result-num">
                    <h3>{props.automs.length || "0"}</h3>
                </div>
            </div>
            <div className="atms-card-cont">
                {props.automs.map((atm) => {
                    return (
                        <button className="atms-card-b" id={atm.code} key={'k'+atm.code} onClick={(e)=>{props.navigateTo("/automations/"+e.currentTarget.id)}}>
                            <img className="atms-card-img" src={getLogoForType(atm.type)} alt="" />
                            <div className="atms-card-id">
                                <h1>{atm.name}</h1>
                                <h3>{getDescForType(atm.type)}</h3>
                            </div>
                        </button>
                    );
                })}
            </div>
        </>
    );
}

// Functional Component
function Controller() {
    setTitleText("Automations");

    // Page states
    const navigate = useNavigate();
    const { showError } = useError();
    const { showUpgrade } = useUpgrade();
    const [didLoad, setLoaded] = useState(false);
    const [loaderOn, setLoaderOn] = useState(false);

    // Data states
    const [automations, setAutomations] = useState(null);

    // Input states
    const [searchInput, setSearchInput] = useState("");
    const [filterTypes, setFilterTypes] = useState([]);

    // Load automations for user
    useEffect(()=>{
        async function loadPage() {
            const resp = await getAutomationsForUser();
            setTimeout(()=>{setLoaderOn(true)}, 500); // Turn preloader on after 0.5s
            switch (resp.status) {
                case 200:
                    // Extract listings
                    const results_raw = (await resp.json()).data;
                    const results = results_raw.map((l) => {
                        var type = "listing";
                        switch (l.type) {
                            case 1:
                                type = "integrations";
                                break;
                            case 2:
                                type = "questions";
                                break;
                            case 3:
                                type = "email";
                                break;
                            default:
                                break;
                        }
                        return ({name: l.name, type: type, code: l.code});
                    });
                    setAutomations(results);
                    // Trigger page load
                    setLoaded(true);
                    break;
                case 401:
                    showUpgrade("Automations");
                    break;
                default:
                    showError("An unexpected error occurred.");
                    break;
            }
        }
        loadPage();
    },[]);



    // Return layout
    return (
        <>
            <div className="tb-cont">
                <div className="tb-top">
                    <div className="iflex">
                        <img className="tb-t-icon" src={zap_orange} alt="" />
                        <h1 className="pg-t">Automations</h1>
                    </div>
                    <div className="lst-dsh-tools iflex">
                        <label>
                            <img src={search_icon} alt="" />
                            <input className="atms-type-in" placeholder="Search automations..." onChange={(e)=>{setSearchInput(e.target.value)}} />
                        </label>
                        <Select isClearable={true} options={didLoad ? allTypes : []} onChange={(e)=>{setFilterTypes(e.map(option => option.value))}} disabled={!didLoad} isMulti={true} styles={selectStyle} placeholder="All Types"/>
                        <NavLink to="/automations/new"><h3>Create</h3></NavLink>
                    </div>
                </div>
            </div>
            <div className="dash-cont">
                {(didLoad) ?
                    <AutomationsLayout
                        {...{
                            navigateTo: (link)=>{navigate(link, {replace:false})},
                            automs: automations.filter(atm => { return( atm.name.toLowerCase().includes(searchInput.toLowerCase()) && ((filterTypes && filterTypes.length > 0) ? filterTypes.includes(atm.type) : true) ); })
                        }}
                    />
                    :
                    <>{(loaderOn) && <div className="prl-spin-cont"><div className="prl-spin" /></div>}</>
                }
            </div>
        </>
    );
}

export default Controller;