// Dependencies
import { useState } from "react";
import { useNavigate } from "react-router-dom";

// API Imports
import { deleteUserAccount } from "../../../apicalls/auth.js";

// Util Imports
import { useError } from '../../ui/Context/ErrorContext';

// SVG Imports
import back_icon from "../../../img/svg/listing/back_chevron.svg";
import del_orange from "../../../img/svg/topbar/del_orange.svg";


// Functional Component
function DeleteAccount(props) {
	const navigate = useNavigate();
	const { showError } = useError();
	
	// Data states
	const [deleteCode, _sdc] = useState(Array.from({ length: 6 }, () => 'ABCDEFGHJKMNPQRSTUVWXYZabcdefghjkmnopqrstuvwxyz23456789'[Math.floor(Math.random() * 58)]).join(''));
	const [deleteInput, setDeleteInput] = useState("");

	// Delete account wrapper
	async function handleDeleteAccount() {
		if (deleteInput !== deleteCode) return;
		const resp = await deleteUserAccount();
		if (resp !== 200) showError('An unexpected error occurred.');
		else navigate("/login", {replace: true});
	}

	// Return layout
	return (
		<div className="pop-pg-cont">
			<button className="lead-prof-back-b" onClick={()=>{props.setPage(0)}}>
				<img src={back_icon} alt="" />
			</button>
			<div className="pop-pg-t flex">
				<img className="tb-t-icon" src={del_orange} alt="" />
				<h1 className="pg-t">Delete Account</h1>
			</div>
			<div className="content-spacer" />
			<div className="lst-new-i-d">
				<h2>Are you sure?</h2>
				<h3 className="intg-mng-hs">Continuing will permanently delete all data associated with your account. This includes all profile info, listings, leads, integrations, and any other data you created. There is no way to recover this data.</h3>
			</div>
			<div className="lst-new-i-d mb0">
				<h2>If you are sure, type "{deleteCode}" below (without quotation marks):</h2>
				<input className="intg-mng-in" value={deleteInput || ""} onChange={(e)=>{setDeleteInput(e.target.value)}} />
			</div>
			<div className="dash-cont-b-d iflex">
				<button className="back" onClick={()=>{props.setPage(0)}}>&lt;- No, take me back!</button>
				<button
					className={"next" + ((deleteInput !== deleteCode) ? " hidden" : "")}
					onClick={handleDeleteAccount}
				>Yes, delete my account.</button>
			</div>
		</div>
	);
}

export default DeleteAccount;