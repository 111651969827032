// Dependencies
import { useState, useEffect } from "react";
import ExcelJS from "exceljs";

// API Imports
import { loadLeadAnswers_bulk } from "../../apicalls/leads.js";

// Util Imports
import { formatPhoneNumber } from "../../utils/formatPhone.js";
import { shortenAddress } from "../../utils/shortenAddress.js";

// SVG Imports
import back_icon from "../../img/svg/listing/back_chevron.svg";
import export_orange from "../../img/svg/topbar/export_orange.svg";

// PNG Imports
import excel_icon from "../../img/png/export_formats/export_excel.png";
import csv_icon from "../../img/png/export_formats/export_csv.png";
import json_icon from "../../img/png/export_formats/export_json.png";
import text_icon from "../../img/png/export_formats/export_text.png";


// Helper Functions
function formatDatetime(unix_dt) {
	const date = new Date(unix_dt * 1000);
    const options = { month: 'short', day: 'numeric', year: 'numeric' };
    return date.toLocaleDateString('en-US', options);
}

// Download correct type
// 	- format = 0 (xlsx), 1 (csv), 2 (json), 3 (text)
// 	- leads = [{name, phone, email, listing, notes, answers}, ...]
async function handleDownload(format, selection, include_contact, include_notes, include_date, include_answers, leads, filtered_leads) {
	var lead_list = leads;
	if (filtered_leads && selection === 1) lead_list = filtered_leads;

	var headers = ['Full Name', 'Listing Address'];
	if (include_contact) headers.push('Phone Number', 'Email Address');
	if (include_notes) headers.push('Notes');
	if (include_date) headers.push('Date Visited');

	var q_bank = {}; var q_header_map = {}; var a_bank = {}; var q_added_ct = 0;
	if (include_answers) {
		// Load data
		const resp = await loadLeadAnswers_bulk(leads.map((e)=>{return e.id}));
		if (resp.status !== 200) {
			// TODO: Handle error
			return false;
		}
		const data = await resp.json();
		q_bank = data.q; a_bank = data.a;

		// Add questions to headers (if excel or csv selected) - keep track of ordering
		if (format === 0 || format === 1) {
			for (const q_id in q_bank) {
				const q_text = q_bank[q_id];
				const index = headers.indexOf(q_text);
				if (index === -1) {
					headers.push(q_text);
					q_header_map[q_id] = headers.length - 1;
					q_added_ct++;
				} else {
					q_header_map[q_id] = index;
				}
			}
		}
	}

	// Dynamically generate file data
	var file_name = `Leads_Export_${Math.floor(Date.now() / 1000)}.`;
	var blob;
	switch (format) {
		case 0: /* Excel */
			file_name += 'xlsx';

			// Create a new workbook
			const workbook = new ExcelJS.Workbook();
			const worksheet = workbook.addWorksheet('Leads');

			// Add & style header rows
			const headerRow = worksheet.addRow(headers);
			headerRow.eachCell((cell) => {
				cell.fill = {
					type: 'pattern',
					pattern: 'solid',
					fgColor: { argb: '00000000' }, // Black background
				};
				cell.font = {
					bold: true,
					color: { argb: 'FFFFFFFF' }, // White font color
				};
			});
			
			// Add data rows
			lead_list.forEach((l) => {
				const row = [l.name, shortenAddress(l.address_1) + (l.address_2 ? `, ${l.address_2}` : '')];
				if (include_contact) row.push(formatPhoneNumber(l.phone), l.email);
				if (include_notes) row.push(l.notes);
				if (include_date) row.push(formatDatetime(l.visited));
				if (include_answers) {
					for (var _i=0; _i<q_added_ct; _i++) row.push("");
					for (const q_id in a_bank[l.id]) {
						const answer = a_bank[l.id][q_id];
						// Booleans for y/n answers
						if (answer === "y" || answer === "n") row[q_header_map[q_id]] = (answer === "y");
						else row[q_header_map[q_id]] = a_bank[l.id][q_id];
					}
				}
				worksheet.addRow(row);
			});

			// Set column widths based on content
			worksheet.columns.forEach((column) => {
				let maxLength = 0;
				column.eachCell((cell) => {
				const columnLength = cell.value ? cell.value.toString().length : 0;
				if (columnLength > maxLength) {
					maxLength = columnLength;
				}
				});
				column.width = maxLength < 10 ? 10 : maxLength;
			});
  
			// Create the blob
			workbook.xlsx.writeBuffer().then((buffer) => {
				const excel_blob = new Blob([buffer], {
					type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
				});
				// Download file
				const a = document.createElement('a');
				a.href = URL.createObjectURL(excel_blob); a.download = file_name; a.style.display = 'none';
				document.body.appendChild(a);
				a.click();
				document.body.removeChild(a);
			});
			return;
		case 1: /* CSV */
			file_name += 'csv';

			// Create csv data
			const csv_data_raw = [
				headers,
				...lead_list.map((l) => {
					var row = [l.name, (shortenAddress(l.address_1) + (l.address_2 ? ` (${l.address_2})` : ""))];
					if (include_contact) row.push(formatPhoneNumber(l.phone), l.email);
					if (include_notes) row.push((l.notes || ""));
					if (include_date) row.push(formatDatetime(l.visited));
					if (include_answers) {
						for (var _i=0; _i<q_added_ct; _i++) row.push("");
						for (const q_id in a_bank[l.id]) {
							const answer = a_bank[l.id][q_id];
							// Booleans for y/n answers
							if (answer === "y" || answer === "n") row[q_header_map[q_id]] = (answer === "y");
							else row[q_header_map[q_id]] = a_bank[l.id][q_id];
						}
					}
					return row.map((e) => {return `\"${e}\"`}); // Wrap in double quotes to allow commas
				})
			];
			const csv_data = csv_data_raw.map((row)=>row.join(',')).join('\r\n');

			// Create blob
			blob = new Blob([csv_data], {
				type: 'text/csv;charset=utf-8',
			});

			break;
		case 2: /* JSON */
			file_name += 'json';
			// Create JSON data
			const json_data_raw = lead_list.map((l) => {
				var t = {full_name: l.name, listing_address: shortenAddress(l.address_1) + ((l.address_2) ? `, ${l.address_2}` : "")};
				if (include_contact) { t['phone_number'] = formatPhoneNumber(l.phone); t['email_address'] = l.email; }
				if (include_notes) t['notes'] = l.notes;
				if (include_date) t['date_visited'] = formatDatetime(l.visited);
				if (include_answers) {
					for (const q_id in a_bank[l.id]) {
						const answer = a_bank[l.id][q_id];
						// Booleans for y/n answers
						if (answer === "y" || answer === "n") t[q_bank[q_id]] = (answer === "y");
						else t[q_bank[q_id]] = a_bank[l.id][q_id];
					}
				}
				return t;
			})
			const json_data = JSON.stringify(json_data_raw, null, 2);
		
			// Create blob
			blob = new Blob([json_data], {
				type: 'application/json;charset=utf-8',
			});

			break;
		case 3: /* Text */ 
			file_name += 'txt';

			// Create text data
			const text_data = leads.map((l) => {
				var t = `Full Name: ${l.name}\nListing Address: ${shortenAddress(l.address_1) + (l.address_2 ? `, ${l.address_2}` : "")}`;
				if (include_contact) t += `\nPhone Number: ${(l.phone) ? formatPhoneNumber(l.phone) : "N/A"}\nEmail Address: ${l.email || "N/A"}`;
				if (include_notes) t += `\nNotes: ${l.notes || "N/A"}`;
				if (include_date) t += `\nDate Visited: ${formatDatetime(l.visited)}`;
				if (include_answers) {
					for (const q_id in a_bank[l.id]) {
						const answer = a_bank[l.id][q_id];
						// Booleans for y/n answers
						if (answer === "y" || answer === "n") t += `\n${[q_bank[q_id]]}: ${(answer === "y") ? "Yes" : "No"}`;
						else t += `\n${[q_bank[q_id]]}: ${a_bank[l.id][q_id]}`;
					}
				}
				t += '\n\n';
				return t;
			}).join('\n');

			// Create blob
			blob = new Blob([text_data], {
				type: 'text/plain;charset=utf-8',
			});

			break;
		default:
			return true;

		return true;
	}

	// Download file
	const a = document.createElement('a');
	a.href = URL.createObjectURL(blob); a.download = file_name; a.style.display = 'none';
	document.body.appendChild(a);
	a.click();
	document.body.removeChild(a);
}


// Functional Component
function ExportLeads(props) {
	const [leads, _setL] = useState(props.all_leads);
	const [filtered_leads, _setFL] = useState((props.all_leads.length === props.filtered_leads.length) ? null : (props.filtered_leads || null));

	// Page states
	const [format, setFormat] = useState(-1);

	// Selection (all leads or just filtered)
	const [selection, setSelection] = useState(-1);
	
	// Dataset (include contact info, sign-in answers)
	const [include_contact, set_include_contact] = useState(true);
	const [include_notes, set_include_notes] = useState(true);
	const [include_date, set_include_date] = useState(true);
	const [include_answers, set_include_answers] = useState(false);

	// Return layout
	return (
		<div className="pop-pg-cont">
			<button className="lead-prof-back-b" onClick={props.goBack}>
				<img src={back_icon} alt="" />
			</button>
			<div className="pop-pg-t flex">
				<img className="tb-t-icon" src={export_orange} alt="" />
				<h1 className="pg-t">Export Leads</h1>
			</div>
			<div className="content-spacer" />
			<div className="lst-new-i-d">
				<h2>Format</h2>
				<div className="set-int-b-cont">
					<button className={"set-int-b"+((format === 0) ? " sel" : ((format>=0) ? " unsel" : ""))} onClick={()=>{(format !== 0) ? setFormat(0) : setFormat(-1)}}>
						<img src={excel_icon} alt="" />
						<h2>Excel (.xlsx)</h2>
					</button>
					<button className={"set-int-b"+((format === 1) ? " sel" : ((format>=0) ? " unsel" : ""))} onClick={()=>{(format !== 1) ? setFormat(1) : setFormat(-1)}}>
						<img src={csv_icon} alt="" />
						<h2>CSV (.csv)</h2>
					</button>
					<button className={"set-int-b"+((format === 2) ? " sel" : ((format>=0) ? " unsel" : ""))} onClick={()=>{(format !== 2) ? setFormat(2) : setFormat(-1)}}>
						<img src={json_icon} alt="" />
						<h2>JSON (.json)</h2>
					</button>
					<button className={"set-int-b"+((format === 3) ? " sel" : ((format>=0) ? " unsel" : ""))} onClick={()=>{(format !== 3) ? setFormat(3) : setFormat(-1)}}>
						<img src={text_icon} alt="" />
						<h2>Text (.txt)</h2>
					</button>
				</div>
			</div>
			{(filtered_leads && filtered_leads.length > 0) &&
				<div className="lst-new-i-d">
					<h2>Selection</h2>
					<button className={"sel-b" + ((selection === 0) ? " active" : "")} onClick={()=>{setSelection(0)}}>
						<div><div/></div>
						<h3>All Leads ({leads.length})</h3>
					</button>
					<button className={"sel-b" + ((selection === 1) ? " active" : "")} onClick={()=>{setSelection(1)}}>
						<div><div/></div>
						<h3>Filtered Leads ({filtered_leads.length})</h3>
					</button>
				</div>
			}
			<div className="lst-new-i-d mb0">
				<h2>Include the following data...</h2>
					<button className={"sel-b" + ((include_contact) ? " active" : "")} onClick={()=>{set_include_contact(!include_contact)}}>
						<div><div/></div>
						<h3>Contact Info</h3>
					</button>
					<button className={"sel-b" + ((include_notes) ? " active" : "")} onClick={()=>{set_include_notes(!include_notes)}}>
						<div><div/></div>
						<h3>Notes</h3>
					</button>
					<button className={"sel-b" + ((include_date) ? " active" : "")} onClick={()=>{set_include_date(!include_date)}}>
						<div><div/></div>
						<h3>Date Visited</h3>
					</button>
					<button className={"sel-b" + ((include_answers) ? " active" : "")} onClick={()=>{set_include_answers(!include_answers)}}>
						<div><div/></div>
						<h3>Sign-In Answers</h3>
					</button>
			</div>
			<div className="dash-cont-b-d iflex">
				<button
					className={"next"+((format<0 || (filtered_leads && selection === -1)) ? " hidden" : "")}
					disabled={format<0 || (filtered_leads && selection === -1)}
					onClick={()=>{handleDownload(format, selection, include_contact, include_notes, include_date, include_answers, leads, filtered_leads)}}
				>Download</button>
			</div>
		</div>
	);
}

export default ExportLeads;