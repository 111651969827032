/*
----------------------
> SETTINGS API CALLS <
----------------------
*/

const prefix = `${(process.env.REACT_APP_ENV_MODE === "development") ? process.env.REACT_APP_API_URL_DEV : process.env.REACT_APP_API_URL_PROD}/settings`;


export async function getProfileData() {
  return await fetch(prefix+'/profile', {
    method: 'GET', credentials: 'include'
  });
}

export async function getOrganization() {
  return await fetch(prefix+'/org', {
    method: 'GET', credentials: 'include'
  });
}

export async function addOrganizationMember(email, role) {
  return await fetch(prefix+'/org/invite', {
    method: 'POST', credentials: 'include',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({email: email, role: role})
  });
}

export async function removeOrganizationMember(id) {
  return await fetch(prefix+'/org/kick', {
    method: 'DELETE', credentials: 'include',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({id: id})
  });
}

export async function changeOrganizationRole(id, role) {
  return await fetch(prefix+'/org/role', {
    method: 'PATCH', credentials: 'include',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({id: id, role: role})
  });
}

export async function leaveOrganization() {
  return await fetch(prefix+'/org/leave', {
    method: 'GET', credentials: 'include'
  });
}

export async function createOrganization(name) {
  return await fetch(prefix+'/org/create', {
    method: 'POST', credentials: 'include',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({name: name})
  });
}

export async function deleteOrganization() {
  return await fetch(prefix+'/org/del', {
    method: 'GET', credentials: 'include'
  });
}