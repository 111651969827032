// SVG Imports
import pro_icon from "../../../img/svg/ai_suite/tone_pro.svg";
import friend_icon from "../../../img/svg/ai_suite/tone_friend.svg";
import inform_icon from "../../../img/svg/ai_suite/tone_inform.svg";
import target_icon from "../../../img/svg/ai_suite/tone_target.svg";
import strat_icon from "../../../img/svg/ai_suite/tone_strat.svg";

// Constants
const allTones = ["Professional", "Friendly", "Informative", "Persuasive", "Strategic"];
const allIcons = [pro_icon, friend_icon, inform_icon, target_icon, strat_icon];


// Functional Component
function AI_Tone({
    val, setVal,
    last=false
}) {
    // Return layout
    return (
        <div className={"ai-tool-input" + (last ? " mb0" : "")}>
            <h2>Tone/Style</h2>
            <div className="ai-tool-b-d tone">
                {
                    allTones.map((tone, index) => {
                        return (
                            <button
                                key={`ait-${index}`}
                                className={"" + ((tone === val) ? " sel" : ((val !== null) ? " unsel" : ""))}
                                value={index}
                                onClick={(e) => {
                                    const ct = allTones[e.currentTarget.value];
                                    if (val === ct) setVal(null);
                                    else setVal(ct);
                                }}
                            ><img src={allIcons[index]} alt="" /><h3>{tone}</h3></button>
                        );
                    })
                }
            </div>
        </div>
    );
}

export default AI_Tone;