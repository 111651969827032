// Dependencies
import { useState, useEffect, useRef } from "react";

// SVG Imports
import x_icon from "../../img/svg/signin/x_icon.svg";

// Functional Component
function EditQuestionModal(props) {
    const [questionText, setQuestionText] = useState(props.text);
    const [questionOptions, setQuestionOptions] = useState(props.options || ["",""]);

    // For auto-resizing question text input
    const qAreaRef = useRef(null);
    const [qAreaRows, setQAreaRows] = useState(1);
    useEffect(() => {
        if (props.page !== 1) return;
        // Set the number of rows to match the content
        if (qAreaRef.current) {
            qAreaRef.current.style.height = 'auto';
            const lineHeight = 20;
            const newRows = Math.floor((qAreaRef.current.scrollHeight - lineHeight) / lineHeight);
            setQAreaRows(newRows < 1 ? 1 : newRows);
        }
    }, [questionText]);

    // Handle saving changes to question
    function handleSaveChanges() {
        if (questionText.length === 0 || ((props.type === 1 || props.type === 2) && !questionOptions.some(s => s.length > 0))) return;
        if (props.type === 0 || props.type === 3) props.updateQuestion(props.lid,questionText,null,props.order);
        if (props.type === 1 || props.type === 2) props.updateQuestion(props.lid,questionText,questionOptions.filter(s => s.trim().length > 0),props.order);
        props.closeModal();
    }

    // Handle removal
    function handleRemove() {
        // Set order to -1
        props.updateQuestion(props.lid,props.text,props.options,-1); 
        props.closeModal();
    }

    // Return layout
    return(
        <div className="nqmod-bg">
            <div className="nqmod-cont">
                <div className="nqmod-top iflex">
                    <h2>Edit Question</h2>
                    <button onClick={()=>{props.closeModal()}}><img src={x_icon} alt="" /></button>
                </div>
                <div className="nqmod-spacer" />
                <div className="nqmod-btm">
                    <h3 className="nqmod-sbt">Question Text</h3>
                    <textarea
                        ref={qAreaRef}
                        rows={qAreaRows}
                        className="nqmod-qt-ta"
                        placeholder="Your question here..."
                        value={questionText}
                        maxLength={255}
                        onChange={(e)=>{setQuestionText(e.target.value || "")}} />
                    {(props.type === 1 || props.type === 2) &&
                        <>
                            <h3 className="nqmod-sbt mt">Question Options</h3>
                            {questionOptions.map((option, i)=>(
                                <input
                                    key={`qopt-${i}`}
                                    className="nqmod-qopt-in"
                                    placeholder={`Option #${i+1}`}
                                    value={option}
                                    maxLength={55}
                                    onChange={(e)=>{
                                        var temp = [...questionOptions];
                                        temp[i] = e.target.value || "";
                                        setQuestionOptions(temp)
                                    }} />
                            ))}
                            {(questionOptions.length < 6) &&
                                <button className="nqmod-qopt-add" onClick={()=>{
                                    var temp = [...questionOptions];
                                    temp.push("");
                                    setQuestionOptions(temp);
                                }}>Add Option</button>
                            }
                        </>
                    }
                    <div className="nqmod-b-d iflex">
                        <button className="nqmod-lrg-b rmv" onClick={handleRemove}>Remove</button>
                        <button
                            className={"nqmod-lrg-b sve"+ ((questionText.length === 0 || ((props.type === 1 || props.type === 2) && !questionOptions.some(s => s.length > 0))) ? " hidden" : "")}
                            disabled={questionText.length === 0 || ((props.type === 1 || props.type === 2) && !questionOptions.some(s => s.length > 0))}
                            onClick={handleSaveChanges}
                        >Save Changes</button>
                    </div>
                </div>
            </div>
        </div>    
    );
}

export default EditQuestionModal;