// Dependencies
import { useState } from 'react';

// API Imports
import { api_highlightInvest } from "../../../apicalls/compose.js";

// Component Imports
import { AI_Single_Select } from '../comp/Select';
import AI_Input from '../comp/Input';
import AI_PropertyDetails from '../comp/PropertyDetails';
import AI_Textbox from "../comp/Textbox";
import AI_Tone from "../comp/Tone";
import FinishedCompose from '../comp/FinishedCompose.jsx';

// Util Imports
import { useError } from '../../ui/Context/ErrorContext';
import { useUpgrade } from '../../ui/Context/UpgradeContext';

// Constants
const allPropertyTypes = ["Townhouse","Detached Home","Condominium","Apartment","Duplex","Multiplex","Bungalow","Luxury Property"];


// Functional Component
function Highlight_InvestBenefits(props) {
    const { showError } = useError();
    const { showUpgrade } = useUpgrade();
    
    // Data states
    const [propertyType, setPropertyType] = useState(null);
    const [addressInfo, setAddressInfo] = useState("");
    const [propertyDetails, setPropertyDetails] = useState([
        {title: "Bedrooms", value: null},
        {title: "Bathrooms", value: null},
        {title: "Levels", value: null},
        {title: "Square Footage", value: null},
        {title: "Lot Size (ft²)", value: null},
        {title: "Garages", value: null},
        {title: "Year Built", value: null},
    ]);
    const [roi, setRoi] = useState("");
    const [risk, setRisk] = useState("");
    const [goals, setGoals] = useState("");
    const [trends, setTrends] = useState("");
    const [history, setHistory] = useState("");
    const [finance, setFinance] = useState("");
    const [comps, setComps] = useState("");
    const [addInfo, setAddInfo] = useState("");
    const [tone, setTone] = useState(null);
    
    // Helper functions
    function setPropertyDetailAtIndex(index, newVal) {
        const newTemp = [...propertyDetails];
        newTemp[index].value = newVal;
        setPropertyDetails(newTemp);
    }

    async function generateCompose() {
        const resp = await api_highlightInvest({
            pt: propertyType,
            pa: addressInfo,
            bed: propertyDetails[0].value,
            bath: propertyDetails[1].value,
            lev: propertyDetails[2].value,
            sf: propertyDetails[3].value,
            ls: propertyDetails[4].value,
            gar: propertyDetails[5].value,
            by: propertyDetails[6].value,
            roi: roi,
            ig: goals,
            rt: trends,
            ph: history,
            rf: risk,
            fo: finance,
            cp: comps,
            ai: addInfo
        });
        switch (resp.status) {
            case 200:
                const msg = await resp.json();
                return msg.c;
            case 401:
                showUpgrade('Compose');
                return "Compose is a Team feature. Please upgrade your plan to continue.";
            default:
                showError('An unexpected error occurred.');
                return "Sorry, an unexpected error occurred.";
        }
    }

    // Return layout
    return (
        <>
            {(props.stage === 0) &&
                <>
                    <AI_Single_Select val={propertyType} setVal={setPropertyType} allValues={allPropertyTypes} title="Property Type" includeOther={true} otherTitle="Please specify Property Type" otherPlaceholder="e.g. Semi-Detached" />
                    <AI_Input val={addressInfo} setVal={setAddressInfo} title="Property Address" placeholder="e.g. 123 Main Street" />
                    <AI_PropertyDetails vals={propertyDetails} setValAtIndex={setPropertyDetailAtIndex} />
                    <AI_Textbox val={roi} setVal={setRoi} title="Potential ROI Factors" placeholder="e.g. Proximity to top-rated schools, an upcoming city park project, and potential for a basement apartment conversion." description="List factors impacting ROI such as location, rental income, renovations, etc." />
                    <AI_Textbox val={risk} setVal={setRisk} title="Potential Risk Factors" placeholder="e.g. Older plumbing and electrical systems may require updating, and the neighborhood is undergoing rapid changes." description="Identify property risks such as maintenance, market volatility, etc." />
                    <AI_Textbox val={goals} setVal={setGoals} title="Investment Goals" placeholder="e.g. Client seeks long-term rental income, targeting a 6% annual return on investment." description="Outline the client's investment objectives." />
                    <AI_Textbox val={trends} setVal={setTrends} title="Relevant Trends" placeholder="e.g. Strong demand for rental properties in the area due to its proximity to a newly planned tech hub." description="Highlight current market trends or local factors relating to this investment." />
                    <AI_Textbox val={history} setVal={setHistory} title="Property History" placeholder="e.g. Built in 1995, recent upgrades include a new roof (2021), and well-documented maintenance records." description="Include details on any relevant renovations, upgrades, repairs, etc." />
                    <AI_Textbox val={finance} setVal={setFinance} title="Financing Options" placeholder="e.g. Client pre-approved for a conventional mortgage with a 20% down payment, exploring local banks and credit unions for competitive rates." description="List any available financing choices for the client." />
                    <AI_Textbox val={comps} setVal={setComps} title="Comparable Properties" placeholder="e.g. Three 3-bedroom, 2-bathroom homes in the Willowbrook neighborhood, recent sale prices averaging $320,000, and notable features like hardwood floors and updated kitchens." description="Include information on similar investments." />
                    <AI_Textbox val={addInfo} setVal={setAddInfo} title="Additional Information" description="Any other relevant information you’d like to include in the analysis." />
                    <AI_Tone val={tone} setVal={setTone} last={true} />
                </>
            }
            {(props.stage === 1) && <FinishedCompose {...{generate: generateCompose}} />}
        </>
    );
}

export default Highlight_InvestBenefits;