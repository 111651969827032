// Date Options
var timeOptions = { year: 'numeric', month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric' };
var longOptions = { year: 'numeric', month: 'long', day: 'numeric' };
var shortOptions = { year: 'numeric', month: 'short', day: 'numeric' };

function formatDate(date_obj, type) {
  if (type === 'time') {
    // Ex. 4:30 PM
    const dp = (Intl.DateTimeFormat('en-US', timeOptions)).formatToParts(date_obj);
    return `${dp[6].value}:${dp[8].value} ${dp[10].value}`;

  } else if (type === 'long') {
    // Ex. October 14, 2023
    const dp = (Intl.DateTimeFormat('en-US', longOptions)).formatToParts(date_obj);
    return `${dp[0].value} ${dp[2].value}, ${dp[4].value}`;

  } else if (type === 'short') {
    // Ex. Oct. 13 (same year) or Oct. 13, '19 (past year)
    const dp = (Intl.DateTimeFormat('en-US', shortOptions)).formatToParts(date_obj);
    if (dp[4].value !== `${(new Date().getFullYear())}` ) return `${dp[0].value}. ${dp[2].value}, '${dp[4].value.slice(-2)}`;
    else return `${dp[0].value}. ${dp[2].value}`;
  }
}

export default formatDate;
