// Dependencies
import { useState, useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import Select from 'react-select';

// API Imports
import { getListings } from "../../../apicalls/listings.js";

// Utils Imports
import { useError } from '../../ui/Context/ErrorContext';
import { shortenAddress } from "../../../utils/shortenAddress.js";
import getPhotoUrlFromId from "../../../utils/getPhotoUrlFromId.js";
import setTitleText from "../../../utils/setTitleText.js";

// SVG Imports
import pin_icon from "../../../img/svg/listing/pin.svg";
import house_orange from "../../../img/svg/topbar/house_orange.svg";
import search_icon from "../../../img/svg/topbar/search_icon.svg";

// Constants

// Custom styling for select boxes
const selectStyle = {
    control: (styles, { isFocused }) => ({
        ...styles,
        borderColor: isFocused ? 'var(--orange-accent)' : styles.borderColor,
        boxShadow: (isFocused) ? '0 0 0 1px var(--orange-accent)' : styles.boxShadow,
        '&:hover': {
            borderColor: 'var(--orange-accent)'
        }
    }),
    option: (provided, state) => ({
        ...provided,
        fontFamily: 'Inter',
        fontSize: '15px',
        backgroundColor: state.isFocused ? 'var(--orange-accent-lite)' : null,
        color: state.isSelected ? 'white' : 'black',
        ':active': {
        backgroundColor: 'var(--orange-accent)'
        },
    }),
    multiValue: (styles) => ({
        ...styles,
        backgroundColor: 'var(--grey-2)',
    }),
    multiValueLabel: (styles) => ({
        ...styles,
        color: 'black',
        fontFamily: 'Inter',
        fontSize: '13px'
    }),
    multiValueRemove: (styles) => ({
        ...styles,
        color: 'var(--grey-4)',
        marginLeft: '3px',
        ':hover': {
            backgroundColor: 'var(--delete-bg)',
            color: 'var(--delete-fg)',
        }
    }),
    noOptionsMessage: (styles) => ({
        ...styles,
        fontSize: '15px',
        fontFamily: 'Inter',
        color: 'var(--grey-4)'
    })
};

// Helper Component to layout all listings
function ListingsLayout(props) {
    return (
        <>
            <div className="dash-result-d flex">
                <h2>Results</h2>
                <div className="dash-result-num">
                    <h3>{props.listings.length || "0"}</h3>
                </div>
            </div>
            <div className="lst-listing-cont">
                {props.listings.map((listing, i)=>{
                    return (
                        <div className="lst-listing-card iflex" key={`listing-${i}`} onClick={()=>{props.navigateTo(`/listings/${listing.code}`)}}>
                            {(listing.photo_id) ?  <img src={getPhotoUrlFromId(listing.photo_id)} alt="" /> : <div className="placeholder" />}
                            <div className="r">
                                <h2>{shortenAddress(listing.address_1) + (listing.address_2 ? (`, ${listing.address_2}`) : "")}</h2>
                                <div className="iflex">
                                    <img src={pin_icon} />
                                    <h3>{listing.city}</h3>
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>
        </>
    );
}

// Functional Component
function ListingsHome() {
    setTitleText("Listings");
    const { showError } = useError();

    // Page states
    const navigate = useNavigate();
    const [didLoad, setLoaded] = useState(false);
    const [loaderOn, setLoaderOn] = useState(false);

    // Data states
    const [listings, setListings] = useState(null);
    const [locations, setLocations] = useState(null);
    const [categories, setCategories] = useState(null);

    // Input states
    const [searchInput, setSearchInput] = useState("");
    const [filterCategories, setFilterCategories] = useState([]);
    const [filterLocations, setFilterLocations] = useState([]);

    // Load listings for user
    async function loadPage() {
        const resp = await getListings();
        setTimeout(()=>{setLoaderOn(true)}, 500); // Turn preloader on after 0.5s
        if (resp.status !== 200) {
            showError('An unexpected error occurred.'); 
        } else {
            // Extract listings
            const listings = await resp.json();
            setListings(listings);

            // Get unique locations & categories
            const citySet = new Set(); var tempLoc = [];
            const categorySet = new Set(); var tempCat = [];
            listings.forEach(listing => {
                if (!citySet.has(listing.city)) {
                    citySet.add(listing.city);
                    tempLoc.push({ value: listing.city, label: listing.city });
                }
                if (listing.category && !categorySet.has(listing.category)) {
                    categorySet.add(listing.category);
                    tempCat.push({ value: listing.category, label: listing.category_name });
                }
            });
            setLocations(tempLoc); setCategories(tempCat);

            // Trigger page load
            setLoaded(true);
        }
    }
    
    // Load listings on page load
    useEffect(()=>{
        loadPage();
    },[]);


    // Return layout
    return (
        <>
            <div className="tb-cont">
                <div className="tb-top">
                    <div className="iflex">
                        <img className="tb-t-icon" src={house_orange} alt="" />
                        <h1 className="pg-t">Listings</h1>
                    </div>
                    <div className="lst-dsh-tools iflex">
                        <label>
                            <img src={search_icon} alt="" />
                            <input placeholder="Search listings..." onChange={(e)=>{setSearchInput(e.target.value)}} />
                        </label>
                        <Select isClearable={true} options={didLoad ? categories : null} onChange={(e)=>{setFilterCategories(e.map(option => option.value))}} disabled={didLoad ? ((categories.length > 0) ? false : true) : true} isMulti={true} styles={selectStyle} placeholder="All Categories" noOptionsMessage={(_) => "No categories found"} />
                        <Select isClearable={true} options={didLoad ? locations : null} onChange={(e)=>{setFilterLocations(e.map(option => option.value))}} disabled={didLoad ? ((locations.length > 0) ? false : true) : true} isMulti={true} styles={selectStyle} placeholder="All Locations" noOptionsMessage={(_) => "No locations found"}  />
                        <NavLink to="/listings/new"><h3>Create</h3></NavLink>
                    </div>
                </div>
            </div>
            <div className="dash-cont">
                {(didLoad) ?
                    <ListingsLayout {...{navigateTo: (link)=>{navigate(link, {replace:false})}, listings:
                        listings.filter(listing => {
                            const address = listing.address_1 + (listing.address_2 ? `, ${listing.address_2}` : "");
                            return(
                                address.toLowerCase().includes(searchInput.toLowerCase())
                                && (filterLocations.length > 0 ? filterLocations.includes(listing.city) : true)
                                && (filterCategories.length > 0 ? filterCategories.includes(listing.category) : true)
                            );
                        })
                    }} />
                    :
                    <>{(loaderOn) && <div className="prl-spin-cont"><div className="prl-spin" /></div>}</>
                }
            </div>
        </>
    );
}

export default ListingsHome;