// Dependencies
import { useState, useEffect, createContext } from "react";
import { BrowserRouter as Router, Route, Routes, Outlet, Navigate } from "react-router-dom";

// API Imports
import { getAuthStatus } from "./apicalls/auth.js";

// Utils
import ScrollToTop from "./utils/ScrollToTop";

// View Components
import AutoLayout from "./comp/ui/AutoLayout";
import {
  Landing, ContactUs, ContactEnterprise, Pricing,
  SignUp, VerifyEmail, ChoosePlan, PaymentRedirect, Onboarding,
  Login,
  ListingsHome, ListingsCreate, ListingsManage,
  HouseSignIn,
  LeadsHome, LeadsProfile,
  AutomationsHome, AutomationsCreate, AutomationsManage,
  ComposeHome,
  Settings,
  NotFound_404, ServerError_500,
} from "./comp";

/*
-----------
Auth States
-----------
- 0 = Public (Not logged in)
- 1 = Email (Logged in, email not verified)
- 2 = No Plan (Logged in, email verified but not subscribed)
- 3 = Private (Logged in, email verified & subscribed to plan)
*/

export const VerifyEmailContext = createContext();

function VerifyRouter({ type }) {
  const [authStatus, setAuthStatus] = useState(-1);
  const [emailAddress, setEmailAddress] = useState("");

  useEffect(() => {
    async function getStatus() {
      try {
        const resp = await getAuthStatus();
        switch (resp.status) {
          case 200:
            setAuthStatus(3);
            break;
          case 400:
            const data = await resp.json();
            if (data.msg === "email") {
              setEmailAddress(data.data);
              setAuthStatus(1);
            } else setAuthStatus(2);
            break;
          default:
            setAuthStatus(0);
            break;
        }
      } catch (err) {
        setAuthStatus(-2);
      }
    }
    getStatus();
  }, []);

  if (authStatus === -1) return (<></>); // Wait for result
  if (authStatus === -2) return (<ServerError_500 />); // Auth error page
  // Handle verify email page separately to get email address
  else if (authStatus === 1) return(<VerifyEmailContext.Provider value={emailAddress}><VerifyEmail /></VerifyEmailContext.Provider>);
  // Else, handle other routes normally
  else if (authStatus === type) return (<Outlet />);
  else if (authStatus === 0) return(<Navigate to="/login"/>);
  else if (authStatus === 2) return(<Navigate to="/welcome/plan"/>);
  else if (authStatus === 3) return(<Navigate to="/listings"/>);
}

// Functional Component
function App() {
  return (
    <Router>
      <ScrollToTop />
      <Routes>
        <Route element={<VerifyRouter type={0} />}>
          <Route exact path="/login" element={<Login />} />
          <Route exact path="/signup" element={<SignUp />} />
        </Route>
        <Route element={<VerifyRouter type={1} />}>
          <Route exact path="/welcome/verify" element={<VerifyEmail />} />
        </Route>
        <Route element={<VerifyRouter type={2} />}>
          <Route exact path="/welcome/plan" element={<ChoosePlan />} />
        </Route>
        <Route element={<VerifyRouter type={3} />}>
          <Route exact path="/welcome/onboard" element={<Onboarding />} />
          <Route path="/visit/:id" element={<HouseSignIn />} />
          <Route exact path="/" element={<Navigate to="/listings" />} />
          <Route exact path="/dashboard" element={<Navigate to="/listings" />} />
          <Route exact path="/home" element={<Navigate to="/listings" />} />
          <Route exact path="/listings" element={<AutoLayout><ListingsHome /></AutoLayout>} />
          <Route exact path="/listings/new" element={<AutoLayout><ListingsCreate /></AutoLayout>} />
          <Route path="/listings/:id" element={<AutoLayout><ListingsManage /></AutoLayout>} />
          <Route exact path="/leads" element={<AutoLayout><LeadsHome /></AutoLayout>} />
          <Route path="/leads/:id" element={<AutoLayout><LeadsProfile /></AutoLayout>} />
          <Route exact path="/automations" element={<AutoLayout><AutomationsHome /></AutoLayout>} />
          <Route exact path="/automations/new" element={<AutoLayout><AutomationsCreate /></AutoLayout>} />
          <Route exact path="/automations/:id" element={<AutoLayout><AutomationsManage /></AutoLayout>} />
          <Route exact path="/compose" element={<AutoLayout><ComposeHome /></AutoLayout>} />
          <Route exact path="/settings" element={<AutoLayout><Settings /></AutoLayout>} />
          <Route path="/billed" element={<PaymentRedirect />} />
        </Route>
        <Route path="/*" element={<NotFound_404 />} />
      </Routes>
    </Router>
  );
}

export default App;
