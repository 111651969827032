// Dependencies
import { useState } from "react";
import { useNavigate } from "react-router-dom";

// Util Imports
import { useError } from '../../../ui/Context/ErrorContext';

// API Imports
import { deleteListingWithCode } from "../../../../apicalls/listings";

// SVG Imports
import back_icon from "../../../../img/svg/listing/back_chevron.svg";
import del_list_orange from "../../../../img/svg/topbar/del_list_orange.svg";


// Functional Component
function DeleteListing(props) {
	const navigate = useNavigate();
	const { showError } = useError();

	// Data States
	const [deleteCode, _sdc] = useState(Array.from({ length: 6 }, () => 'ABCDEFGHJKMNPQRSTUVWXYZabcdefghjkmnopqrstuvwxyz23456789'[Math.floor(Math.random() * 58)]).join(''));
	const [deleteInput, setDeleteInput] = useState("");

	// Delete wrapper
	async function handleDeleteListing() {
		if (deleteInput !== deleteCode) return;
		const resp = await deleteListingWithCode(props.listing_code);
		if (resp.status !== 200) showError('An unexpected error occurred.');
		else navigate('/listings', {replace: true});
	}

	// Return layout
	return (
		<div className="pop-pg-cont">
			<button className="lead-prof-back-b" onClick={()=>{props.setPage(0)}}>
				<img src={back_icon} alt="" />
			</button>
			<div className="pop-pg-t flex">
				<img className="tb-t-icon" src={del_list_orange} alt="" />
				<h1 className="pg-t">Delete Listing</h1>
			</div>
			<div className="content-spacer" />
			<div className="lst-new-i-d">
				<h2>Are you sure?</h2>
				<h3 className="intg-mng-hs">Continuing will permanently delete all data associated with this listing. This includes all leads, integrations, sign-in questions, address details, and any other data you created or visitors submitted. There is no way to recover this data.</h3>
			</div>
			<div className="lst-new-i-d mb0">
				<h2>If you are sure, type "{deleteCode}" below (without quotation marks):</h2>
				<input className="intg-mng-in" value={deleteInput || ""} onChange={(e)=>{setDeleteInput(e.target.value)}} />
			</div>
			<div className="dash-cont-b-d iflex">
				<button className="back" onClick={()=>{props.setPage(0)}}>&lt;- No, take me back!</button>
				<button
					className={"next" + ((deleteInput !== deleteCode) ? " hidden" : "")}
					onClick={handleDeleteListing}
				>Yes, delete this listing.</button>
			</div>
		</div>
	);
}

export default DeleteListing;