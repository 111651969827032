// Dependencies
import { useState, useEffect, useRef } from "react";

// SVG Imports
import back_icon from "../../img/svg/listing/back_chevron.svg";
import x_icon from "../../img/svg/signin/x_icon.svg";
import yn_icon from "../../img/svg/signin/yn_icon_lrg.svg";
import sc_icon from "../../img/svg/signin/sc_icon_lrg.svg";
import mc_icon from "../../img/svg/signin/mc_icon_lrg.svg";
import oe_icon from "../../img/svg/signin/oe_icon_lrg.svg";

// Functional Component
function NewQuestionModal(props) {
    const [page, setPage] = useState(0);
    const [questionType, setQuestionType] = useState(-1);
    const [questionText, setQuestionText] = useState("");
    const [questionOptions, setQuestionOptions] = useState(["",""]);

    // For auto-resizing question text input
    const qAreaRef = useRef(null);
    const [qAreaRows, setQAreaRows] = useState(1);
    useEffect(() => {
        if (page !== 1) return;
        // Set the number of rows to match the content
        if (qAreaRef.current) {
            qAreaRef.current.style.height = 'auto';
            const lineHeight = 20;
            const newRows = Math.floor((qAreaRef.current.scrollHeight - lineHeight) / lineHeight);
            setQAreaRows(newRows < 1 ? 1 : newRows);
        }
    }, [questionText]);

    // Handle finishing new question
    function handleSubmit() {
        if (questionText.length === 0 || ((questionType === 1 || questionType === 2) && !questionOptions.some(s => s.length > 0))) return;
        if (questionType === 0 || questionType === 3) props.addQuestion({type: questionType, text: questionText});
        if (questionType === 1 || questionType === 2) props.addQuestion({type: questionType, text: questionText, options: questionOptions.filter(s => s.trim().length > 0)});
        props.closeModal();
    }

    // Return layout
    return(
        <div className="nqmod-bg">
            <div className="nqmod-cont">
                <div className="nqmod-top iflex">
                    {(page === 0) &&
                        <h2>Add Question</h2>
                    }
                    {(page === 1) &&
                        <div className="iflex">
                            <button onClick={()=>{setPage(0); setQuestionType(-1); setQuestionText(""); setQuestionOptions(["",""]); }}><img src={back_icon} alt="" /></button>
                            <div>
                                <h2>{((questionType === 0) ? "Yes / No" : ((questionType === 1) ? "Single Select" : ((questionType === 2) ? "Multi Select" : "Open Ended")))}</h2>
                                <h3>Go Back</h3>
                            </div>
                        </div>
                    }
                    <button onClick={()=>{props.closeModal()}}><img src={x_icon} alt="" /></button>
                </div>
                <div className="nqmod-spacer" />
                <div className="nqmod-btm">
                    {(page === 0) &&
                        <>
                            <button 
                                onClick={()=>{if (questionType === 0) setQuestionType(-1); else setQuestionType(0)}}
                                className={"nqmod-type-b"+((questionType === 0) ? " active" : ((questionType === -1) ? "" : " hidden"))}
                            >
                                <img src={yn_icon} alt="" />
                                <h2>Yes / No</h2>
                                <h3>Answer yes or no</h3>
                            </button>
                            <button
                                onClick={()=>{if (questionType === 1) setQuestionType(-1); else setQuestionType(1)}}
                                className={"nqmod-type-b"+((questionType === 1) ? " active" : ((questionType === -1) ? "" : " hidden"))}
                            >
                                <img src={sc_icon} alt="" />
                                <h2>Single Select</h2>
                                <h3>Choose one answer</h3>
                            </button>
                            <button
                                onClick={()=>{if (questionType === 2) setQuestionType(-1); else setQuestionType(2)}}
                                className={"nqmod-type-b"+((questionType === 2) ? " active" : ((questionType === -1) ? "" : " hidden"))}
                            >
                                <img src={mc_icon} alt="" />
                                <h2>Multi Select</h2>
                                <h3>Choose many answers</h3>
                            </button>
                            <button
                                onClick={()=>{if (questionType === 3) setQuestionType(-1); else setQuestionType(3)}}
                                className={"nqmod-type-b l"+((questionType === 3) ? " active" : ((questionType === -1) ? "" : " hidden"))}
                            >
                                <img src={oe_icon} alt="" />
                                <h2>Open Ended</h2>
                                <h3>Write your own answer</h3>
                            </button>
                            <button
                                disabled={questionType === -1}
                                className={"nqmod-lrg-b"+((questionType === -1) ? " hidden" : "")}
                                onClick={()=>{if (questionType !== -1) setPage(1)}}
                            >
                                Continue
                            </button>
                        </>
                    }
                    {(page === 1) &&
                        <>
                            <h3 className="nqmod-sbt">Question Text</h3>
                            <textarea
                                ref={qAreaRef}
                                rows={qAreaRows}
                                className="nqmod-qt-ta"
                                placeholder="Your question here..."
                                value={questionText}
                                maxLength={255}
                                onChange={(e)=>{setQuestionText(e.target.value || "")}} />
                        </>
                    }
                    {(page === 1 && (questionType === 1 || questionType === 2)) &&
                        <>
                            <h3 className="nqmod-sbt mt">Question Options</h3>
                            {questionOptions.map((option, i)=>(
                                <input
                                    key={`qopt-${i}`}
                                    className="nqmod-qopt-in"
                                    placeholder={`Option #${i+1}`}
                                    value={option}
                                    maxLength={55}
                                    onChange={(e)=>{
                                        var temp = [...questionOptions];
                                        temp[i] = e.target.value || "";
                                        setQuestionOptions(temp)
                                    }} />
                            ))}
                            {(questionOptions.length < 6) &&
                                <button className="nqmod-qopt-add" onClick={()=>{
                                    var temp = [...questionOptions];
                                    temp.push("");
                                    setQuestionOptions(temp);
                                }}>Add Option</button>
                            }
                        </>
                    }
                    {(page === 1) &&
                        <button
                            className={"nqmod-lrg-b"+ ((questionText.length === 0 || ((questionType === 1 || questionType === 2) && !questionOptions.some(s => s.length > 0))) ? " hidden" : "")}
                            disabled={questionText.length === 0 || ((questionType === 1 || questionType === 2) && !questionOptions.some(s => s.length > 0))}
                            onClick={handleSubmit}
                        >Continue</button>
                    }

                </div>
            </div>
        </div>    
    );
}

export default NewQuestionModal;