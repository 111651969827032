// Dependencies
import { useState } from "react";

// API Imports
import { changeUserPassword } from "../../../apicalls/auth.js";

// Util Imports
import { useError } from '../../ui/Context/ErrorContext';

// SVG Imports
import back_icon from "../../../img/svg/listing/back_chevron.svg";
import key_orange from "../../../img/svg/topbar/key_orange.svg";


// Functional Component
function ChangePass(props) {
	const { showError } = useError();

	// Data states
	const [newpass_a, setNewPass_a] = useState("");
	const [newpass_b, setNewPass_b] = useState("");

	// Update password wrapper
	async function handleUpdatePass() {
		const resp = await changeUserPassword(newpass_a);
		if (resp.status !== 200) showError('An unexpected error occurred.');
		else props.setPage(0);
	}
	
	// Return layout
	return (
		<div className="pop-pg-cont">
			<button className="lead-prof-back-b" onClick={()=>{props.setPage(0)}}>
				<img src={back_icon} alt="" />
			</button>
			<div className="pop-pg-t flex">
				<img className="tb-t-icon" src={key_orange} alt="" />
				<h1 className="pg-t">Change Password</h1>
			</div>
			<div className="content-spacer" />
			<div className="lst-new-i-d">
				<h2>New Password</h2>
				<input type="password" placeholder="••••••••" className="intg-mng-in" value={newpass_a || ""} onChange={(e)=>{setNewPass_a(e.target.value)}} />
			</div>
			<div className="lst-new-i-d mb0">
				<h2>Re-Enter New Password</h2>
				<input type="password" placeholder="••••••••" className="intg-mng-in" value={newpass_b || ""} onChange={(e)=>{setNewPass_b(e.target.value)}} />
			</div>
			<div className="dash-cont-b-d iflex">
				<button
					className={"next" + ((newpass_a.length <= 0 || newpass_a !== newpass_b) ? " hidden" : "")}
					disabled={newpass_a.length <= 0 || newpass_a !== newpass_b}
					onClick={handleUpdatePass}
				>Save Changes</button>
			</div>
		</div>
	);
}

export default ChangePass;