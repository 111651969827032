// Dependencies
import { createRoot } from 'react-dom/client';

// Component Imports
import App from './App';
import { ErrorProvider, ErrorDisplay } from "./comp/ui/Context/ErrorContext";
import { UpgradeProvider, UpgradeDisplay } from './comp/ui/Context/UpgradeContext';

// Style Imports
import './style.css';

// Render app
const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  //<React.StrictMode >
    <ErrorProvider>
      <UpgradeProvider>
        <App />
        <ErrorDisplay />
        <UpgradeDisplay />
      </UpgradeProvider>
    </ErrorProvider>,
  //</React.StrictMode>,
);