export function prettyFileSize(bytes) {
	var size = bytes;
    const units = ['B', 'KB', 'MB'];
    var unitIndex = 0;

    while (size >= 1000 && unitIndex < units.length) {
        size /= 1000;
        unitIndex++;
    }

    return `${size.toFixed(2)} ${units[unitIndex]}`;
}