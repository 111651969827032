// Component Imports
import Sidebar from "./Sidebar";

// Functional Component
function AutoLayout({ children }) {
    // Return layout
    return(
        <div className="content iflex">
            <Sidebar />
            <div className="cont-r">
                {children}
            </div>
        </div>
    )
}

export default AutoLayout;