// Dependencies
import { useState, useEffect } from "react";

// API Imports
import { getLeadsWithCode } from "../../../../apicalls/leads.js";

// Util Imports
import formatDate from "../../../../utils/formatDate";
import { formatPhoneNumber } from "../../../../utils/formatPhone.js";
import { useError } from '../../../ui/Context/ErrorContext';

// SVG Imports
import sort_icon from "../../../../img/svg/listing/sort.svg";
import filter_icon from "../../../../img/svg/listing/filter.svg";
import export_icon from "../../../../img/svg/listing/export.svg";
import email_icon from "../../../../img/svg/listing/email_orange.svg";
import phone_icon from "../../../../img/svg/listing/phone_orange.svg";
import calendar_icon from "../../../../img/svg/listing/calendar_small.svg";
import search_icon from "../../../../img/svg/topbar/search_icon.svg";

// Functional Component
function Leads(props) {
    const { showError } = useError();

    // Page states
    const [didLoad, setDidLoad] = useState(false);
    const [loaderOn, setLoaderOn] = useState(false); 
    const [searchInput, setSearchInput] = useState("");

    // Render rows of leads
    function renderLeads(leads) {
        if (leads.length === 0) {
            return (
                <div className="lds-empty">
                    <div>
                        <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 35 35">
                            <g><rect width="35" height="35" rx="5" fill="#ffe2dd"/></g>
                            <g transform="translate(9.297 9)">
                                <path d="M19.406,11.2A8.2,8.2,0,1,1,11.2,3a8.2,8.2,0,0,1,8.2,8.2Z" transform="translate(-3 -3)" fill="none" stroke="#ff5c3c" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.2"/>
                                <path d="M12,21a4.429,4.429,0,0,0,3.281,1.641A4.429,4.429,0,0,0,18.563,21" transform="translate(-7.078 -11.156)" fill="none" stroke="#ff5c3c" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.2"/>
                                <path d="M13.5,13.5h.008" transform="translate(-7.758 -7.758)" fill="none" stroke="#ff5c3c" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
                                <path d="M22.5,13.5h.008" transform="translate(-11.836 -7.758)" fill="none" stroke="#ff5c3c" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
                            </g>
                        </svg>
                        <h1>No leads found</h1>
                        <h3>We couldn't find any leads matching your search criteria.</h3>
                    </div>
                </div>
            );
        }

        return (
            <>
                <div className="dash-result-d flex">
                    <h2>Results</h2>
                    <div className="dash-result-num">
                        <h3>{leads.length || "0"}</h3>
                    </div>
                </div>
                <div className="lds-card-cont">
                    {leads.map((lead) => {
                        return (
                            <button key={`lead-${lead.id}`} value={lead.id} onClick={(e)=>{props.openLead(e.target.value)}} className="lds-card-b">
                                <div className="lds-card-t iflex" value={lead.id}>
                                    <div className="lds-card-pf" value={lead.id}>
                                        <h1>{lead.name.match(/\b\w/g).slice(0, 2).join('').toUpperCase()}</h1>
                                    </div>
                                    <div className="lds-card-nm" value={lead.id}>
                                        <h2>{lead.name}</h2>
                                        <div className="iflex">
                                            {(lead.email && lead.email.length > 0) ?
                                                <>
                                                    <img src={email_icon} alt="" />
                                                    <h3>{lead.email}</h3>
                                                </>
                                                :
                                                ((lead.phone && lead.phone.length > 0) ?
                                                    <>
                                                        <img src={phone_icon} alt="" />
                                                        <h3>{formatPhoneNumber(lead.phone)}</h3>
                                                    </>
                                                    :
                                                    <>
                                                        <h3 className="hidden">No Contact Info</h3>
                                                    </>
                                                )
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="lds-card-spacer" value={lead.id}></div>
                                <div className="lds-card-more iflex" value={lead.id}>
                                    <div className="lds-card-place iflex" value={lead.id}>
                                        <img src={calendar_icon} alt="" />
                                        <h3>Visited {formatDate(new Date(lead.visited * 1000), 'long')}</h3>
                                    </div>
                                </div>
                            </button>
                        );
                    })}
                </div>
            </>
        );
    }

    // Load leads
    async function loadLeads() {
        const leads_resp = await getLeadsWithCode(props.listing_code);
        setTimeout(()=>{setLoaderOn(true)}, 500); // Turn preloader on after 0.5s
        if (leads_resp.status !== 200) {
            showError('An unexpected error occurred.');
        } else {
            const leads_data_raw = await leads_resp.json();
            const leads_data = leads_data_raw.map((l) => {
                return {...l , address_1: props.listingAddress[0], address_2: props.listingAddress[1]};
            })
            props.setFilteredLeads(leads_data);
            props.setLeads(leads_data);
            setDidLoad(true);
        }
    }
    // Load leads on page load
    useEffect(()=>{
        if (!props.leads || props.leads.length === 0) loadLeads();
        else if (props.leads) setDidLoad(true);
    },[]);

    // Return layout
    return (
        <>
            {(didLoad && props.leads) ? 
                <>
                    <div className="lst-new-i-d">
                        <div className="iflex">
                            <label className="lst-mng-leads-search">
                                <img src={search_icon} alt="" />
                                <input type="text" placeholder="Search leads..." value={searchInput || ""} onChange={(e)=>{setSearchInput(e.target.value)}} />
                            </label>
                            {/* <button className="lst-mng-leads-b sort"><img src={sort_icon} alt="" />Sort</button> */}
                            <button className="lst-mng-leads-b filter" onClick={props.openFilterLeads}><img src={filter_icon} alt="" />Filter</button>
                            <button className="lst-mng-leads-b export" onClick={props.openExportLeads}><img src={export_icon} alt="" />Export</button>
                        </div>
                    </div>
                    <div className="content-spacer" />
                        { renderLeads(props.filteredLeads.filter(lead => { return(lead.name.toLowerCase().includes(searchInput.toLowerCase())); }))}
                </>
                :
                <>{(loaderOn) && <div className="prl-spin-cont"><div className="prl-spin" /></div>}</>
            }
        </>
    );
}

export default Leads;