// Dependencies
import { useState } from 'react';

// API Imports
import { api_explainJargon } from "../../../apicalls/compose.js";

// Component Imports
import { AI_Single_Select } from '../comp/Select';
import AI_Textbox from "../comp/Textbox";
import AI_Tone from "../comp/Tone";
import FinishedCompose from '../comp/FinishedCompose.jsx';

// Util Imports
import { useError } from '../../ui/Context/ErrorContext';
import { useUpgrade } from '../../ui/Context/UpgradeContext';

// Constants
const allComplexityLevels = ["Very Basic", "Basic", "Moderate", "Expert"];


// Functional Component
function Explain_Jargon(props) {
    const { showError } = useError();
    const { showUpgrade } = useUpgrade();

    // Data states
    const [terms, setTerms] = useState("");
    const [contextInfo, setContextInfo] = useState("");
    const [complexity, setComplexity] = useState(null);
    const [addInfo, setAddInfo] = useState("");
    const [tone, setTone] = useState(null);

    // Helper functions
    async function generateCompose() {
        const resp = await api_explainJargon({
            st: terms,
            ci: contextInfo,
            ds: complexity,
            ai: addInfo,
        });
        switch (resp.status) {
            case 200:
                const msg = await resp.json();
                return msg.c;
            case 401:
                showUpgrade('Compose');
                return "Compose is a Team feature. Please upgrade your plan to continue.";
            default:
                showError('An unexpected error occurred.');
                return "Sorry, an unexpected error occurred.";
        }
    }

    // Return layout
    return (
        <>
            {(props.stage === 0) &&
                <>
                    <AI_Textbox val={terms} setVal={setTerms} title="Target Terms" placeholder="e.g. Title Insurance" description="Which specific real estate terms / concepts should be clarified?" />
                    <AI_Textbox val={contextInfo} setVal={setContextInfo} title="Contextual Information" description="Is there any context that is relevant to the explanation? For example, the client’s specific question." />
                    <AI_Single_Select val={complexity} setVal={setComplexity} allValues={allComplexityLevels} title="Complexity Level" description="How basic or complex should the explanation be?" />
                    <AI_Textbox val={addInfo} setVal={setAddInfo} title="Additional Information" description="Any other relevant information you’d like to include in the explanation." />
                    <AI_Tone val={tone} setVal={setTone} last={true} />
                </>
            }
            {(props.stage === 1) && <FinishedCompose {...{generate: generateCompose}} /> }
        </>
    );
}

export default Explain_Jargon;