/*
--------------------------
> INTEGRATIONS API CALLS <
--------------------------
*/

const prefix = `${(process.env.REACT_APP_ENV_MODE === "development") ? process.env.REACT_APP_API_URL_DEV : process.env.REACT_APP_API_URL_PROD}/integrations`;


// Fetches integrations for listing with given code.
export async function getIntegrationsWithCode(listing_code) {
    return await fetch(prefix+`/for/${listing_code}`, {
        method: 'GET', credentials: 'include'
    });
}

// Fetches specific integration with id
export async function getIntegrationWithId(listing_code, integration_id) {
    return await fetch(prefix+`/load/${listing_code}/${integration_id}`, {
        method: 'GET', credentials: 'include'
    });
}

// Creates integration with given ID.
export async function handleCreateIntegrationWithCode(listing_code, type, target_1, target_2) {  
    const resp = await fetch(prefix+`/create/${listing_code}`, {
        method: 'POST', credentials: 'include',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ info: {type: type, target_1: target_1, target_2: target_2} })
    });
    return resp.status === 200;
}

export async function handleBulkCreateIntegrationsWithCode(listing_code, integrations) {
    return await fetch(prefix+`/create/bulk/${listing_code}`, {
        method: 'POST', credentials: 'include',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({data: integrations})
    });
}

export async function bulkCreateIntegrationsWithCode(listing_code, intg_list) {
    return await fetch(prefix+`/addbulk/${listing_code}`, {
        method: 'POST', credentials: 'include',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ data: intg_list })
    });
}

// Updates integration with given ID. Returns true/false if success/failed.
export async function handleUpdateIntegrationWithId(listing_code, integration_id, target_1, target_2) {  
    const resp = await fetch(prefix+`/update/${integration_id}/${listing_code}`, {
        method: 'PUT', credentials: 'include',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ info: {target_1: target_1, target_2: target_2} })
    });
    return resp.status === 200;
}

// Deletes integration with given ID. Returns true/false if success/failed.
export async function handleDeleteIntegrationWithId(listing_code, integration_id) {
    const resp = await fetch(prefix+`/forget/${integration_id}/${listing_code}`, {
        method: 'DELETE', credentials: 'include'
    })
    return resp.status === 200;
}

// Fetches specific integration with id
export async function getCredentialsForType(type) {
    return await fetch(prefix+`/keys/load/${type}`, {
        method: 'GET', credentials: 'include'
    });
}

// Fetches specific integration with id
export async function saveCredentialsForType(type, target_1, target_2) {
    const resp = await fetch(prefix+`/keys/save/${type}`, {
        method: 'POST', credentials: 'include',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ info: {target_1: target_1, target_2: target_2} })
    });
    return (resp.status === 200);
}