// Dependencies

// Util Imports
import { renderQuestionList } from "../../../../utils/sharedQuestionFuns.js";
import { getIntegrationLogo, getIntegrationTitle } from "../../../../utils/sharedIntegrationFuns.js";
import { themeNameToNumber } from "../../../../utils/sharedDesignFuns.js";

// SVG Imports
import mailbox_icon from "../../../../img/svg/listing/mailbox_orange.svg";
import pin_icon from "../../../../img/svg/listing/pin_orange.svg";
import map_icon from "../../../../img/svg/listing/map_orange.svg";
import tag_icon from "../../../../img/svg/listing/tag_orange.svg";

// Functional Component
function Review(props) {

	function renderIntegrations(integrations) {
		if (integrations.length === 0) return (<h3 className="ind">No integrations added.</h3>);
		var r = [];
		integrations.forEach((i, index) => {
			r.push(
				<button className="set-int-b" key={`ik-${index}`}>
					<img src={getIntegrationLogo(i.type)} alt="" />
					<h2>{getIntegrationTitle(i.type)}</h2>
				</button>
			);
		});
		return (<div className="set-int-b-cont">{r}</div>);
	}

	// Return layout
	return (
		<>
			<div className="lst-new-rvw-dtl-d">
				<h1>{props.address1 + ((props.address2 && props.address2.length > 0) ? `, ${props.address2}`:"")}</h1>
				<div className="content-spacer" />
				<div className="dtl flex"><img src={mailbox_icon} alt="" /><h3>{props.zip}</h3></div>
				<div className="dtl flex"><img src={pin_icon} alt="" /><h3>{props.city}</h3></div>
				<div className="dtl flex"><img src={map_icon} alt="" /><h3>{props.region}, {props.country}</h3></div>
				{(props.categoryName && props.categoryName.length > 0) && <div className="dtl flex"><img src={tag_icon} alt="" /><h3>{props.categoryName}</h3></div>}
			</div>
			<div className="lst-new-i-d">
				<div className="dash-result-d flex">
					<h2>Photos</h2>
					<div className="dash-result-num">
						<h3>{props.imageUrls.length || "0"}</h3>
					</div>
				</div>
				<div className="img-mng-gal-cont">
					{
						props.imageUrls.map((url, index) => {
							return (
								<div className="img-mng-gal-i" key={`ik-${index}`}>
									<img src={url} alt="" />
								</div>
							);
						})
					}
				</div>
			</div>
			<div className="lst-new-i-d">
				<h2>Questions</h2>
				{renderQuestionList(props.questions)}
			</div>
			<div className="lst-new-i-d">
				<h2>Integrations</h2>
				{renderIntegrations(props.integ)}
			</div>
			<div className="lst-new-i-d mb0">
				<h2>Design</h2>
				<div className="set-int-b-cont">
					<button className="set-int-b">
						<div className={"lst-new-rvw-dsn-c"+` fg${themeNameToNumber(props.fgColor)}`} />
						<h2>{props.fgColor.charAt(0).toUpperCase() + props.fgColor.slice(1)} Foreground</h2>
					</button>
					<button className="set-int-b">
						<div className={"lst-new-rvw-dsn-c"+` bg${themeNameToNumber(props.bgColor,true)}`} />
						<h2>{props.bgColor.charAt(0).toUpperCase() + props.bgColor.slice(1)} Background</h2>
					</button>
				</div>
			</div>
			<div className="dash-cont-b-d iflex">
				<button className="back" onClick={props.goBack}>&lt;- Back</button>
				<button className="finish" onClick={props.goFinish}>Save & Publish</button>
			</div>
		</>
	);
}


export default Review;