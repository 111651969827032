// Dependencies
import { useState, useEffect } from "react";
import Select from "react-select";

// API Imports
import { getUserCategories, updateListingDetailsWithCode } from "../../../../apicalls/listings";

// Util Imports
import uploadPhoto from "../../../../utils/uploadPhoto.js";
import getIdFromPhotoUrl from "../../../../utils/getIdFromPhotoUrl.js";
import { useError } from '../../../ui/Context/ErrorContext';

// SVG Imports
import plus_icon from "../../../../img/svg/listing/plus_circle.svg";
import AddPhotoModal from "../../../modals/AddPhotoModal";


// Functional Component
function Details(props) {
    const { showError } = useError();
    
    // Page States
    const [imageModalOpen, setImageModalOpen] = useState(false);

    // Data States
    const [categories, setCategories] = useState(null);
    const [chosenCategory, setChosenCategory] = useState(props.details.category);
    const [address1, updateAddress1] = useState(props.details.address1);
    const [address2, updateAddress2] = useState(props.details.address2);
    const [city, updateCity] = useState(props.details.city);
    const [zipcode, updateZip] = useState(props.details.zipcode);
    const [country, updateCountry] = useState(props.details.country);
    const [region, updateRegion] = useState(props.details.region);
    const [ogListingPhotos, _solp] = useState([...props.listingPhotos.map(i => {return {...i, status: true}})]);
    const [remainingPhotoCount, setRemainingPhotoCount] = useState(props.listingPhotos.length);
    const [newImageBlobs, setNewImageBlobs] = useState([]);
    const [newImageUrls, setNewImageUrls] = useState([]);
    const imageModalProps = {
        imageBlobs: newImageBlobs,
        setImageBlobs: setNewImageBlobs,
        imageUrls: newImageUrls,
        setImageUrls: setNewImageUrls,
        closeModal: ()=>{setImageModalOpen(false)}
    }

    // Cancel changes
    useEffect(()=>{
        if (props.didCancel) {
            // Reset listing info
            updateAddress1(props.details.address1);
            updateAddress2(props.details.address2);
            updateCity(props.details.city);
            updateZip(props.details.zipcode);
            updateCountry(props.details.country);
            updateRegion(props.details.region);
            setChosenCategory(props.details.category);

            // Reset listing photos
            props.setListingPhotos(ogListingPhotos);
            setRemainingPhotoCount(ogListingPhotos.length);
            setNewImageBlobs([]);
            setNewImageUrls([]);

            // Notify controller
            props.setCancelled(false);
        }
    },[props.didCancel]);

    // Upload changes
    useEffect(()=>{
        async function uploadChanges() {
            // Upload new images
            var newImageIds = [];
            for (var i=0; i<newImageBlobs.length; i++) newImageIds.push(await uploadPhoto(newImageBlobs[i]));

            // Format images to API
            var newOrder = 0;
            const formatted_og_photos = props.listingPhotos.map((o) => { return {id: getIdFromPhotoUrl(o.url), order: (o.status ? newOrder++ : -1)}; });
            const formatted_new_photos = ((newImageIds.length > 0) ? newImageIds.map((id) => { return {id: id, order: newOrder++}; }) : []);

            // Upload changes
            const resp = await updateListingDetailsWithCode(props.listing_code, address1, address2, city, zipcode, country, region, formatted_og_photos.concat(formatted_new_photos), chosenCategory);
            if (resp.status !== 200) {
                showError('An unexpected error occurred.');
            } else {
                // Update images
                setNewImageBlobs([]);
                setNewImageUrls([]);
                setRemainingPhotoCount(formatted_og_photos.filter(o => o.order !== -1).length + formatted_new_photos.length);

                // Notify
                props.setDidSave(2);
                props.notifyChanges(false);
            }
        }
        if (props.didSave === 1) uploadChanges();
    },[props.didSave])

    // Detect changes
    useEffect(()=>{
        if (
            (address1 !== props.details.address1) ||
            (address2 !== props.details.address2) ||
            (city !== props.details.city) ||
            (zipcode !== props.details.zipcode) ||
            (country !== props.details.country) ||
            (region !== props.details.region) ||
            (chosenCategory !== props.details.category) ||
            (props.listingPhotos.filter((o) => !o.status).length > 0) ||
            (newImageUrls.length !== 0)
        ) props.notifyChanges(true);
        else props.notifyChanges(false);
    },[address1,address2,city,zipcode,country,region,chosenCategory,props.listingPhotos,newImageUrls]);

    // Load category options
    useEffect(()=>{
        async function loadCategories() {
            const resp = await getUserCategories();
            if (resp.status !== 200) {
                showError('An unexpected error occurred.');
            } else {
                const cats = await resp.json(); var tempCat = [];
                cats.forEach(cat => {
                    tempCat.push({ value: cat.id, label: cat.category_name });
                });
                setCategories(tempCat);
            }
        }
        loadCategories();
    },[]);

    // "Remove" existing image
    function removeOldImageNum(e) {
        const index = e.currentTarget.value;
        // Mark as delete
        var newPhotos = [...props.listingPhotos]; newPhotos[index].status = false;
        props.setListingPhotos(newPhotos);
        setRemainingPhotoCount(remainingPhotoCount-1);
    }

    // Remove new image
    function removeNewImageNum(e) {
        const index = e.currentTarget.value;
        var newBlobs = [...newImageBlobs]; newBlobs.splice(index,1);
        var newUrls = [...newImageUrls]; newUrls.splice(index,1);
        setNewImageBlobs(newBlobs);
        setNewImageUrls(newUrls);
    }

    // Custom style for selector
    const selectStyle = {
        control: (styles, { isFocused }) => ({
            ...styles,
            borderColor: isFocused ? 'var(--orange-accent)' : styles.borderColor,
            boxShadow: (isFocused) ? '0 0 0 1px var(--orange-accent)' : styles.boxShadow,
            '&:hover': {
                borderColor: 'var(--orange-accent)'
            }
        }),
        option: (provided, state) => ({
            ...provided,
            fontFamily: 'Inter',
            fontSize: '15px',
            backgroundColor: state.isFocused ? 'var(--orange-accent-lite)' : null,
            color: 'black',
            ':active': {
                backgroundColor: 'var(--orange-accent)'
            },
        }),
        noOptionsMessage: (styles) => ({
            ...styles,
            fontSize: '15px',
            fontFamily: 'Inter',
            color: 'var(--grey-4)'
        }),
        singleValue: (styles) => ({
            ...styles,
            fontSize: '15px',
            fontFamily: 'Inter',
            color: 'black'
        })
    };

    // Return layout
    return (
        <>
            {(imageModalOpen) && <AddPhotoModal {...imageModalProps} />}
            <div className="lst-new-i-d">
                <h2>Category</h2>
                <div className="cat-in">
                    <Select isClearable={true} options={categories || null} styles={selectStyle} value={categories ? categories.find((option) => option.value === chosenCategory) : null} onChange={(c)=>{setChosenCategory(c.value || null)}} disabled={categories && categories.length > 0 ? false : true} placeholder="No Category Selected" noOptionsMessage={(_) => "No categories found"} />
                </div>
            </div>
            <div className="lst-new-i-d">
                <h2>Address</h2>
                <div>
                    <input className="half" type="text" placeholder="Street Address" onChange={(e)=>{updateAddress1((e.target.value.length > 0) ? e.target.value : null)}} value={address1 || ""} />
                    <input className="half" type="text" placeholder="Apt #, Suite, Floor, etc." onChange={(e)=>{updateAddress2((e.target.value.length > 0) ? e.target.value : null)}} value={address2 || ""} />
                </div>
                <div>
                    <input className="qrtr" type="text" placeholder="City" onChange={(e)=>{updateCity((e.target.value.length > 0) ? e.target.value : null)}} value={city || ""} />
                    <input className="qrtr" type="text" placeholder="ZIP/Postal Code" onChange={(e)=>{updateZip((e.target.value.length > 0) ? e.target.value : null)}} value={zipcode || ""} />
                    <input className="qrtr" type="text" placeholder="Country" onChange={(e)=>{updateCountry((e.target.value.length > 0) ? e.target.value : null)}} value={country || ""} />
                    <input className="qrtr" type="text" placeholder="Region" onChange={(e)=>{updateRegion((e.target.value.length > 0) ? e.target.value : null)}} value={region || ""} />
                </div>
            </div>
            <div className="lst-new-i-d">
				<div className="dash-result-d flex">
					<h2>Photos</h2>
					<div className="dash-result-num">
						<h3>{(remainingPhotoCount + newImageUrls.length) || "0"}</h3>
					</div>
				</div>
				<div className="img-mng-gal-cont edit">
					{
						props.listingPhotos.filter((o) => o.status).map((obj) => {
							return (
                                <div className="flex" key={`ik-${obj.order}`}>
                                    <div className="img-mng-gal-i">
                                        <img src={obj.url} alt="" />
                                    </div>
                                    <button className="img-mng-gal-del-b" value={obj.order} onClick={removeOldImageNum}>×</button>
                                </div>
							);
						})
					}
                    {
                        newImageUrls.map((url, index) => {
                            return (
                                <div className="flex" key={`nk-${index}`}>
                                    <div className="img-mng-gal-i">
                                        <img src={url} alt="" />
                                    </div>
                                    <button className="img-mng-gal-del-b" value={index} onClick={removeNewImageNum}>×</button>
                                </div>
                            );
                        })
                    }
                    <button className="img-mng-gal-add-b" onClick={()=>{setImageModalOpen(true)}}>
                        <img src={plus_icon} alt="" />
                        <h2>Add Photo</h2>
                    </button>
				</div>
			</div>
            <div className="lst-new-i-d">
                <h2>Settings</h2>
                <div className="set-prof-b-d iflex">
                    <div>
                        <h2>Delete Listing</h2>
                        <h3>This action cannot be undone.</h3>
                    </div>
                    <button onClick={()=>{props.setPage(0.1)}} className="del">Delete</button>
                </div>
            </div>
        </>
    );
}

export default Details;