// Dependencies
import { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";

// API Imports
import { deleteAutomationWithCode, loadAutomationWithCode, renameAutomationWithCode, updateEmailTemplateWithCode, updateIntegrationPackageWithCode, updateListingTemplateWithCode, updateSigninPresetWithCode } from "../../../apicalls/automations";

// Util Imports
import { useError } from '../../ui/Context/ErrorContext';
import { useUpgrade } from "../../ui/Context/UpgradeContext";
import { convertToBackendFormat_integrations, convertToBackendFormat_questions, getAutomationSmallIcon, formatBackendIntegrations, formatBackendQuestions } from "../../../utils/sharedAutomationFuns.js";
import uploadPhoto from "../../../utils/uploadPhoto.js";
import setTitleText from "../../../utils/setTitleText.js";

// Component Imports
import { default as IntegrationDetails } from "../Details/Integration_Details";
import { default as QuestionDetails } from "../Details/Question_Details";
import { default as ListingDesign } from "../Details/Listing_Design";
import { default as EmailDetails } from "../Details/Email_Details";


// Functional Component
function Controller() {
    setTitleText("Manage Automation");

    // Page states
	const { id } = useParams();
    const navigate = useNavigate();
    const { showError } = useError();
    const { showUpgrade } = useUpgrade();
    const [loaderOn, setLoaderOn] = useState(false); // Turn preloader on after 0.5s
	const [page, setPage] = useState(0);
    const [loading, setLoading] = useState(false); // Avoid multiple 'save changes'

    // Data states
    const [name, setName] = useState("");
    const [newName, setNewName] = useState("");
    const [type, setType] = useState(-1);
    const [theme, setTheme] = useState(["black", "light"]); // [FG, BG]
    const [integrations, setIntegrations] = useState([]);
    const [questions, setQuestions] = useState([]);
    const [emailData, setEmailData] = useState([]);
    const [emailImageBlob, setEmailImageBlob] = useState(null);
    const [emailImageUrl, setEmailImageUrl] = useState(null);

	useEffect(()=>{
		async function loadAutomation() {
            const resp = await loadAutomationWithCode(id);
            setTimeout(()=>{setLoaderOn(true)}, 500);

            switch (resp.status) {
                case 200:
                    const data = await resp.json();
                    if (data.type === 0) {
                        setTheme([data.data.fg_color, data.data.bg_color]);
                        setIntegrations(formatBackendIntegrations(data.data.integrations || []));
                        setQuestions(formatBackendQuestions(data.data.questions) || [])
                    }
                    if (data.type === 1) setIntegrations(formatBackendIntegrations(data.data || []));
                    if (data.type === 2) setQuestions(formatBackendQuestions(data.data) || []);
                    if (data.type === 3) setEmailData(data.data || []);
                    setName(data.name);
                    setNewName(data.name);
                    setType(data.type);
                    break;
                case 401:
                    showUpgrade("Automations");
                    break;
                default:
                    showError('An unexpected error occurred.');
                    break;
            }
		}
		loadAutomation();
	},[]);

    async function saveNameChange() {
        const resp = await renameAutomationWithCode(id, newName);
        if (resp.status !== 200) {
            showError('An unexpected error occurred.');
        } else {
            setName(newName);
        }
    }

    async function handleDelete() {
        const resp = await deleteAutomationWithCode(id);
        if (resp.status !== 200) {
            showError('An unexpected error occurred.');
        } else {
            navigate("/automations", {replace:true});
        }
    }

    async function saveIntegrationChanges() {
        setLoading(true);
        const formatted = convertToBackendFormat_integrations(integrations);
        const resp = await updateIntegrationPackageWithCode(id, formatted);
        if (resp.status !== 200) {
            showError('An unexpected error occurred.');
        }
        setLoading(false);
    }
    
    async function saveQuestionChanges(new_questions) {
        setLoading(true);
        const formatted = convertToBackendFormat_questions(new_questions);
        const resp = await updateSigninPresetWithCode(id, formatted);
        if (resp.status !== 200) {
            showError('An unexpected error occurred.');
        }
        setLoading(false);
    }

    async function saveDesignChanges(new_fg, new_bg) {
        setLoading(true);
        const resp = await updateListingTemplateWithCode(id, new_fg, new_bg);
        if (resp.status !== 200) {
            showError('An unexpected error occurred.');
        } else {
            setTheme([new_fg, new_bg]);
        }
        setLoading(false);
    }
    
    async function saveEmailTemplateChanges() {
        setLoading(true);
        // Upload new image to S3
        var imageId = emailData.s3_id;
        if (!emailData.s3_id && emailImageUrl) imageId = await uploadPhoto(emailImageBlob);
        // Upload new details
        const resp = await updateEmailTemplateWithCode(id, emailData.s, emailData.h, emailData.b, emailData.c, emailData.cn, emailData.ct, imageId);
        if (resp.status !== 200) {
            showError('An unexpected error occurred.');
        }
        setLoading(false);
    }

    // Return layout
    return (
        <>
            {(type === -1) ?
                <>{(loaderOn) && <div className="prl-spin-cont"><div className="prl-spin" /></div>}</>
                :
                <>
                    <div className="tb-cont">
                        <div className="tb-top">
                            <div className="iflex">
                                {getAutomationSmallIcon(type)}
                                <h1 className="pg-t">{name}</h1>
                            </div>
                        </div>
                        <div className="tb-nav iflex">
                            <button onClick={()=>{setPage(0)}}><h3>{((type === 0 || type === 2) ? "Sign-In" : ((type === 1) ? "Integrations" : "Email"))}</h3><div className={(page === 0) ? "a" : ""} /></button>
                            {(type === 0) &&
                                <>
                                    <button onClick={()=>{setPage(1)}}><h3>Integrations</h3><div className={(page === 1) ? "a" : ""} /></button>
                                    <button onClick={()=>{setPage(2)}}><h3>Design</h3><div className={(page === 2) ? "a" : ""} /></button>
                                </>
                            }
                            <button onClick={()=>{setPage(3)}}><h3>Settings</h3><div className={(page === 3) ? "a" : ""} /></button>
                        </div>
                    </div>
                    <div className="dash-cont nav">
                        {(type === 0 && page === 2) && 
                            /* Listing Design */
                            <ListingDesign {...{pkg_name: `${name}`, isLoading: loading, fgColor: theme[0], bgColor: theme[1], setFgColor: (fg)=>{setTheme([fg,theme[1]])}, setBgColor: (bg)=>{setTheme([theme[0],bg])}, isManage: true, saveChanges: saveDesignChanges}} />
                        }
                        {((type === 1 && page === 0) || (type === 0 && page === 1)) &&
                            /* Integrations Details */
                            <IntegrationDetails {...{pkg_name: `${name}`, isLoading: loading, integrations: integrations, setIntegrations: setIntegrations, isManage: true, isListTemplate: (type === 0), saveChanges: saveIntegrationChanges}} />
                        }
                        {(type === 3 && page === 0) && 
                            /* Email Template */
                            <EmailDetails {...{pkg_name: `${name}`, isLoading: loading, template: emailData, setTemplate: setEmailData, isManage: true, profileUrl: emailImageUrl, setProfileUrl: setEmailImageUrl, setProfileBlob: setEmailImageBlob, saveChanges: saveEmailTemplateChanges}} />
                        }
                        {((type === 2 || type === 0) && page === 0) &&
                            /* Sign-In Details */
                            <QuestionDetails {...{pkg_name: `${name}`, isLoading: loading, questions: questions, isManage: true, saveChanges: saveQuestionChanges}} />
                        }
                        {(page === 3) && 
                            <>
                                <div className="lst-new-i-d">
                                    <h2>Automation Name</h2>
                                    <input className="atms-name-in" placeholder="e.g. My Automation" onChange={(e) => {setNewName(e.target.value)}} value={newName || ""}></input>
                                </div>
                                <div className="lst-new-i-d mb0">
                                    <h2>Settings</h2>
                                    <div className="lead-prof-b-d iflex">
                                        <div>
                                            <h2>Delete Automation</h2>
                                            <h3>This action cannot be undone.</h3>
                                        </div>
                                        <button onClick={handleDelete}>Delete</button>
                                    </div>
                                </div>
                                <div className="dash-cont-b-d iflex">
                                    <button className={"back" + ((newName === name) ? " hidden" : "")} disabled={newName === name} onClick={()=>{setNewName(name)}}>Cancel</button>
                                    <button className={"next" + ((newName === name) ? " hidden" : "")} disabled={newName === name} onClick={saveNameChange}>Save Changes</button>
                                </div>
                            </>
                        }
                    </div>
                </>
            }
        </>
    );
}

export default Controller;