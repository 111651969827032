// Dependencies
import { useState, useEffect } from 'react';

// API Imports
import { getProfileData } from "../../apicalls/settings.js";

// Component Imports
import Profile from "./comp/Profile";
import ChangePass from "./comp/ChangePass";
import DeleteAccount from "./comp/DeleteAccount";
import Categories from "./comp/Categories";
import UpdateCredentials from "./comp/UpdateCredentials";
import Organization from "./comp/Organization";
import DeleteOrganization from "./comp/DeleteOrganization";
import Billing from './comp/Billing.jsx';

// Util Imports
import { useError } from '../ui/Context/ErrorContext';
import setTitleText from '../../utils/setTitleText.js';

// SVG Imports
import gear_orange from "../../img/svg/topbar/gear_orange.svg";


// Functional Component
function Controller() {
    setTitleText("Settings");

    const { showError } = useError();

    // Data states
    const [didLoad, setDidLoad] = useState(false);
    const [loaderOn, setLoaderOn] = useState(false);
    const [page, setPage] = useState(0);
    const [profileData, setProfileData] = useState({});
    const [selIntg, setSelIntg] = useState("");

    // Load profile data
    useEffect(()=>{
        async function loadProfile() {
            const resp = await getProfileData();
            setTimeout(()=>{setLoaderOn(true)}, 500); // Turn preloader on after 0.5s
            if (resp.status !== 200) {
                showError('An unexpected error occurred.');
            } else {
                const data = await resp.json();
                setProfileData(data.data);
                setDidLoad(true);
            }
        }
        if (Object.keys(profileData).length === 0) loadProfile();
    }, []);

    // Return layout
    return (
        <>
            {(page === 0.1 || page === 0.2 || page === 0.4) ?
                <>
                    {(page === 0.1) && <ChangePass {...{setPage: setPage}} /> }
                    {(page === 0.2) && <DeleteAccount {...{setPage: setPage}} /> }
                    {(page === 0.4) && <DeleteOrganization {...{setPage: setPage}} /> }
                </>
            :
                <>
                    <div className="tb-cont">
                        <div className="tb-top">
                            <div className="iflex">
                                <img className="tb-t-icon" src={gear_orange} alt="" />
                                <h1 className="pg-t">Settings</h1>
                            </div>
                        </div>
                        <div className="tb-nav iflex">
                            <button onClick={()=>{setPage(0)}}><h3>Account</h3><div className={(page === 0) ? "a" : ""} /></button>
                            <button onClick={()=>{setPage(1)}}><h3>Categories</h3><div className={(page === 1) ? "a" : ""} /></button>
                            <button onClick={()=>{setPage(2)}}><h3>Organization</h3><div className={(page === 2 || page === 2.5) ? "a" : ""} /></button>
                            <button onClick={()=>{setPage(3)}}><h3>Billing</h3><div className={(page === 3) ? "a" : ""} /></button>
                        </div>
                    </div>
                    <div className="dash-cont nav">
                        {(didLoad) ?
                            <>
                                {(page === 0) && <Profile {...{profileData: profileData, setSelIntg: setSelIntg, setPage: setPage}} /> }
                                {(page === 0.3) &&  <UpdateCredentials {...{type: selIntg, goBack: ()=>{setPage(0)}}} /> }
                                {(page === 1) && <Categories {...{setPage: setPage}} /> }
                                {(page === 2) && <Organization {...{email: profileData.email, setPage: setPage}} /> }
                                {(page === 3) && <Billing /> }
                            </>
                            :
                            <>{(loaderOn) && <div className="prl-spin-cont"><div className="prl-spin" /></div>}</>
                        }
                    </div>
                </>
            }
        </>
    );
}

export default Controller;