// Dependencies
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

// API Imports
import { loginUser } from "../../apicalls/auth.js";

// Util Imports
import setTitleText from "../../utils/setTitleText.js";

// SVG Imports
import su_logo from "../../img/svg/signup/su_logo.svg";

// PNG Imports
import signin_bg from "../../img/png/signup/signup_bg_10.png";

// Functional Component
function Login() {
    setTitleText("Sign In");

    // Page states
    const navigate = useNavigate();
    const [showPass, toggleShowPass] = useState(false);
    const [showError, toggleError] = useState(false);

    // Data states
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    // Login on "Enter" key press
    useEffect(() => {
        function handleKeyDown(e) {
            if (e.key === 'Enter') handleLogin();
        }
        document.addEventListener('keydown', handleKeyDown);
        return () => { document.removeEventListener('keydown', handleKeyDown); }
    }, [email, password]);

    // Login user
    async function handleLogin() {
        const login = await loginUser(email, password);
        if (login) window.location.reload(false);
        else addError();
    }

    // Helper UI functions
    function addError() { toggleError(true); }
    function removeError() { toggleError(false); }

    // Return layout
    return(
        <>
            <div className="su-bg">
                <img src={signin_bg} alt="" />
            </div>
            <div className="su-cont">
                <img src={su_logo} alt="" />
                <div className="top bl">
                    <h1>Sign In</h1>
                </div>
                <div className="content-spacer" />
                <div className="in">
                    <div className="di">
                        <h2>Email Address</h2>
                        <input className={(showError) ? "err" : ""} placeholder="e.g. jdoe@dreamrealty.com" type="email" value={email} onChange={(e)=>{removeError(); setEmail(e.target.value)}}></input>
                    </div>
                    <div className="di mb0">
                        <div className="su-pws flex">
                            <h2>Password</h2>
                            <button onClick={(_)=>{toggleShowPass(!showPass)}}>{(showPass) ? "Hide" : "Show"}</button>
                        </div>
                        <input className={(showError) ? "err" : ""} placeholder="•••••" type={(showPass) ? "text" : "password"} value={password} onChange={(e)=>{removeError(); setPassword(e.target.value)}} />
                    </div>
                </div>
                <button
                    className={"tb" + ((!email.length || !password.length) ? " hidden" : "")}
                    disabled={(!email.length || !password.length)}
                    onClick={handleLogin}>Sign In</button>
                <button
                    className="su"
                    onClick={()=>{navigate('/signup',{replace:false})}}>
                        New to ListingOpen?&nbsp;<span className="orange">Sign Up!</span>
                </button>
            </div>
        </>
    );
}

export default Login;