// Dependencies
import { useState, useEffect } from "react";

// API Imports
import { updateListingDesignWithCode } from "../../../../apicalls/listings";

// Util Imports
import { themeNameToNumber, themeNumberToName } from "../../../../utils/sharedDesignFuns.js";
import { useError } from '../../../ui/Context/ErrorContext';

// SVG Imports
import check_white from "../../../../img/svg/listing/check_white.svg";
import eyedrop from "../../../../img/svg/listing/eyedrop.svg";


// Functional Component
function Design(props) {
    const { showError } = useError();

    // Data states
    const [fgColor, setFgColor] = useState(themeNameToNumber(props.details.fg_theme));
    const [bgColor, setBgColor] = useState(themeNameToNumber(props.details.bg_theme, true));

    // Cancel changes
    useEffect(()=>{
        if (props.didCancel) {
            setFgColor(themeNameToNumber(props.details.fg_theme));
            setBgColor(themeNameToNumber(props.details.bg_theme, true));
            props.setCancelled(false);
        }
    },[props.didCancel]);

    // Upload changes
    useEffect(()=>{
        async function uploadChanges() {
            const resp = await updateListingDesignWithCode(props.listing_code, themeNumberToName(fgColor), themeNumberToName(bgColor, true));
            if (resp.status !== 200) {
                showError('An unexpected error occurred.');
            } else {
                props.setDidSave(2);
                props.notifyChanges(false);
            }
        }
        if (props.didSave === 1) uploadChanges();
    },[props.didSave])

    // Detect changes
    useEffect(()=>{
        if ((fgColor !== themeNameToNumber(props.details.fg_theme)) || (bgColor !== themeNameToNumber(props.details.bg_theme, true))) props.notifyChanges(true);
        else props.notifyChanges(false);
    },[fgColor, bgColor]);

    // Get custom foreground color
    function openCustomFg() {

    }

    // Return layout
    return (
        <div className="iflex mng-lst-dsn-cont">
            <div className="mng-lst-dsn-split-d">
                <div>
                    <h2>Foreground</h2>
                    <div className="mng-lst-dsn-colors-d">
                        <button className="fg0" onClick={()=>{setFgColor(0)}}>
                            {(fgColor === 0) && <img src={check_white} alt="" />}
                        </button>
                        <button className="fg1" onClick={()=>{setFgColor(1)}}>
                            {(fgColor === 1) && <img src={check_white} alt="" />}
                        </button>
                        <button className="fg2" onClick={()=>{setFgColor(2)}}>
                            {(fgColor === 2) && <img src={check_white} alt="" />}
                        </button>
                        <button className="fg3" onClick={()=>{setFgColor(3)}}>
                            {(fgColor === 3) && <img src={check_white} alt="" />}
                        </button>
                        <button className="fg4" onClick={()=>{setFgColor(4)}}>
                            {(fgColor === 4) && <img src={check_white} alt="" />}
                        </button>
                        <button className="fg5" onClick={()=>{setFgColor(5)}}>
                            {(fgColor === 5) && <img src={check_white} alt="" />}
                        </button>
                        <button className="fg6" onClick={()=>{setFgColor(6)}}>
                            {(fgColor === 6) && <img src={check_white} alt="" />}
                        </button>
                        <button className="fg7" onClick={()=>{setFgColor(7)}}>
                            {(fgColor === 7) && <img src={check_white} alt="" />}
                        </button>
                        <button className="fg8" onClick={()=>{setFgColor(8)}}>
                            {(fgColor === 8) && <img src={check_white} alt="" />}
                        </button>
                        <button className="fg9" onClick={()=>{setFgColor(9)}}>
                            {(fgColor === 9) && <img src={check_white} alt="" />}
                        </button>
                        <button className="fg10" onClick={()=>{setFgColor(10)}}>
                            {(fgColor === 10) && <img src={check_white} alt="" />}
                        </button>
                        <button className="fg11" onClick={()=>{setFgColor(11)}}>
                            {(fgColor === 11) && <img src={check_white} alt="" />}
                        </button>
                        <button onClick={openCustomFg} className="custom-color">
                            <img src={eyedrop} alt="" />
                        </button>
                    </div>
                </div>
                <div>
                    <h2>Background</h2>
                    <div className="mng-lst-dsn-colors-d bg">
                        <button className="bg0" onClick={()=>{setBgColor(0)}}>
                            <img src={check_white} className={bgColor === 0 ? "" : "hide"} />
                        </button>
                        <button className="bg1" onClick={()=>{setBgColor(1)}}>
                            <img src={check_white} className={bgColor === 1 ? "" : "hide"} />
                        </button>
                    </div>
                </div>
            </div>
            <div className="mng-lst-dsn-split-d">
                <h2>Preview</h2>
                <div className={`mng-lst-dsn-preview-cont bg${bgColor}`}>
                    <div className={`mng-lst-dsn-preview-prog iflex bg${bgColor}`}>
                        <div className={`filled fg${fgColor}`} />
                        <div className={`fg${fgColor}`} />
                        <div className={`fg${fgColor}`} />
                        <div className={`fg${fgColor}`} />
                        <div className={`fg${fgColor}`} />
                    </div>
                    <h1 className={`bg${bgColor}`}>Let's get to know you...</h1>
                    <div className="mng-lst-dsn-preview-q">
                        <h2 className={`bg${bgColor}`}>Question Text</h2>
                        <div className={`fg${fgColor}`}><h3 className={`hidden fg${fgColor}`}>Your answer here</h3></div>
                        <div className={`iflex fg${fgColor}`}>
                            <div className={`mng-lst-dsn-preview-yes fg${fgColor}`}>
                                <div />
                            </div>
                            <h3 className={`fg${fgColor}`}>Yes</h3>
                        </div>
                        <button className={`fg${fgColor}`}>Continue</button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Design;