// Dependencies
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

// Component Imports
import ExportLeads from "./Export";
import FilterLeads from "./Filter";

// API Imports
import { getAllLeads } from "../../apicalls/leads.js";

// Util Imports
import { useError } from '../ui/Context/ErrorContext';
import formatDate from "../../utils/formatDate.js";
import { shortenAddress } from "../../utils/shortenAddress.js";
import { formatPhoneNumber } from "../../utils/formatPhone.js";
import setTitleText from "../../utils/setTitleText.js";

// SVG Imports
import sort_icon from "../../img/svg/listing/sort.svg";
import filter_icon from "../../img/svg/listing/filter.svg";
import export_icon from "../../img/svg/listing/export.svg";
import home_icon from "../../img/svg/listing/house_small.svg";
import email_icon from "../../img/svg/listing/email_orange.svg";
import phone_icon from "../../img/svg/listing/phone_orange.svg";
import calendar_icon from "../../img/svg/listing/calendar_small.svg";
import lead_orange from "../../img/svg/topbar/lead_orange.svg";
import search_icon from "../../img/svg/topbar/search_icon.svg";



// Functional Component
function Controller() {
    setTitleText("Leads");

    const navigate = useNavigate();
    const { showError } = useError();

    // Page states
    const [didLoad, setDidLoad] = useState(false);
    const [loaderOn, setLoaderOn] = useState(false);
    const [page, setPage] = useState(0);
    const [searchInput, setSearchInput] = useState("");

    // Data states
    const [leadList, setLeadList] = useState(null);
    const [filteredLeads, setFilteredLeads] = useState(null);
    
    // Filter states
    const [codesRequired, setCodesRequired] = useState([]);
	const [phoneRequired, setPhoneRequired] = useState(false);
	const [emailRequired, setEmailRequired] = useState(false);
    const [listingsRequired, setListingsRequired] = useState([]);
	const [questionsRequired, setQuestionsRequired] = useState({});
    const filterProps = {
        all_leads: leadList,
        setFilteredLeads: setFilteredLeads,
        listingsRequired: listingsRequired,
        setListingsRequired: setListingsRequired,
        listingCodesRequired: codesRequired,
        setListingCodesRequired: setCodesRequired,
        phoneRequired: phoneRequired,
        setPhoneRequired: setPhoneRequired,
        emailRequired: emailRequired,
        setEmailRequired: setEmailRequired,
        questionsRequired: questionsRequired,
        setQuestionsRequired: setQuestionsRequired,
        goBack: goBack
    };

    function goBack() { setPage(0) }

    // Render rows of leads
    function renderLeads(leads) {        
        if (leads.length === 0) {
            return (
                <div className="lds-empty">
                    <div>
                        <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 35 35">
                            <g><rect width="35" height="35" rx="5" fill="#ffe2dd"/></g>
                            <g transform="translate(9.297 9)">
                                <path d="M19.406,11.2A8.2,8.2,0,1,1,11.2,3a8.2,8.2,0,0,1,8.2,8.2Z" transform="translate(-3 -3)" fill="none" stroke="#ff5c3c" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.2"/>
                                <path d="M12,21a4.429,4.429,0,0,0,3.281,1.641A4.429,4.429,0,0,0,18.563,21" transform="translate(-7.078 -11.156)" fill="none" stroke="#ff5c3c" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.2"/>
                                <path d="M13.5,13.5h.008" transform="translate(-7.758 -7.758)" fill="none" stroke="#ff5c3c" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
                                <path d="M22.5,13.5h.008" transform="translate(-11.836 -7.758)" fill="none" stroke="#ff5c3c" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
                            </g>
                        </svg>
                        <h1>No leads found</h1>
                        <h3>We couldn't find any leads matching your search criteria.</h3>
                    </div>
                </div>
            );
        }

        return (
            <>
                <div className="dash-result-d flex">
                    <h2>Results</h2>
                    <div className="dash-result-num">
                        <h3>{leads.length || "0"}</h3>
                    </div>
                </div>
                <div className="lds-card-cont">
                    {leads.map((lead) => {
                        return (
                            <button key={`lead-${lead.id}`} value={lead.id} onClick={(e)=>{navigate(`/leads/${e.target.value}`,{replace:false})}} className="lds-card-b">
                                <div className="lds-card-t iflex" value={lead.id}>
                                    <div className="lds-card-pf" value={lead.id}>
                                        <h1>{lead.name.match(/\b\w/g).slice(0, 2).join('').toUpperCase()}</h1>
                                    </div>
                                    <div className="lds-card-nm" value={lead.id}>
                                        <h2>{lead.name}</h2>
                                        <div className="iflex">
                                            {(lead.email && lead.email.length > 0) ?
                                                <>
                                                    <img src={email_icon} alt="" />
                                                    <h3>{lead.email}</h3>
                                                </>
                                                :
                                                ((lead.phone && lead.phone.length > 0) ?
                                                    <>
                                                        <img src={phone_icon} alt="" />
                                                        <h3>{formatPhoneNumber(lead.phone)}</h3>
                                                    </>
                                                    :
                                                    <>
                                                        <h3 className="hidden">No Contact Info</h3>
                                                    </>
                                                )
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="lds-card-spacer" value={lead.id}></div>
                                <div className="lds-card-more iflex" value={lead.id}>
                                    <div className="lds-card-place iflex" value={lead.id}>
                                        <img src={home_icon} alt="" />
                                        <h3>{shortenAddress(lead.address_1) + (lead.address_2 ? `, ${lead.address_2}` : "")}</h3>
                                    </div>
                                    <div className="lds-card-date iflex" value={lead.id}>
                                        <img src={calendar_icon} alt="" />
                                        <h3>{formatDate(new Date(lead.visited * 1000), 'short')}</h3>
                                    </div>
                                </div>
                            </button>
                        );
                    })}
                </div>
            </>
        );
    }

    // Load leads
    async function loadLeads() {
        const leads_resp = await getAllLeads();
        setTimeout(()=>{setLoaderOn(true)}, 500); // Turn preloader on after 0.5s
        if (leads_resp.status !== 200) {
            showError('An unexpected error occurred.');
        } else {
            const leads_data = await leads_resp.json();
            setFilteredLeads(leads_data);
            setLeadList(leads_data);
            setDidLoad(true);
        }
    }
    // Load leads on page load
    useEffect(()=>{
        loadLeads();
    },[]);

    // Return layout
    return (
        <> 
            {(page === 1) &&
                <ExportLeads {...{
                    goBack: goBack,
                    all_leads: leadList,
                    filtered_leads: filteredLeads.filter(lead => {
                        return(lead.name.toLowerCase().includes(searchInput.toLowerCase()));
                    }),
                }} />
            }
            {(page === 2) &&
                <FilterLeads {...filterProps} />
            }
            {(page === 0) &&
                <>
                    <div className="tb-cont">
                        <div className="tb-top">
                            <div className="iflex">
                                <img className="tb-t-icon" src={lead_orange} alt="" />
                                <h1 className="pg-t">Leads</h1>
                            </div>
                            <div className="lst-dsh-tools iflex">
                                <label>
                                    <img src={search_icon} alt="" />
                                    <input className="lst-mng-leads-search" type="text" placeholder="Search leads..." onChange={(e)=>{setSearchInput(e.target.value)}} value={searchInput} />
                                </label>
                                {/* <button className="lst-mng-leads-b sort"><img src={sort_icon} alt="" />Sort</button> */}
                                <button className="lst-mng-leads-b filter" onClick={()=>{setPage(2)}}><img src={filter_icon} alt="" />Filter</button>
                                <button className="lst-mng-leads-b export" onClick={()=>{setPage(1)}}><img src={export_icon} alt="" />Export</button>       
                            </div>
                        </div>
                    </div>
                    <div className="dash-cont">
                        {(didLoad && leadList) ?
                            <>
                                { renderLeads(
                                    filteredLeads.filter(lead => {
                                        return(lead.name.toLowerCase().includes(searchInput.toLowerCase()));
                                    })
                                )}
                            </>
                            :
                            <>{(loaderOn) && <div className="prl-spin-cont"><div className="prl-spin" /></div>}</>
                        }
                    </div>
                </>
            }
        </>
    );
}

export default Controller;