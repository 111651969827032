// Dependencies
import { useState} from 'react';

// Component Imports
import AddPhotoModal from "../../modals/AddPhotoModal";
import { PersonalizeInput, PersonalizeTextArea, AutoHeight_Textarea } from "./comp/PersonalizeInput";

// SVG Imports
import plus_icon from "../../../img/svg/listing/plus_circle.svg";
import info_circle from "../../../img/svg/automations/info_circle.svg";

// Util Imports
import getPhotoUrlFromId from "../../../utils/getPhotoUrlFromId.js";


// Functional Component
function EmailDetails(props) {
	// Page states
	const [imageModalOpen, setImageModalOpen] = useState(false);
	const imageModalProps = {
		singleImage: true,
		setImageBlob: props.setProfileBlob,
		setImageUrl: props.setProfileUrl,
		closeModal: ()=>{setImageModalOpen(false)}
	};
	const [selectorPage, setSelectorPage] = useState(0);

	// Helper functions
	function clearImage_Manage() {
		props.setTemplate({...props.template, s3_id: null});
		props.setProfileUrl(null); 
		props.setProfileBlob(null)
	}

	// Return layout
	return (
		<>
			{ (imageModalOpen) && <AddPhotoModal {...imageModalProps} /> }
			<div className={"tool-tip-d" + ((selectorPage === 0) ? " flex" : "")}>
				{(selectorPage === 0) &&
					<>
						<img src={info_circle} alt="" />
						<h2>Use <b>selectors</b> to personalize messages for each lead.</h2>
						<button onClick={()=>{setSelectorPage(1)}}>Learn How -&gt;</button>
					</>
				}
				{(selectorPage === 1) &&
					<>
						<div className="top">
							<img src={info_circle} alt="" />
							<h1>How To</h1>
						</div>
						<h2>Use the selectors <b>{`{name}`}</b> or <b>{`{address}`}</b> to personalize messages for each lead. These selectors <u>only</u> work in the Subject, Header, Body, and Closing fields. To use the selectors, simply type <b>{`{name}`}</b> or <b>{`{address}`}</b> in the fields. You'll know they are working when the selectors light up orange.</h2>
						<button onClick={()=>{setSelectorPage(2)}}>See an Example -&gt;</button>
					</>
				}
				{(selectorPage === 2) &&
					<>
						<div className="top">
							<img src={info_circle} alt="" />
							<h1>In Action</h1>
						</div>
						<h2>Here, we use both selectors to personalize the subject field.</h2>
						<div className="faux-in">
							<h3>Hi <span className="orange">{`{name}`}</span>, thanks for visiting <span className="orange">{`{address}`}</span>!</h3>
						</div>
						<h2>This is the subject of the email that John Doe will receive:</h2>
						<div className="faux-in mb5">
							<h3>Hi John doe, thanks for visiting 123 Main Street!</h3>
						</div>
						<button className="back" onClick={()=>{setSelectorPage(1)}}>&lt;- Go Back</button>
					</>
				}
			</div>
			<div className="lst-new-i-d">
				<h2 className="ind">Subject</h2>
				<h3 className="ind">Email subject line.</h3>
				<PersonalizeInput val={(!props.isManage) ? props.subjectText : props.template.s} setVal={(!props.isManage) ? props.setSubjectText : (s)=>{props.setTemplate({...props.template, s: s})}} />
			</div>
			<div className="lst-new-i-d">
				<h2 className="ind">Heading</h2>
				<h3 className="ind">Large-sized text that introduces your message.</h3>
				<PersonalizeInput val={(!props.isManage) ? props.headingText : props.template.h} setVal={(!props.isManage) ? props.setHeadingText : (h)=>{props.setTemplate({...props.template, h: h})}} />
			</div>
			<div className="lst-new-i-d">
				<h2 className="ind">Body</h2>
				<h3 className="ind">The main content of your message.</h3>
				<PersonalizeTextArea val={(!props.isManage) ? props.bodyText : props.template.b} setVal={(!props.isManage) ? props.setBodyText : (b)=>{props.setTemplate({...props.template, b: b})}} />
			</div>
			<div className="lst-new-i-d">
				<h2 className="ind">Closing</h2>
				<h3 className="ind">Conclude your message in a final block of text.</h3>
				<PersonalizeInput val={(!props.isManage) ? props.closingText : props.template.c} setVal={(!props.isManage) ? props.setClosingText : (c)=>{props.setTemplate({...props.template, c: c})}} />
			</div>
			<div className="lst-new-i-d">
				<h2>Contact Photo</h2>
				{((!props.isManage) ? props.profileUrl : (props.template.s3_id || props.profileUrl)) ?
					<div className="flex">
						<div className="img-mng-gal-i sq">
							<img src={(!props.isManage) ? props.profileUrl : ((props.template.s3_id) ? getPhotoUrlFromId(props.template.s3_id) : props.profileUrl)} alt="" />
						</div>
						<button className="img-mng-gal-del-b" onClick={()=>{(!props.isManage) ? props.setProfileUrl(null) : clearImage_Manage() }}>×</button>
					</div>
					:
					<button className="img-mng-gal-add-b sq" onClick={()=>{setImageModalOpen(true)}}>
						<img src={plus_icon} alt="" />
						<h2>Add Photo</h2>
					</button>
				}
			</div>
			<div className="lst-new-i-d mb0">
				<h2 className="ind">Contact Details</h2>
				<h3 className="ind">Your name, plus any additional details (email, phone, company, etc.)</h3>
				<input className="atm-in mb" value={(!props.isManage) ? props.contactName : props.template.cn} onChange={(e) => { (!props.isManage) ? props.setContactName(e.target.value) : props.setTemplate({...props.template, cn: e.target.value}) }} placeholder="e.g. John Doe" />
				<AutoHeight_Textarea val={(!props.isManage) ? props.contactText : props.template.ct} setVal={(!props.isManage) ? props.setContactText : (ct)=>{props.setTemplate({...props.template, ct: ct})}} className={"atm-in"} placeholder={"e.g. (123) 456-7890 • jdoe@dreamrealty.com • Dream Realty Inc."} />
			</div>
			{(props.isManage) ?
				<div className="dash-cont-b-d iflex">
					<button
						className={"next"+((props.isLoading) ? " hidden" : "")}
						disabled={(props.isLoading /* TODO: Check if email is done */)}
						onClick={props.saveChanges}
					>Save Changes</button>
				</div>
				:
				<div className="dash-cont-b-d iflex">
					<button className="back" onClick={props.goBack}>&lt;- Back</button>
					<button
						className={"next"+((false /* TODO: CHECK IF EMAIL IS DONE */ ) ? " hidden" : "")}
						disabled={(false /* TODO: CHECK IF EMAIL IS DONE */ )}
						onClick={props.goNext}
					>Next -&gt;</button>
				</div>
			}
		</>
	);
}

export default EmailDetails;