// Dependencies
import { useState } from "react";

// Component Imports
import {default as SignInDetails} from "../Details/Question_Details";
import {default as IntgDetails} from "../Details/Integration_Details";
import {default as ListDesign} from "../Details/Listing_Design";
import {default as EmailDetails} from "../Details/Email_Details";
import {default as SignInReview} from "./Review/Review_Questions";
import {default as IntgReview} from "./Review/Review_Integrations";
import {default as ListReview} from "./Review/Review_Listing";
import {default as EmailReview} from "./Review/Review_Email";

// Util Imports
import setTitleText from "../../../utils/setTitleText.js";

// SVG Imports
import dots_orange from "../../../img/svg/topbar/dots_orange.svg";

// PNG Imports
import list_template from "../../../img/png/automations/list_template.png";
import si_preset from "../../../img/png/automations/si_preset.png";
import int_pack from "../../../img/png/automations/int_pack.png";
import em_template from "../../../img/png/automations/em_template.png";


// Constants
const templateTypes = [
	{id: 0, title: 'Listing Template', desc: 'Create standard listings', img: list_template, },
	{id: 1, title: 'Sign-In Preset', desc: 'Create a reusable list of sign-in questions', img: si_preset, },
	{id: 2, title: 'Integration Package', desc: 'Enable default integrations', img: int_pack, },
	{id: 3, title: 'Email Template', desc: 'Send standard follow-up messages', img: em_template, }
];


// Functional Component
function Controller() {
	setTitleText("New Automation");

	// Page states
	const [type, setType] = useState(-1);
	const [page, setPage] = useState(0);
	function goBack() { setPage(page-1) }
	function goNext() { setPage(page+1) }

	// Data states
	const [automationName, setAutomationName] = useState(null);

	const [questions, setQuestions] = useState([]);
	const questProps = {
		isListTemplate: false,
		questions: questions,
		setQuestions: setQuestions,
		automationName: automationName,
		setAutomationName: setAutomationName,
		goBack: goBack,
		goNext: goNext
	}

	const [integrations, setIntegrations] = useState([]);
	const intgProps = {
		isListTemplate: false,
		integrations: integrations,
		setIntegrations: setIntegrations,
		automationName: automationName,
		setAutomationName: setAutomationName,
		goBack: goBack,
		goNext: goNext
	}

	const [fgColor, setFgColor] = useState("black");
	const [bgColor, setBgColor] = useState("light");
	const listProps = {
		isListTemplate: true,
		integrations: integrations,
		setIntegrations: setIntegrations,
		questions: questions,
		setQuestions: setQuestions,
		fgColor: fgColor,
		setFgColor: setFgColor,
		bgColor: bgColor,
		setBgColor: setBgColor,
		automationName: automationName,
		setAutomationName: setAutomationName,
		goBack: goBack,
		goNext: goNext
	}

	const [subjectText, setSubjectText] = useState("");
	const [headingText, setHeadingText] = useState("");
	const [bodyText, setBodyText] = useState("");
	const [closingText, setClosingText] = useState("");
	const [contactName, setContactName] = useState("");
	const [contactText, setContactText] = useState("");
	const [profileUrl, setProfileUrl] = useState(null);
	const [profileBlob, setProfileBlob] = useState(null);
	const emailProps = {
		subjectText: subjectText,
		setSubjectText: setSubjectText,
		headingText: headingText,
		setHeadingText: setHeadingText,
		bodyText: bodyText,
		setBodyText: setBodyText,
		closingText: closingText,
		setClosingText: setClosingText,
		contactName: contactName,
		setContactName: setContactName,
		contactText: contactText,
		setContactText: setContactText,
		profileUrl: profileUrl,
		setProfileUrl: setProfileUrl,
		profileBlob: profileBlob,
		setProfileBlob: setProfileBlob,
		isListTemplate: false,
		automationName: automationName,
		setAutomationName: setAutomationName,
		goBack: goBack,
		goNext: goNext
	}
	
	// Return layout
	return (
		<>
            <div className="tb-cont">
                <div className="tb-top">
                    <div className="iflex">
                        <img className="tb-t-icon" src={dots_orange} alt="" />
                        <h1 className="pg-t">Create Automation</h1>
                    </div>
                    <div className="tb-steps-cont flex">
                        <div className={"tb-steps iflex"+((page===0)?" active":"")}><div className="tb-step-num"><h3>1</h3></div><h2>Setup</h2></div>
                        { (type === 0) ?
							<>
								<div className={"tb-steps iflex"+((page===1)?" active":"")}><div className="tb-step-num"><h3>2</h3></div><h2>Sign-In</h2></div>
								<div className={"tb-steps iflex"+((page===2)?" active":"")}><div className="tb-step-num"><h3>3</h3></div><h2>Integrations</h2></div>
								<div className={"tb-steps iflex"+((page===3)?" active":"")}><div className="tb-step-num"><h3>4</h3></div><h2>Design</h2></div>
								<div className={"tb-steps iflex"+((page===4)?" active":"")}><div className="tb-step-num"><h3>5</h3></div><h2>Review</h2></div>
							</>
							:
							<>
								<div className={"tb-steps iflex"+((page===1)?" active":"")}><div className="tb-step-num"><h3>2</h3></div><h2>Details</h2></div>
								<div className={"tb-steps iflex"+((page===2)?" active":"")}><div className="tb-step-num"><h3>3</h3></div><h2>Review</h2></div>
							</>
						}
                    </div>
                </div>
            </div>
            <div className="dash-cont step">
				{(page === 0) &&
					/* Choose type */
					<>
						<div className="lst-new-i-d mb0"><h2 className="mb25">What type of automation do you want to create?</h2></div>
						<div className="atms-card-cont">
							{templateTypes.map((template) => {
								return (
									<button
										className={"atms-card-b"+((type === template.id) ? " sel" : ((type !== -1) ? " unsel" : ""))}
										onClick={(e)=>{
											const cur_id = parseInt(e.currentTarget.value);
											(type !== cur_id) ? setType(cur_id) : setType(-1)}
										}
										value={template.id} key={`k-${template.id}`}
									>
										<img className="atms-card-img" src={template.img} alt="" />
										<div className="atms-card-id">
											<h1>{template.title}</h1>
											<h3>{template.desc}</h3>
										</div>
									</button>
								);
							})}
						</div>
						<div className="dash-cont-b-d iflex">
							<button className={"next"+((type === -1) ? " hidden" : "")} disabled={(type === -1)} onClick={goNext}>Next -&gt;</button>
						</div>
					</>
				}
				{/* Listing Preset */}
				{(type === 0 && page === 1) && <SignInDetails {...listProps} />}
				{(type === 0 && page === 2) && <IntgDetails {...listProps} />}
				{(type === 0 && page === 3) && <ListDesign {...listProps} />}
				{(type === 0 && page === 4) && <ListReview {...listProps} />}
				{/* Sign-In Preset */}
				{(type === 1 && page === 1) && <SignInDetails {...questProps} />}
				{(type === 1 && page === 2) && <SignInReview {...questProps} />}
				{/* Integration Package */}
				{(type === 2 && page === 1) && <IntgDetails {...intgProps} />}
				{(type === 2 && page === 2) && <IntgReview {...intgProps} />}
				{/* Email Template */}
				{(type === 3 && page === 1) && <EmailDetails {...emailProps} />}
				{(type === 3 && page === 2) && <EmailReview {...emailProps} />}
			</div>
		</>
	);
}

export default Controller;