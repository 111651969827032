// Constants
const api_prefix = 'http://localhost:5050/api/img';
const s3_bucket_prefix = 'https://listingopen-photos.s3.us-east-2.amazonaws.com/';

// Returns null if failed, and link if success
export default async function uploadPhoto(image_blob) {
	const resp = await fetch(api_prefix+'/gen/psurl', {
		method: 'GET', credentials: 'include'
	});
	if (resp.status !== 200) return null; // TODO: Handle error

	try {
		const data = await resp.json();
		const result = await fetch(data.url, {method: 'PUT', body: image_blob});
		if (result.status !== 200) return null;
		return (/* s3_bucket_prefix + */ data.key);
	} catch (err) {
		return null;
	}
}